import { AnyAction } from 'redux';
import {
    SHARE_PROGNOSIS_PERSON_GET_SUCCESS,
    SHARE_PRODUCTS_GET_SUCCESS,
    SHARE_PROGNOSIS_PERSON_GET,
} from 'src/store/actions/share';

export default function shareReducer(state = { person: null, products: [] }, action: AnyAction) {
    switch (action.type) {
        case SHARE_PROGNOSIS_PERSON_GET:
            return { ...state, personLoading: true, personLoaded: false}
        case SHARE_PROGNOSIS_PERSON_GET_SUCCESS:
            return { ...state, person: action.payload, personLoading: false, personLoaded: true };
        case SHARE_PRODUCTS_GET_SUCCESS:
            return { ...state, products: action.payload };
        default:
            return state
    }
}