import React from 'react';
import styled from 'styled-components';
import { getUser } from 'src/helpers/localStorage';
import synastryBanner from '../../assets/img/banner/synastry-banner.png';

export default function SynastryBig(){
  const onClick = () => {
    const user = getUser();
    const id = user?.id;
    if(id) {
      //@ts-ignore
      window.dataLayer.push({'event': 'dawn_formation_click-synastry', 'user_id': id})
      setInterval(() => {
        window.location.href = `${process.env.REACT_APP_INDIVIDUALS_APP_URL}/products/synastry`;
      }, 750);
      
    }
  }
  return <Container onClick={onClick}>
    <Content>
      <Title>Получите детальный разбор вашей совместимости в отношениях</Title>
      <Text>Узнаете что объединяет и мотивирует, а также что является препятствием в ваших отношениях с партнером</Text>
      <Button onClick={onClick}>Попробовать</Button>
    </Content>
    <Img></Img>
  </Container>
}


const Img = styled.div`
  height: 100%;
  background-position: bottom;
  background-size: contain;
  background-image: url(${synastryBanner});
  background-repeat: no-repeat;
`

const Container = styled.div`
  margin: 0.75rem 0;
  display: grid;
  grid-template-columns: 1fr 10rem 0.1rem;
  align-items: center;
  grid-gap: 2.8rem;

  background: var(--color-white);
  border-radius: 10px;

  @media (max-width: 768px){
    grid-template-columns: 1fr;
    ${Img} {
      display: none;
    }
  }
`

const Content = styled.div`
  padding: 1.5rem;
`

const Title = styled.div`
  font-size: 1.125rem;
  color: var(--text-secondary);
  line-height: 1.3rem;
  margin-bottom: 0.675rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  color: var(--text-third);
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
`

const Button = styled.div`
  padding: 0.675rem;

  color: var(--color-white);
  font-size: 0.75rem;
  background: var(--color-sea2);
  border-radius: 4px;
  width: min-content;
  cursor: pointer;

  :hover {
    opacity: 0.75;
  }
`