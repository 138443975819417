import React from 'react';
import styled from 'styled-components';

import background from './banner_autumn_app.jpg';
import { ReactComponent as CloseIcon } from './close.svg';
import { getUser } from 'src/helpers/localStorage';

const lsName = 'popupBottomBannerClosed_240924';

const PopupBottomBanner = () => {
  const user = getUser(); 

  const [isBannerShow, setIsBannerShow] = React.useState(true);

  React.useLayoutEffect(() => {
    const closedLS = localStorage.getItem(lsName);
    if (closedLS || +new Date() > 0) { // 24.09.2024 00:00:00
      setIsBannerShow(false);
    }
  }, []);

  const onClick = () => {
    //@ts-ignore
    window.dataLayer.push({ 'event': 'dawn_banner_veb_osenravn', 'user_id': user?.id });
    setTimeout(() => {
        window.location.href = 'https://p.chronos.ru/transition-point?utm_source=cabinet_rassveta&utm_medium=webinar&utm_campaign=120924&utm_content=banner ';
    }, 500)
    
  }

  const onClose = () => {
    setIsBannerShow(false);
    localStorage.setItem(lsName, Date.now().toString());
  }

  if (!isBannerShow) return <></>;

  return <Container>
    <Close onClick={onClose} />
    <Image onClick={onClick}><img src={background} alt="Autumn" /></Image>
    {/* <Content>
      <Title>Черная пятница</Title>
      <Text>
        Уникальная распродажа со скидками до 70% на все продукты и услуги Сhronos. Каждый участник получит подарок.
      </Text>
      <Button href="https://p.chronos.ru/black-friday" target="_blank">
        Принять участие
      </Button>
    </Content> */}
  </Container>;
}

const Container = styled.div`
  width: 36rem;
  max-width: 90vw;
  border-radius: 0.375rem;
  overflow: hidden;
  font-size: 0;
  position: relative;
  box-shadow: var(--workspace-box-shadow, 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2));
`
const Image = styled.a`
  display: block;
  & img {
    width: 100%;
  }
  cursor: pointer;
`

const Content = styled.div`
  background-color: var(--bg-100);
  padding: 1.25rem;
`

const Title = styled.h2`
  color: var(--text-primary);
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  font-weight: normal;
  margin-bottom: 0.25rem;
`

const Text = styled.p`
  color: var(--text-secondary);
  font-size: 0.87rem;
  line-height: 1.31rem;
  margin: 0;
  font-weight: normal;
  margin-bottom: 0.94rem;
`

const Button = styled.a`
  display: inline-block; 
  background-color: var(--accent-color);
  padding: 0.55rem 0.75rem 0.65rem 0.75rem;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.87rem;
  border: none;
  margin: 0;
  font-weight: normal;
  outline: none;
  line-height: 1rem;
  border-radius: 0.325rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;

  &:before {
    content: "";
    background-color: rgba(255,255,255,0.2);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    &:before {
      transform: skewX(-45deg) translateX(15em);
      transition: all 0.5s ease-in-out;
    }
  }
`

const Close = styled(CloseIcon)`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 1rem;
  top: 1rem;
  color: rgba(255,255,255,0.87);
  cursor: pointer;
  transition: opacity 0.2s;  

  &:hover {
    color: rgba(255,255,255,1);
  }
`

export default PopupBottomBanner;