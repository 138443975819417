import { uniqBy } from 'lodash';
import dayjs from 'dayjs';
import { CANVAS_HEIGHT, HEADER_HEIGHT, TREND_FIRST_Y } from './constants';
import { TDrawTrendsOptions, TTrend, drawTrends } from './trends';
import { drawUI, drawTooltip } from './ui';
import { IMousePosition } from './events';

export const hp = (v: number) => Math.round(v) + 0.5;

export const getPixelRatio = (ctx: CanvasRenderingContext2D) => {
    const dpr = window.devicePixelRatio || 1;
    //@ts-ignore
    const bsr = ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio || ctx.oBackingStorePixelRatio || ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
};

export const getTrendHeight = (count: number) => (CANVAS_HEIGHT - TREND_FIRST_Y - HEADER_HEIGHT) / count;

export const render = (ctx: CanvasRenderingContext2D, options: TDrawTrendsOptions, mouse?: IMousePosition) => {
  try {
    const canvasWidth = parseInt(ctx.canvas.style.width);
    ctx.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);
    drawUI(ctx, options, mouse);
    drawTrends(ctx, options);

    const trend = options.data[options.activeIndex];
    
    if (trend) {
      if (mouse && mouse.mouseX > 3.5) {
        drawTooltip(ctx, trend, [mouse?.mouseX!, mouse?.mouseY!])
      }
    } 
  } catch (err) {

  }
  
}

export function getLines(ctx: CanvasRenderingContext2D, text: string, maxWidth: number) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + ' ' + word).width;
      if (width < maxWidth) {
          currentLine += ' ' + word;
      } else {
          lines.push(currentLine);
          currentLine = word;
      }
  }
  lines.push(currentLine);
  return lines;
}

export function getTrendIndex(data: TTrend[], canvas: HTMLCanvasElement, event: MouseEvent): number {
  
  const trendHeight = getTrendHeight(data.length);
  const rect = canvas.getBoundingClientRect()
  let activeIndex = -1;

  const y = rect.height - (event.clientY - rect.top);
  
  if (y > TREND_FIRST_Y && y < CANVAS_HEIGHT - HEADER_HEIGHT) {
    activeIndex = Math.floor((y - TREND_FIRST_Y) / trendHeight);
  }

  return activeIndex;
}

export function adjustPoints(points: number[][]): number[][] {

  let result = uniqBy(points, ([x, y]) => x);

  // если точек меньше пяти, то генерим и вставляем недостающие
  if (result.length < 5) {
    const insertedArr = [];
    const diff = 5 - result.length;
    const first = result.at(0)!;
    const other = result.slice(1);

    for (let i = 1; i <= diff; i++) {
      insertedArr.push([first[0] + (i * 1), first[1]]);
    }
  
    result = [first, ...insertedArr, ...other];
  }

  // масштабируем график, если максимальная точка > 100
  let max = 0;
  for(let i = 0; i < result.length; i++) {
    const y = result[i][1];
    max = Math.max(max, y);
  }

  if (max > 100) {
    const k = 100 / max;
    result = result.map(point => ([point[0], point[1] * k]));
  }

  return result;
}

export function getSynchronWithGlobalTrengsText(trend: TTrend) {
  
  const { intersectionDate, keys } = trend;
  const [beforeArr, afterArr] = keys;

  let resultText = '';
  let startText = 'Синхрон с глобальными трендами:';

  if (!intersectionDate && beforeArr.length) {

    resultText = `${startText} ${beforeArr.join(', ')}`;

  } else if (intersectionDate && beforeArr.length && afterArr.length) {

    const date = dayjs.utc(intersectionDate).format('DD.MM');

    resultText = `${startText} до ${date} - ${beforeArr.join(', ')}; после ${date} - ${afterArr.join(', ')}`;

  }

  return resultText;
}