import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";

import Confirm from 'src/components/ui/Confirm'
import PersonAvatar from 'src/components/PersonAvatar/'
import PersonEdit from 'src/components/PersonEdit/'

import { EditIcon, ShevronIcon } from 'src/assets/img/icons';

import { getUser, getUserEditInfoShow, setUserEditInfoShow } from 'src/helpers/localStorage';
import { IFormation } from 'src/components/PrognosisList/compact';
import { searchParams } from 'src/helpers/utils';

export default function PersonHeader({
    user,
}: {
    user: IFormation
}) {
    const [isShowConfirm, setIsShowConfirm] = React.useState(false);
    const [editIsOpened, setEditIsOpened] = React.useState<boolean | IFormation>(false);
    const sp = searchParams()

    document.title = `${user.name} - Кабинет рассвета`;

    useEffect(() => {
        if(user.datetime.isEmpty && user.user_id && !getUserEditInfoShow(user.user_id) && !checkMakingPrognosis()) {
            setEditIsOpened(true);
            setUserEditInfoShow(user.user_id);
        }
    }, [user.datetime.isEmpty, user.user_id])

    const currentUser = getUser();

    const editHandle = () => {
        if (Object.keys(user.data).length > 0) {
            setIsShowConfirm(true);
        } else {
            setEditIsOpened(user);
        }
    }

    const openPersonList = () => {
        // for mobile
        const listEl = document.getElementById('person-list');
        if (isMobile && listEl) listEl.classList.add('opened');
    }

    const checkMakingPrognosis = () => {
        return !!sp.get('id')
    }


    return (
        <Header>
            <AvatarName guest={!currentUser?.id} onClick={openPersonList}>
                <PersonAvatar size="2rem" name={user.name} color={user.color} image={user.image} />
                <Name>{user.name}</Name>

                {isMobile && <Shevron />}
            </AvatarName>

            <DatePlace>

                {!user.datetime.isEmpty && <>{user.datetime.date}, {user.datetime.time}, {user.place.name}</>}
                {user.datetime.isEmpty && <>Необходимо заполнить профиль</>}
                {currentUser?.id && <EditIcon onClick={editHandle} />}
            </DatePlace>

            {isShowConfirm && <Confirm
                title='Построения сгорят'
                text='После редактирования любых данных, кроме имени и времени, все купленные построения сгорят'
                buttons={[
                    { name: 'Отменить', type: 'a', action: () => { setIsShowConfirm(false); } },
                    { name: 'Редактировать', type: 'button', action: () => { setIsShowConfirm(false); setEditIsOpened(user) } }
                ]}
            ></Confirm>}

            {editIsOpened && <PersonEdit onClose={() => setEditIsOpened(false)} user={user} />}
        </Header>
    )
}

const Header = styled.header`
    margin-bottom: 1.625rem;
    overflow: hidden;
    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }

    /* #print-profile-data {
        display: none;
    }*/
`

const AvatarName = styled.div<{guest: boolean}>`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: min-content;
    justify-content: center;
    margin-bottom: 1.125rem;

    @media (max-width: 768px) {
        background: var(--color-white);
        border: 1px solid var(--color-gray3);
        padding: 0.625rem;
        border-radius: 2.5rem;
        margin-bottom: 0.725rem;

        ${props => props.guest && `
            background: none;
            border: none;
            padding: 0rem;
            svg {
                display: none;
            }
        `}
    }
`

const Name = styled.span`
    margin-left: 1rem;
    font-size: 2rem;
    color: var(--color-black);
    white-space: nowrap;

    @media (max-width: 768px) {
        margin-left: 0.5rem;
        font-size: 0.9rem;
    }
`

const DatePlace = styled.div`
    color: var(--color-gray);
    display: flex;
    justify-content: center;
    align-items: center;


    @media (max-width: 768px) {
        font-size: 0.75rem;
    }

    & svg {
        margin-left: 0.675rem;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }
`


const Shevron = styled(ShevronIcon)`
    width: 0.75rem;
    margin: 0.25rem 0.25rem 0rem 0.6rem;
    fill: var(--color-gray);
`
