import React from 'react';
import {Marker} from "./index";

type NicheType = {
	k: string
	v: number
}

function Proforientation({
	niches,
	priorityDirections,
	talentsMap,
	talentsMapLabel,
	title,
	warning,
}: {
	niches: NicheType[]
	priorityDirections: string
	talentsMap: NicheType[]
	talentsMapLabel: string
	title: string
	warning: string
}) {
	return (
		<>
			<i>{warning}</i>

			<p>{talentsMapLabel}</p>

			<ul>
				{
					talentsMap.map((t: any, i: number) =>
						<li key={`talent_${i}`}>
							<div className="block">
								<Marker className='marker'>+{t.v}</Marker>
								<div className="text">{t.k}</div>
							</div>
						</li>
					)
				}
			</ul>

			<p>{priorityDirections}</p>

			<ul>{niches.map((n: any, i: number) => <li key={i.toString()}>{n.k}</li>)}</ul>
		</>
	)
}

export default Proforientation;