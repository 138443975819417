import React, {useCallback} from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { OkIcon } from 'src/assets/img/icons';

import Label from 'src/components/ui/Label';
import { IProduct } from 'src/components/Buy';
import { IFormation } from './compact';
import Updater from "../Updater";
import { searchParams, trendsOverInfo } from 'src/helpers/utils';
import { isHideFormation } from 'src/helpers/formations';

export default function PrognosisList({
    title = 'Заголовок',
    list = [],
    user,
    mode
}: {
    title: string;
    list: IProduct[];
    user: IFormation;
    mode: 'idp' | 'ap' | 'cidp';
}) {
    const [redirectUrl, setRedirectUrl] = React.useState<string>();

    const redirectUC = useCallback((value: string) => setRedirectUrl(value), [ setRedirectUrl ])

    const sp = searchParams()

    if (redirectUrl) return <Redirect push to={{pathname: redirectUrl, search: sp.toString()}} />;

    return <PrognosisListContainer>
        <header>{title}</header>
        <List>
            {list.map(item => {
                const newIcon = (item.isNew || item.type === 'cidp') ? <Label>Новое</Label> : '';
                const overInfo = trendsOverInfo(user, mode, item.key);

                if(isHideFormation(item, user)) return;

                if (item.isBuyed && !overInfo.isOver) {
                    return <Item
                                key={`item_key_${item.key}`}
                                isBuyed
                                onClick={() => {
                                    setRedirectUrl(`/prognosis/${user.id}/${item.key}`) }}
                            >
                                <p>{item.name}
                                    {mode === 'ap' ? <span>действует до {overInfo.serviceLife.format('DD.MM.YYYY')}</span> : <></>}
                                </p>
                                <OkIcon/>
                        </Item>
                        {item.hasUpdate && <Updater key={`updater_${item.key}`} setRedirectUrl={redirectUC} mode={'in-list'} price={item.price} discount={0.69} userId={user.id} productId={item.id} prognosisKey={item.key}/>}

                } else {
                    return <Item key={item.key}><p>{item.name} {newIcon}</p></Item>
                }
            })}
        </List>
    </PrognosisListContainer >
}

const PrognosisListContainer = styled.section`
    margin-bottom: 1.75rem;

    & header {
        color: var(--color-gray);
        font-size: 1.125rem;
        margin-bottom: 0.875rem;

        @media (max-width: 768px) {
            font-size: 0.875rem;
        }
    }
`

const List = styled.ul`
    margin: 0;
    padding: 0;
`

const Item = styled.li<{isBuyed?: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.875rem;
    padding: 1.75rem;
    background: var(--color-white);
    color: ${props => props.isBuyed ? 'var(--color-black)' : 'var(--color-gray)'};
    font-size: 1.5rem;
    border-radius: 0.625rem;


    @media (max-width: 768px) {
        padding: 1rem;
        font-size: 1.2rem;
    }

    ${props => props.isBuyed && `
        cursor: pointer;
        &:hover {
                box-shadow: 0px 1.8px 5.4px rgba(0, 0, 0, 0.09), 0px 9.6px 21.6px rgba(0, 0, 0, 0.05);
        }
    `}

    & p {
        display: flex;
        align-items: center;
        max-width: 80%;
        margin: 0;

        & label {
            margin-left: 0.65rem;
        }
    }

    & span {
        color: var(--text-secondary);
        font-size: 1rem;
        margin-left: 1.5rem;
    }

    & svg {
            width: 1.5rem;
            height: 1.5rem;
    }
`
