import React from 'react';
import { Button } from 'src/libs';
import styled from 'styled-components';

export default function Appeal({
    title = 'Заголовок',
    text = 'Текст',
    onClick = () => { },
    inverse = false,
    buttonText = "Купить",
    buttonShow = true
}) {
    return <Container inverse={inverse}>
        <header>{title}</header>
        <span>{text}</span>
        {buttonShow &&
            <nav><Button size='small' color="accent" onClick={onClick}>{buttonText}</Button></nav>
        }
    </Container>
}

const Container = styled.div<{inverse: boolean}>`
    padding: 1.5rem;
    color: var(--color-white);
    background: var(--color-sea);
    border-radius: 0.5rem;
    margin-bottom: 1rem;


    @media (max-width: 768px) {
        padding: 1rem;
    }

    ${props => props.inverse && `
        background: none;
        border: 1px solid var(--color-gray3);
        color: var(--color-black);
    `}

    & header {
        display: block;
        font-size: 1.125rem;
        margin-bottom: 0.675rem;
        width: 90%;

        @media (max-width: 768px) {
            width: 98%;
        }
    }

    & span {
        display: block;
        color: var(--color-white2);
        font-size: 0.875rem;
        line-height: 1.4;
        width: 90%;

        @media (max-width: 768px) {
            width: 98%;
        }

        ${props => props.inverse && `
            color: var(--color-gray);
        `}
    }

    & nav {
        margin-top: 0.875rem;
        
        & button {
            color: rgba(255, 255, 255, 0.87)
        }
    }

`