import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'src/assets/css/main.css';

import App from 'src/pages/app/';
import store from 'src/store/'
import { BrowserRouter } from 'react-router-dom';

import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';

dayjs.locale('ru');
dayjs.extend(updateLocale);
dayjs.updateLocale('ru', {
  monthsShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
})

console.log('******* DROP CONSOLE.LOG not working')

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

//<React.StrictMode>
