import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import Loading from 'src/components/Loading/';
import PersonHeader from 'src/components/PersonHeader/';
import PrognosisList from 'src/components/PrognosisList/';
import HoroscopeDaily from 'src/components/HoroscopeDaily/';
import Buy, { IProduct } from 'src/components/Buy/';
import Appeal from 'src/components/ui/Appeal/';
import { getFormation } from 'src/store/actions/formations';
import { IFormation } from 'src/components/PrognosisList/compact';
import { get as getProducts } from 'src/store/actions/products';
import { appealWindowText } from 'src/helpers/utils';
import SynastrySmall from 'src/components/Synastry/SynastrySmall';
import { fetchAPI_REST } from 'src/helpers/api';
import { routes } from 'src/routes';
import { Region } from 'src/libs';


function List({
	formations,
	onGetFormation,
	products,
	match,
	onGetProducts
}: {
	formations: IFormation[],
	onGetFormation(id: number): void,
	products: IProduct[],
	match: any,
	onGetProducts(id: number, country: Region): void
}) {

	const userId = +match.params.userId;
	const user = formations && formations[userId];
	const [buyIsOpened, setBuyIsOpened] = useState(false);
	const [hasSynastryUsage, setHasSynastryUsage] = useState(null);

	useEffect(() => {
		fetchAPI_REST(routes.PROFILE).then((data: any) => {
			setHasSynastryUsage(data.hasSynastryUsage);
		});
	}, []);

	useEffect(() => {
		if (userId) onGetFormation(userId);
		onGetProducts(userId, Region.ru)
	}, [onGetFormation, userId]);

	if (!user || products.length === 0) return <>
		<Center>
		</Center>
		<ColumnRight>
			<Loading />
		</ColumnRight>
	</>;

	const userIdpList = user.data.idp || {};
	const userApList = user.data.ap || {};
	const userCidpList = user.data.cidp || {};

	const cidpList = products
		.filter(item => item.type === 'cidp' && (!user.gender || !item?.gender || item?.gender === user.gender))
		.map(item => { return { ...item, isBuyed: !!userCidpList[item.key], isNew: true } });

	const idpList = products
		.filter(item => item.type !== item.key && item.type === 'idp' && (!user.gender || !item?.gender || item?.gender === user.gender))
		//FIXME
		//TODO: Delete ternary when the version of birth (IDP) will become 3.0
		.map(item => { return { ...item, isBuyed: !!userIdpList[item.key], hasUpdate: item.key === 'birth' && userIdpList[item.key]?.version === '1.7' && Math.floor(+item?.version) === 2 ? false : Math.floor(userIdpList[item.key]?.version) < Math.floor(+item?.version) } });

	const apList = products
		.filter(item => item.type !== item.key && item.type === 'ap')
		.map(item => { return { ...item, isBuyed: !!userApList[item.key] } });

	const extraGender = new RegExp(user.gender ? user.gender === 'male' ? 'Female' : 'Male' : '')
	const FilteredUserIdpList = Object.keys(userIdpList).filter(item => !item.match(extraGender))
	const purchasesCount = Object.keys(FilteredUserIdpList).length + Object.keys(userApList).length + Object.keys(userCidpList).length;
	const maxPurchasesCount = idpList.length + apList.length + cidpList.length;
	const appealTextData = appealWindowText(userIdpList, userApList, idpList, apList);

	const someApExpired = Object.values(userApList)?.some((data) => {
		//@ts-ignore
		const serviceLife: Dayjs = dayjs(data.created).add(1, 'year');
		return dayjs().isAfter(serviceLife)
	})

	return <>
		<Center>
			<PersonHeader user={user} />

			{hasSynastryUsage === false && <SynastrySmall />}

			{!user.datetime.isEmpty && ((purchasesCount < maxPurchasesCount) || someApExpired) &&
				<Appeal {...appealTextData} onClick={() => { setBuyIsOpened(true) }} />
			}

			{user.datetime.isEmpty &&
				<Appeal title="Заполните свой профиль" text="Чтобы посмотреть свой план развития, Вам сначала нужно заполнить профиль. Нажмите на карандаш выше этого блока, или на многоточие в левой части у своего профиля" onClick={() => { setBuyIsOpened(true) }} buttonShow={false} />
			}

			{cidpList[0] && <>
				<PrognosisList title='Для ребенка. Актуально до 14 лет' list={cidpList} user={user} mode={'cidp'} />
			</>
			}

			{idpList.length > 0 && <PrognosisList title='План развития' list={idpList} user={user} mode={'idp'} />}

			{apList.length > 0 && <PrognosisList title='Автопрогноз' list={apList} user={user} mode={'ap'} />}

			{buyIsOpened && <Buy onClose={() => setBuyIsOpened(false)} user={user} />}
		</Center>
		<ColumnRight>
			<HoroscopeDaily />
		</ColumnRight>
	</>
}

const mapStateToProps = (state: any) => {
	return {
		formations: state.formations,
		products: state.products
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetFormation: (id: number) => { dispatch(getFormation(id)); },
		onGetProducts: (id: number, country: Region) => { dispatch(getProducts(id, country)); },
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);



const Center = styled.div`
    width: var(--main-layout-center-width);
    padding-bottom: 3rem;

    @media (max-width: 768px) {
        padding-bottom: 1rem;
    }
`

const ColumnRight = styled.div`
    width: var(--main-layout-sidebar-right-width);
    padding-bottom: 3rem;
`
