import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import redirect from 'src/store/middlewares/redirect';
import rootReducer from 'src/store/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export type AppRootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk, redirect)
));

export default store;

