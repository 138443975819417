import { ChronosApi, IPlace, S3Urls } from "src/libs";

export const mainApi = new ChronosApi(process.env.REACT_APP_STATIC_URL as string, '');
export const s3urls = new S3Urls();

export interface IStartConfigPrices {
  default: number;
  birth: number;
  relations: number;
  finance: number;
  autoprediction: number;
}

export interface IPaymentCardMethod {
  country: string;
  title: string;
}

// FIXME: refactor
export interface IPaymentData {
  type?: string;
  cloudPayments?: any;
  place: string;
  lat: number;
  lon: number;
  name: string;
  date: string;
  time: string;
  promo?: any;
  subtype?: string;
  createdAt?: number;
  partner?: string;
  lablife?: boolean;
  operation?: any;
  token?: string;
  autoprediction?: boolean;
  apDate?: string;
  apTime?: string;
  country?: string;
}

// FIXME: refactor
export interface IStagedPaymentData {
  type?: string;
  cloudPayments?: any;
  email: string;
  promo?: any;
  subtype?: string;
  createdAt?: number;
  relations: boolean;
  partner?: string;
  finance: boolean;
  lablife: boolean;
  birth: boolean;
  operation?: any;
  token?: string;
  autoprediction: boolean;
  apDate?: string;
  apTime?: string;
  country: string;
  isStaged?: boolean;
}

const apiUrl = `${process.env.REACT_APP_BACKEND}/api`;

export const getStartConfigPrices = (token: string, partner: string): Promise<IStartConfigPrices> => {
  let url = `${apiUrl}/widget/${token}/config`;
  if(partner) url += `?partner=${partner}`
  return fetch(url, {
    method: 'GET',
    credentials: 'include'
  }).then(data => data.json())
}

export const getPlaces = (name: string): Promise<IPlace[]> => {
  return fetch(`${apiUrl}/places?input=${name}`, {
    method: 'GET',
    credentials: 'include'
  })
  .then(data => data.json())
  .then((data: {data: IPlace[]}) => data.data)
}

export const getPlace = (id: number): Promise<IPlace> => {
  return fetch(`${apiUrl}/places/${id}`, {
    method: 'GET',
    credentials: 'include'
  })
  .then(data => data.json())
  .then((data: {data: IPlace}) => data.data)
}

export async function getPaymentResult (dataPost: IPaymentData, token: string, partner: string) {
  let url = `${apiUrl}/widget/${token}/payment/config`;
  if(partner) url += `?partner=${partner}`
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost),
    credentials: 'include'
  }).then((response) => response.json());

  return result
}

export async function getStagedPaymentResult (dataPost: IStagedPaymentData, token: string, partner: string) {
  let url = `${apiUrl}/widget/${token}/payment/config`;
  if(partner) url += `?partner=${partner}`
  const result = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost),
    credentials: 'include'
  }).then((response) => response.json());

  return result
}

export async function hash(message: string) {
  const uint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
