import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { get } from 'src/store/actions/maps'
import { IFormation } from 'src/components/PrognosisList/compact';

function Map({
    maps,
    user,
    onGet
}: {
    maps: any,
    user: IFormation,
    onGet(id: number): void;
}) {
    if (maps[user.id]) {
        return <Container id="print-map">
            <div className='map-print-wrapper' dangerouslySetInnerHTML={{ __html: maps[user.id] }} ></div>
        </Container>
    } else {
        onGet(user.id);
        return null;
    }

}


const mapStateToProps = (state: any) => {
    return {
        maps: state.maps
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGet: (formationId: number) => { dispatch(get(formationId)); },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map);


const Container = styled.div`
    width: 70%;
    margin: 0 auto;
`