import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { throttle } from 'lodash';
import { mainApi } from 'src/helpers/api';
import GenderSelector, { TGender } from '../ui/GenderSelector';
import { Button, DateTimeInput, IPlace, Input, InputContainer, PlaceInput } from 'src/libs';

dayjs.extend(utcPlugin);

export default function PersonForm({
    type = 'edit',
    onSave = () => { },
    onFormEdit = () => { },
    initialName,
    initialGender,
    initialPlace,
    initialDate,
    initialTime,
    isMain,
    showDawnOnboarding,
}:{
    type?: string,
    onSave?(name: string, place: IPlace, date: string, time: string, gender: TGender): void,
    onFormEdit?(v: boolean): void,
    initialGender: TGender,
    initialName: string,
    initialPlace: IPlace,
    initialDate: string,
    initialTime: string,
    isMain?: boolean,
    showDawnOnboarding?: boolean,
}) {
    const [name, setName] = React.useState(initialName);
    const [nameError, setNameError] = React.useState<string>();
    const handleName = (name: string) => {
        setName(name);
        if (name.trim() === '') setNameError('Имя не может быть пустым');
        else if (!/^[a-z,а-я,ё,\s]*$/i.test(name)) setNameError('В имени могут быть только буквы');
        else setNameError(undefined)
    }

    const [gender, setGender] = React.useState<TGender>(initialGender || null);
    const [lon, setLon] = React.useState(initialPlace.lon || 0);
    const [lat, setLat] = React.useState(initialPlace.lat || 0);
    const [place, setPlace] = React.useState(initialPlace.name || '');
    const [placeError, setPlaceError] = React.useState<string>();

    const inputContainerStyle = {marginBottom: '1.8rem'}
    const buttonStyle = {padding: '0.9rem 1rem', opacity: '1', borderRadius: '0.375rem'}

    const handlePlace = (place: IPlace) => {
        setPlace(place.name);
        setLon(place.lon);
        setLat(place.lat);
        if (isMain && showDawnOnboarding) {
            //@ts-ignore
            window.ym(49958389, 'reachGoal', 'City_onboarding_dawn');
        }
        setPlaceError(undefined);
    }

    const onChangePlace = (val: string) => {
        if (val?.length < 3) {
            setPlaceError('Введите от трех символов')
        } else {
            setPlaceError('Выберите город из списка')
        }
    }

    const [date, setDate] = React.useState(initialDate); //initialDate
    const [dateError, setDateError] = React.useState<string>();

    const handleDate = (isoDate: string) => {
        if (Date.parse(isoDate) >= Date.now()) {
            setDateError('Введите дату в прошлом');
        } else if (Date.parse(isoDate) < Date.parse('1900')) {
            setDateError('Введите корректную дату')
        } else {
            setDate(isoDate);
            if (isMain && showDawnOnboarding) {
                //@ts-ignore
                window.ym(49958389, 'reachGoal', 'Date_onboarding_dawn');
            }
            setDateError(undefined);
        }
    }

    const [time, setTime] = React.useState(initialTime);
    const [timeError, setTimeError] = React.useState<string>();

    const handleTime = (isoTime: string) => {
        setTime(isoTime);
        if (isMain && showDawnOnboarding) {
            //@ts-ignore
            window.ym(49958389, 'reachGoal', 'TimeBirth_onboarding_dawn');
        }
        setTimeError(undefined);
    }

    const isError = nameError || placeError || dateError || timeError;
    const isEmpty = name === '' || place === '' || date === '' || time === '' || !gender;

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()

        if (!isError && !isEmpty) {
            // console.log('on save -', date, time)
            //const fDate = dayjs(date.rep).isValid() ? formatedDate(date) : date;
            //const fTime = dayjs(time).isValid() ? formatedTime(time) : time;
            onSave(name, { name: place, lat, lon }, date, time, gender);
        }
    }

		const throttleOnSubmit = throttle(onSubmit, 3000)

    const checkInputMode = (errorMessage?: string) => {
        return errorMessage ? 'error' : 'normal';
    }

    return <Form onSubmit={throttleOnSubmit}>

        {   !isMain &&
            <InputContainer label='Имя' message={nameError} style={inputContainerStyle}>
                <Input
                    size="medium"
                    placeholder="Как зовут человека?"
                    value={name}
                    onChange={(val) => { onFormEdit(true); handleName(val) }}
                    mode={checkInputMode(nameError)}
                    autoFocus
                />
            </InputContainer>
        }
        <InputContainer label='Ваш пол' style={inputContainerStyle}>
            <GenderSelector value={gender} setValue={setGender} compact />
        </InputContainer>

        <InputContainer label='Дата рождения' message={dateError} style={inputContainerStyle}>
            <DateTimeInput
                type='date'
                size='medium'
                value={date}
                onChange={(isoDate) => { onFormEdit(true); handleDate(isoDate) }}
                mode={checkInputMode(dateError)}
                hideIcon={true}
                onInvalid={() => setDateError('Введите корректную дату')}
                utcMode
            />
        </InputContainer>

        <InputContainer label='Время рождения' message={timeError} style={inputContainerStyle}>
            <DateTimeInput
                type='time'
                size='medium'
                value={time}
                onChange={(isoTime) => { onFormEdit(true); handleTime(isoTime) }}
                mode={checkInputMode(timeError)}
                hideIcon={true}
                onInvalid={() => setTimeError('Нужно ввести время')}
                utcMode
            />
        </InputContainer>

        <InputContainer label='Место рождения' message={placeError} style={inputContainerStyle}>
            <PlaceInput
                size="medium"
                mode={checkInputMode(placeError)}
                placeholder="Где родился?"
                value={place}
                asyncDataFn={mainApi.places.bind(mainApi)}
                asyncDetailFn={mainApi.place.bind(mainApi)}
                onChange={onChangePlace}
                onSelect={(place: IPlace) => { onFormEdit(true); handlePlace(place) }}
                lang="ru"
            />
        </InputContainer>

        <Nav>
            {!isError && !isEmpty && <>
                <Button type='submit'
												size='small'
												color={'var(--color-sea)'}
												style={buttonStyle}>
									{type === 'edit' ? 'Сохранить' : 'Создать карточку'}
								</Button>  Или нажмите Enter
            </>}
        </Nav>
    </Form >;
}

const Form = styled.form`
    position: relative;

    & > * + * {
        margin-top: 1rem;
    }
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    min-height: 3rem;

    & button {
        margin-right: 1.125rem;
        color: var(--color-white);
    }
`
