import React from 'react';

const GlobalTrends = ({
  primaryTrends,
  secondaryTrends,
  heading,
  description,
  compensationIntro,
  actualTo,
  actualToText,
}: {
  primaryTrends: any,
  secondaryTrends: any,
  heading: string,
  description: string,
  compensationIntro: string,
  actualTo: string,
  actualToText: string,
}) => {

  return <div className="trends">
    {/* <h1>{heading}</h1> */}
    <p>{description}</p>

    {[primaryTrends, secondaryTrends].map((trends, i) => trends
      ? <React.Fragment key={`trend_${i}`}>
        <h2>{trends.title}</h2>
        {trends.importantNotice &&
          <p className="important-notice">
            {trends.importantNotice}
          </p>
        }
        <ins>{actualToText}{actualTo}</ins>
        <p>{trends.description}</p>
        <ul className="list-wrap-global">
          {trends.group?.map((item: any, j: number) => item.compensation
            ? <li className="list-item red" key={`trend_${i}_group_${j}`}>
              <div className="list-item-data">
                {item?.texts?.length
                  ? item.texts.map((text: any, k: number) => (
                    <div className="block" key={`trend_${i}_group_${j}_text_${k}`}>
                      <div className="marker">+{text.weight}</div>
                      <div className="text">{text.text}</div>
                    </div>
                  ))
                  : null
                }
              </div>
              <div className="list-item-data compensation">
                <div className="block">
                  <div className="marker">!</div>
                  <div className="text">
                    <span className="intro">{compensationIntro}: </span>
                    {item.compensation}
                  </div>
                </div>
              </div>
            </li>
            : <li className="list-item gray" key={`trend_${i}_group_${j}`}>
              <div className="list-item-data">
                <div className="block">
                  <div className="marker">+{item.weight}</div>
                  <div className="text">{item.text}</div>
                </div>
              </div>
            </li>
          )}
        </ul>
      </React.Fragment>
      : null
    )}
  </div>
}

export default GlobalTrends;