import React from 'react';

import healthUrl from '../../assets/img/idp/health.png';
import birth4Url from '../../assets/img/idp/4birth.png';
import energyUrl from '../../assets/img/idp/energy.png';
import financeUrl from '../../assets/img/idp/finance.png';
import relationsUrl from '../../assets/img/idp/relations.png';
import profUrl from '../../assets/img/idp/prof.png';

import financeProfUrl from '../../assets/img/idp/finance_prof.jpg';
import styled from 'styled-components';

export default function Banner({
  block,
  partner
}: {
  block: string,
  partner: string
}){
  let url = null;

  if(partner === 'terenteva' || partner === 'terenteva_prof_inner') {
    if(block === 'relations') url = relationsUrl;
    else if(block === 'health') url = healthUrl;
    else if(block === 'birth') url = birth4Url;
    else if(block === 'energy') url = energyUrl;
    else if(block === 'relations') url = relationsUrl;
    else if(block === 'proforientation') url = profUrl;
    else if(block === 'finance_proforientation') url = financeProfUrl;
    else if(block === 'finance') url = financeUrl;
  }

  if(url) {
    return <Img src={url} />
  }

  return null;
}

const Img = styled.img`
  width: 100%;
`