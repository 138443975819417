import { CANVAS_HEIGHT, HEADER_HEIGHT, TREND_FIRST_Y } from './constants';
import { getTrendHeight, render } from './helpers';
import { TTrend } from './trends';
import { throttle, debounce } from 'lodash';

export interface IMousePosition {
  mouseX: number;
  mouseY: number;
}

//TODO: add trotling
export function initEvents(canvas: HTMLCanvasElement, data: TTrend[]) {

  const canvasOnMouseMove = (e: MouseEvent) => {
    const trendHeight = getTrendHeight(data.length);
    const rect = canvas.getBoundingClientRect();
    const y = rect.height - (e.clientY - rect.top);
  
    let activeIndex = -1;
    if(y > TREND_FIRST_Y && y < CANVAS_HEIGHT - HEADER_HEIGHT) {
      activeIndex = Math.floor((y - TREND_FIRST_Y) / trendHeight);
    }

    const mouseY: number = (e.clientY) - rect.top;
    const mouseX: number = e.clientX - rect.left;
  
    render(canvas.getContext('2d')!, { data, activeIndex }, { mouseX: mouseX, mouseY: mouseY })
  }

  const canvasOnMouseLeave = (e: MouseEvent) => {
    render(canvas.getContext('2d')!, { data, activeIndex: -1 })
  }

  const canvasOnClick = (e: MouseEvent) => {
    const trendHeight = getTrendHeight(data.length);
    const rect = canvas.getBoundingClientRect();
    const y = rect.height - (e.clientY - rect.top);

    let activeIndex = -1;
    
    if (y > TREND_FIRST_Y && y < CANVAS_HEIGHT - HEADER_HEIGHT) {
      activeIndex = Math.floor((y - TREND_FIRST_Y) / trendHeight);
      
      const { origin, pathname } = document.location;
      document.location.href = `${origin}${pathname}#trend-${activeIndex + 1}`
    }

  }
  
  canvas.addEventListener('mousemove', throttle(canvasOnMouseMove, 50));
  canvas.addEventListener('mouseleave', canvasOnMouseLeave);
  canvas.addEventListener('click', canvasOnClick);
  
}

