import React from 'react';
import styled from 'styled-components';
import { Marker } from "./index";

type NicheType = {
	k: string
	v: number
}

function Predisposition({
	nichesItems,
	nichesTitle,
	items,
	subtitle,
	title,
	description,
}: {
	nichesItems: NicheType[]
	nichesTitle: string
	items: NicheType[]
	subtitle: string
	title: string
	description: string
}) {
	return (
		<>
			<Title>{title}</Title>
			<i>{description}</i>
			<p>{subtitle}</p>
			<ul>
				{
					items.map((t: any, i: number) =>
						<li key={`talent_${i}`}>
							<div className="block">
								<Marker className='marker'>+{t.v}</Marker>
								<div className="text">{t.k}</div>
							</div>
						</li>
					)
				}
			</ul>
			<p>{nichesTitle}</p>
			<ul>{nichesItems.map((n: any, i: number) => <li key={i.toString()}>{n.k}</li>)}</ul>
		</>
	)
}

export default Predisposition;

const Title = styled.div`
  font-size: large;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
`