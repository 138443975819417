import { fetchAPI_REST } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

import { signOut } from 'src/store/actions/user';
import { IProduct } from 'src/components/Buy';
import { Region } from 'src/libs';

export const PRODUCTS_GET_SUCCESS = 'PRODUCTS_GET_SUCCESS';

export const get = (id: number, country: Region) => {
    const route = id ? {...routes.FORMATION_PRODUCTS, url: `${routes.FORMATION_PRODUCTS.url}/${id}?country=${country}`} : {...routes.FORMATION_PRODUCTS, url: `${routes.FORMATION_PRODUCTS.url}`}  // FIXME: ???

    return (dispatch: Dispatch<any>) => {
        fetchAPI_REST(route)
            .then((data: any) => {
                dispatch(getSuccess(data, country))
            })
            .catch(error => {
                dispatch(signOut(error));
            });
    };
}

const getSuccess = (data: any, country: Region) => {
	const data_ = data.map((item: IProduct) => ({...item, country}))

  return {
      type: PRODUCTS_GET_SUCCESS,
      payload: data_
  }
}
