import React from 'react';
import styled from 'styled-components';
import Avatar1 from 'src/assets/img/banner/Avatar1.png'
import { isMobile } from "react-device-detect";
import { SideArrow } from 'src/assets/img/banner';

export default function NewConsultationsBanner({
  mode = 'idp',
  link,
  className = ''
}: {
  mode: 'idp' | 'ap';
  link: string;
  className?: string;
}) {
  const clickHandler = () => {
    mode === 'idp' ?
      window.ym(49958389, 'reachGoal', 'click-bunner_consultation_IPR_dawn') :
      window.ym(49958389, 'reachGoal', 'click-bunner_consultation_prognoz_dawn');
    window.open(link);
  }

  return (
    <Wrapper onClick={() => clickHandler()} className={className} isMobile>
      <section className="chronos_a cons">
        <div className='circle1'></div>
        <div className='circle2'></div>
        <div className='circle3'></div>
        <img src={Avatar1} />

        <div className="content">
          <h1>Разберите {mode === 'idp' ? 'план' : 'прогноз'} с астрологом</h1>
          <p>93% {isMobile ? '' : 'наших'} клиентов {mode === 'idp' ? 'получают' : 'чувствуют'} ощутимые результаты уже после первой консультации</p>

          <button>
            <a href={link} rel="noopener noreferrer" target="_blank">
              <span>Записаться на консультацию</span>
              {!isMobile && <SideArrow />}
            </a>
          </button>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isMobile: boolean }>`
  cursor: pointer;
  user-select: none;
  background: #EBECFE;
  border: 1px solid rgba(237, 104, 46, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
  height: ${isMobile ? '10rem' : '15rem'};


  img {
    position: absolute;
    right: ${isMobile ? '-0.9rem' : '0.5rem'};
    width: ${isMobile ? '15rem' : '22rem'};
    bottom: ${isMobile ? '0.8rem' : '-1.4rem'};
  }
  .circle1 {
    position: absolute;
  width: 650px;
  height: 650px;
  left: 137px;
  top: -186px;

  background: radial-gradient(48.01% 48.01% at right, #EBECFE 20.6%, rgba(255, 255, 255, 0.48) 100%);
  border-radius: 100rem;
  }

  .circle2 {
    position: absolute;
    width: 427px;
    height: 427px;
    left: 366px;
    top: -95px;

    background: radial-gradient(27rem 100rem at right, rgba(215, 216, 233, 0) 50.28%, rgba(223, 227, 255, 0.56) 100%);
    border-radius: 90rem;
  }

  .circle3 {
    position: absolute;
  width: 14rem;
  height: 14rem;
  right: ${isMobile ? '0rem' : '2rem'};
  bottom: -2rem;


  background: #B1BCFA;
  border-radius: 100rem;
  }

  .content {
    width: 70%;
    z-index: 1;

    a {
      color: var(--colors-white);
      text-decoration: none;
    }
  }

  .chronos_a {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 1.75rem 1.5rem 1.563rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
  }

  .chronos_a h1 {
    font-size: ${isMobile ? '0.8rem' : '1.5rem'};
    color: var(--text-primary);
    font-weight: 500;
    margin: 0.75rem 0;
  }

  .chronos_a p {
    max-width: 90%;
    margin: 0;
    font-size: ${isMobile ? '0.65rem' : '1.125rem'};
    line-height: ${isMobile ? '0.8rem' : '1.688rem'};
    color: var(--text-secondary);
  }

  .chronos_a.cons {

  }

  .chronos_a.cons h1 {
    margin-top: 0;
  }

  .chronos_a.cons .icon {
    svg {
      width: 5rem;
      height: 5rem;
    }
  }

  .chronos_a.cons .star {
    position: absolute;
    top: -60px;
    right: -60px;

    svg {
      width: 12rem;
    }
  }

  .chronos_a.cons button {
    appearance: none;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.125rem;
    height: ${isMobile && '2rem'};;
    font-size: 1.125rem;
    line-height: 27px;
    /* background-color: #ED682E; */
    background-color: #485FEA;
    color: var(--colors-white);
    width: ${isMobile ? '80%' : '100%'};;
    padding: 0.625rem 0 0.688rem;
    border-radius: 0.375rem;

    /* a {
      display: inline-block;
      width: 100%;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    } */

    span {
      font-size: ${isMobile && '0.7rem'};
    }

    svg {
      vertical-align: middle;
      color: var(--colors-white);
      margin-left: 1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
