import { BACKGROUND_COLOR, BACKGROUND_COLOR_OPACITY, CANVAS_HEIGHT, TIMELINE_LINE_COLOR, TREND_FIRST_Y, TREND_LINE_COLOR, TREND_RIGHT_INFO_CIRCLE_RADIUS, TREND_RIGHT_INFO_PADDING } from './constants';
import { getTrendHeight, adjustPoints, hp } from './helpers';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';

export type TTrend = {
  color: string;
  points: number[][];
  keys: string[][];
  intersectionDate: string;
  directionPeriods: { start: string; end: string; dir: number }[]
}

type TDrawTrendOptions = {
  index: number;
  trend: TTrend;
  trendY: number;
  trendHeight: number;
  canvasWidth: number;
  activeIndex: number;
}

export type TDrawTrendsOptions = {
  data: TTrend[];
  activeIndex: number;
}

export function drawTrends(ctx: CanvasRenderingContext2D, { data, activeIndex }: TDrawTrendsOptions) {
  const trendHeight = getTrendHeight(data.length);

  data.forEach((trend, index) => {
    const trendY = CANVAS_HEIGHT - (TREND_FIRST_Y + index * trendHeight);
    const canvasWidth = parseInt(ctx.canvas.style.width);

    const drawOptions: TDrawTrendOptions = { index, trend, trendY, trendHeight, canvasWidth, activeIndex }

    drawTrendLine(ctx, drawOptions);
    drawTrendGraphic(ctx, drawOptions);
    drawTrendRightInfo(ctx, drawOptions);

  });
}

function drawDashLine(ctx: CanvasRenderingContext2D, x: number, y: number) {
  ctx.lineWidth = 1;
  ctx.strokeStyle = TREND_LINE_COLOR;
  ctx.setLineDash([5, 3]);
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x, CANVAS_HEIGHT - TREND_FIRST_Y);
  ctx.stroke();
  ctx.setLineDash([])
}

function drawTrendLine(ctx: CanvasRenderingContext2D, { trendY, canvasWidth }: TDrawTrendOptions) {
  ctx.lineWidth = 1;
  ctx.strokeStyle = TREND_LINE_COLOR;

  ctx.beginPath();
  ctx.moveTo(0, trendY);
  ctx.lineTo(canvasWidth, trendY);
  ctx.stroke();
}

function drawTrendRightInfo(ctx: CanvasRenderingContext2D, { activeIndex, index, trend, trendY, trendHeight, canvasWidth }: TDrawTrendOptions) {
  ctx.textAlign = 'end';
  ctx.fillStyle = TIMELINE_LINE_COLOR;
  ctx.lineWidth = 1.5;
  ctx.strokeStyle = trend.color;
  ctx.font = `12px monospace`;

  const timeStamp = trend.points.at(-1)?.[0]! * 1000;
  const string = `до ${dayjs(timeStamp).format('DD.MM')}`;

  ctx.fillText(string, canvasWidth - TREND_RIGHT_INFO_PADDING, trendY - trendHeight / 2 - 5);

  activeIndex !== index && (ctx.globalAlpha = 0.5);
  const circleX = canvasWidth - TREND_RIGHT_INFO_PADDING - ctx.measureText(string).width - 6 - TREND_RIGHT_INFO_CIRCLE_RADIUS;

  ctx.beginPath();
  ctx.arc(circleX, trendY - trendHeight / 2, TREND_RIGHT_INFO_CIRCLE_RADIUS, 0, Math.PI * 2);
  ctx.stroke();

  ctx.textAlign = 'center';
  ctx.fillStyle = trend.color;

  ctx.font = `12px Inter`;
  ctx.fillText(String(index + 1), circleX, trendY - trendHeight / 2 - 5);

  activeIndex !== index && (ctx.globalAlpha = 1);

}

function drawTrendGraphic(ctx: CanvasRenderingContext2D, { activeIndex, index, trend, trendY, trendHeight, canvasWidth }: TDrawTrendOptions) {
  const firstX = +dayjs().startOf('month');
  const lastX = +dayjs(firstX).add(13, 'month');

  let points = adjustPoints(trend.points);


  const xPoints = points.map(([x,]) => ((((x * 1000) - firstX ) / (lastX - firstX) ) * canvasWidth) );
  const yPoints = points.map(([, y]) => trendY - (trendHeight * (y / 100)));
  // const _yPoints = points.map(([, y]) => y);

  try {
    ctx.lineWidth = 2;
    ctx.strokeStyle = trend.color;
    ctx.beginPath();

    const cxMax = xPoints.at(-1) || 0;
    let cy = 0;

    ctx.moveTo(xPoints[0], yPoints[0])

    for (let i = 1; i < xPoints.length; i++) {
      cy = yPoints[i] - ctx.lineWidth;
      
      ctx.bezierCurveTo(
        xPoints[i - 1] + (xPoints[i] - xPoints[i - 1]) / 2,
        yPoints[i - 1] - ctx.lineWidth,
        xPoints[i - 1] + (xPoints[i] - xPoints[i - 1]) / 2,
        yPoints[i] - ctx.lineWidth,
        xPoints[i],
        yPoints[i] - ctx.lineWidth
      )

    }

    ctx.stroke();

    (activeIndex !== -1 && activeIndex !== index) && (ctx.globalAlpha = 0.5);
    
    ctx.stroke();
    
    (activeIndex !== -1 ?? activeIndex !== index) && (ctx.globalAlpha = 1);

    activeIndex === index && drawDashLine(ctx, cxMax, cy);

    drawTrendGradient(ctx, 'right', { trend, index, activeIndex, trendY, trendHeight, canvasWidth });
    drawTrendGradient(ctx, 'left', { trend, index, activeIndex, trendY, trendHeight, canvasWidth });

    // console.log(`drawTrendGraphic index: ${index} trendY: ${trendY} xPoints: `, JSON.stringify(xPoints, null, 2), 'yPoints: ', JSON.stringify(yPoints, null, 2))

  } catch (err) {
    console.log('drawTrendGraphic err -', err)
  }

  
}

function drawTrendGradient(ctx: CanvasRenderingContext2D, type: 'left' | 'right', { index, trendHeight, canvasWidth }: TDrawTrendOptions) {
  const y1 = CANVAS_HEIGHT - TREND_FIRST_Y - (index + 1) * trendHeight;
  let gradient = ctx.createLinearGradient(0, 0, (isMobile ? 30 : 80), 0);
  let gradientRect: [number, number, number, number] = [0, y1, (isMobile ? 30 : 80), trendHeight];

  if (type === 'right') {
    gradient = ctx.createLinearGradient(canvasWidth, 0, canvasWidth - 150, 0);
    gradientRect = [canvasWidth - 150, y1, 150, trendHeight];
  }

  gradient.addColorStop(0.1, BACKGROUND_COLOR);
  gradient.addColorStop(1, BACKGROUND_COLOR_OPACITY);

  ctx.fillStyle = gradient;
  ctx.fillRect(...gradientRect);
}