import { AnyAction } from 'redux';
import { HOROSCOPE_GET_SUCCESS } from 'src/store/actions/horoscopeDaily';

export default function userReducer(state = {}, action: AnyAction) {
    switch (action.type) {
        case HOROSCOPE_GET_SUCCESS:
            return action.payload

        default:
            return state
    }
}