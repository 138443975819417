import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { OkIcon } from 'src/assets/img/icons';
import { IProduct } from 'src/components/Buy';
import { searchParams, trendsOverInfo } from 'src/helpers/utils';
import { TGender } from '../ui/GenderSelector';
import { isHideFormation } from 'src/helpers/formations';

export interface IFormation {
    createdAt: string;
    data: {
        idp: any,
        ap: any,
        cidp: any,
    }
    datetime: {
        date: string,
        time: string,
        gmt: number,
        isEmpty?: boolean;
    }
    form_id: number | null
    formationCount: number
    id: number
    main?: boolean
    name: string
    place: {
        isEmpty: boolean,
        lat: number,
        lon: number,
        name: string
    }
    gender: TGender,
    user_id: 9052;
    is_public: boolean;

    color?: string;
    image?: string;
    isNew?: boolean;

    hash?: string;
}

export default function PrognosisListCompact({
    title = 'Заголовок',
    list = [],
    user,
    share = false,
    mode
}: {
    title: string;
    list: IProduct[];
    user: IFormation;
    share?: boolean;
    mode: 'idp' | 'ap' | 'cidp';
}) {
    const sp = searchParams()

    return <Container>
        <header>{title}</header>
        <List>
            {list.map((item) => {
                const overInfo = trendsOverInfo(user, mode, item.key);

                if(isHideFormation(item, user)) return;

                let link = `/prognosis/${user.id}/${item.key}`;
                if (share) link = `/share${link}`
                if (item.isBuyed && !overInfo.isOver) return <Item key={item.key} ><Link to={{pathname: link, search: sp.toString()}}>{item.name}</Link><OkIcon /></Item>
                else return <Item key={item.key}><span>{item.name} </span></Item>
            })}
        </List>
    </Container >
}

const Container = styled.section`
    margin-bottom: 1.75rem;
    color: var(--color-gray);

    & header {
        font-size: 1rem;
        margin-bottom: 1.125rem;


        @media (max-width: 768px) {
            font-size: 0.875rem;
            margin-bottom: 1rem;
        }
    }
`

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const Item = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    margin-bottom: 1.75rem;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-bottom: 1.4rem;
    }

    & svg {
        margin-left: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    & a {
        color: var(--color-black2);
        text-decoration: none;
        max-width: 70%;
        word-wrap: break-word;

        &:hover {
            color: var(--color-gray);
        }
    }
`
