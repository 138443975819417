import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Notification from 'src/components/ui/Notification/';
import ScrollToTop from 'src/helpers/ScrollToTop';
import { getUser, setUser } from 'src/helpers/localStorage';
import { checkIsOpenRoute, getURL, searchParams } from 'src/helpers/utils';
import Prognosis from 'src/pages/prognosis/';
import { signInSuccess } from 'src/store/actions/user';
import notificationContext, { INotification, NotificationType } from 'src/store/context/notifications';
import styled from 'styled-components';
import { mainApi } from "../../helpers/api";
import Finance from '../finance/Finance';
import { ApiError, IAuth } from 'src/libs';

const TOKEN_LS = 'token'

// FIXME: duplicate
interface IUser {
	autoPayment: boolean;
	avatarUrl: string | null;
	confirmations: any;
	expiryDate: string;
	id: number;
	isUnderConstruction: boolean;
	language: string;
	lastName: string;
	firstName: string;
	levelOfAstrology: number;
	name: string;
	notifications: INotification[];
	nps2Visited: string[];
	token: {
		access: string;
		refresh: string;
	}
}

export const history = createBrowserHistory();

function App({
	user,
	onSignInSuccess,
}: {
	user: IUser
	onSignInSuccess(data: IAuth): void
}) {
	const [notifications, setNotifications] = React.useState<INotification[]>([]);
	const defaultPath = `/prognosis/0/`
	const sp = searchParams()
	const showLoading = !getUser() && Object.keys(user).length === 0;

	const add = (text: string, type: NotificationType = 'success', delay = 3000) => {
		const id = +new Date();
		setNotifications((notifications) => [...notifications, { text, type, id }]);

		setTimeout(() => {
			setNotifications((notifications) => [...notifications].filter(item => item.id !== id))
		}, delay);
	}

	const notificationContextValue = {
		notifications, add
	}

	const isOpenRoute = checkIsOpenRoute();

	// Кидаем на авторизацию
	useEffect(() => {
		if (!isOpenRoute) {
			const URL = getURL();
			const sp = URL.searchParams;

			const signIn = async () => {
				let auth: IAuth | null = null;
				if (sp.has(TOKEN_LS)) {
					try {
						auth = await mainApi.signIn(sp.get(TOKEN_LS) ?? '');
						mainApi.token = auth.token;
						setUser(auth);
						onSignInSuccess(auth);
					} catch (err) {
						const apiError = (err as ApiError);
						if (apiError.message.toUpperCase() === 'BLOCKED') {
							alert('Слишком много обращений, попробуйте через 5 минут')
						} else if (!apiError.skipGoToAuth) {
							mainApi.goToAuth();
						}
					} finally {
						sp.delete(TOKEN_LS);
						window.history.pushState({}, '', URL.toString());
					}
				} else {
					try {
						auth = await mainApi.refreshToken(mainApi.token?.refresh ?? '');
						mainApi.token = auth.token;
						setUser(auth as any);
						if (showLoading) window.location.reload();
					} catch (err) {
						mainApi.goToAuth();
					}
				}
			}

			signIn();
		}
	}, [])

	// Открытый роутинг
	if (isOpenRoute) {
		return <>
			<ScrollToTop />
			<Router history={history}>
				<Switch>
					<Route exact path='/share/prognosis/:userId/:key' component={Prognosis} />
					<Route path='/idp/:hash/' component={Prognosis} />
					<Route path='/ap/:hash/' component={Prognosis} />
					<Route path='/prognosis/' component={Prognosis} />

					<Route path='/finance/:hash' component={Finance} />
				</Switch>
			</Router>
		</>
	}

	if (showLoading) {
		return <></>
	}

	return <>
		<notificationContext.Provider value={notificationContextValue}>
			<ScrollToTop />
			<Switch>
				<Route exact path='/'> <Redirect to={{ pathname: defaultPath, search: sp.toString() }} /></Route>
				<Route exact path='/prognosis'> <Redirect to={{ pathname: defaultPath, search: sp.toString() }} /></Route>
				<Route path='/prognosis/:userId/' component={Prognosis} />
			</Switch>
		</notificationContext.Provider>


		<NotificationsList>
			<TransitionGroup>
				{notifications.map((item) =>
					<CSSTransition
						key={item.id}
						timeout={500}
						classNames="notification"
					>
						<Notification>{item.text}</Notification>
					</CSSTransition>
				)}
			</TransitionGroup>
		</NotificationsList>
	</>
}

const mapStateToProps = (state: any) => {
	return {
		user: state.user
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onSignInSuccess: (data: IAuth) => {
			dispatch(signInSuccess(data));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);

const NotificationsList = styled.div`
  position: fixed;
  bottom: 2rem;
  z-index: 100;

  width: 100%;
  display: flex;
  justify-content: center;

  & > div > div {
 		margin-top: 1rem;
  }
`
