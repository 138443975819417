import React from 'react';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";

import { MoreIcon } from 'src/assets/img/icons';

import PersonAvatar from 'src/components/PersonAvatar/'
import DropDown from 'src/components/ui/DropDown/'
import Label from 'src/components/ui/Label/'
import { IFormation } from 'src/components/PrognosisList/compact';

interface IMenuButton {
    name: string;
    action: () => void;
    red?: boolean;
}

export default function Person({
    user,
    active = false,
    onEdit = () => { },
    onDelete = () => { }
}: {
    user: IFormation,
    active: boolean,
    onEdit(): void,
    onDelete(): void
}) {
    const { name, color, image, isNew = false, main } = user;

    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    
    const menuButtons: IMenuButton[] = [];
    menuButtons.push({ name: 'Редактировать', action: () => onEdit() });
    !main && menuButtons.push({ name: 'Удалить', action: () => onDelete(), red: true });

    const avatarSize = isMobile ? '2.5rem' : '1.5rem';


    return <>
        <Item active={active} onMouseLeave={() => isMenuOpened && setIsMenuOpened(false)}>
            <PersonAvatar name={name} color={color} image={image} size={avatarSize} />
            <Name>{name}</Name>
            {isNew && <LabelContainer><Label>Новое</Label></LabelContainer>}
            <Button onClick={(event) => {
                setIsMenuOpened(!isMenuOpened)
                event.preventDefault()
            }}>
                <MoreIcon />
            </Button>
            {isMenuOpened && <DropDownContainer>
                <DropDown buttons={menuButtons} />
            </DropDownContainer>}
        </Item>


    </>
}

const Item = styled.div<{active: boolean}>`
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem;
    background-color: ${props => props.active ? `var(--color-gray2)` : `none`};
    border-radius: 0.375rem;

    &:hover {
        background-color: var(--color-gray2);
        & button {
            display: flex !important;
        }

        @media (max-width: 768px) {
            & button {
                display: none !important;
            }
        }
    }


    ${props => !props.active && `
        & button {
            display: none !important;
        }
    `}
    
`

const Name = styled.span`
    color: var(--color-black2);
    margin-left: 0.75rem;
    max-width: 9rem;
    overflow-x: hidden;
`

const LabelContainer = styled.span`
    margin-left: 0.75rem;
`

const Button = styled.button`
    display: flex;
    margin-left: 0.5rem;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--color-gray);
    outline: none;

    @media (max-width: 768px) {
        display: none;
    }

    & svg {
        margin-left: -0.1rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover {
        color: var(--color-black2);
    }
`

const DropDownContainer = styled.div`
    padding: 1.5rem;
    position: absolute;
    top: 1.5rem;
    z-index: 5;
`