import React from 'react';
import styled from 'styled-components';
import GlobalTrends from './GlobalTrends';
import LocalTrends from './LocalTrends';
import NewConsultationsBanner from '../banner/NewConsultationsBanner';
import ClosePeopleBanner from '../banner/ClosePeopleBanner';
import TrendsChart from './trendsChart';

// import mockData from './trendsChart/mock.json'

const componentsMap = {
  localTrends: LocalTrends,
  globalTrends: GlobalTrends,
};

export default function AutoPrediction({
  type,
  data
}: {
  type: keyof typeof componentsMap;
  data: any;
}) {
  if(!data || !componentsMap[type]) {
    return null;
  }

  const isLocalTrends: boolean = type === 'localTrends';

  const Comp = componentsMap[type];
  
  
  // если есть данные для графика
  const chartData = data.chart?.map((item: any, idx: number) => ({
    ...item
  }));
  

  return (
    <Content>
      {isLocalTrends && <TrendsChart data={chartData} />}
      <Comp {...data} />
      <NewConsultationsBanner className='chronos-ext' mode={'ap'} link={`${process.env.REACT_APP_BUNNER_LINK}/services/17?block=ap-global`}/>
      <ClosePeopleBanner className='chronos-ext' />
    </Content>
  );
}

export const Content = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.125rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-black2);
  position: relative;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .trends {
    margin-bottom: 2.25rem;
    --red-color: #EF5350;
    --green-color: #73B230;
  }
  .trends h1 {
    margin: 1rem 0rem;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.723rem;
    color: var(--text-primary);
  }
  .trends h2 {
    margin: 0.5rem 0rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.475rem;
    color: var(--text-primary);
  }

  .trends p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--text-secondary);
  }

  .trends ins {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-third);
    text-decoration: none;
  }

  .trends ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .local-trend {
    margin-bottom: 2rem;
  }

  .local-trend ul {
    list-style: disc !important;
    padding-left: 2em !important;
    margin-top: 1.75rem;
  }

  .local-trend ul .list-item {
    color: var(--text-third);
    margin-bottom: 1rem;
    border-left: none;
  }

  .list-item {
    position: relative;
    margin-bottom: 1.313rem;
    border-left: 2px solid;
  }

  .list-item-data {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    color: var(--text-secondary);
    margin-bottom: 1.313rem;
  }

  .list-item-data:last-child {
    margin-bottom: 0;
  }

  .list-item-data .block {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.313rem;
    margin-left: 1rem;
  }

  .list-item-data .marker {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-width: 1.75rem;
    height: 1.5rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
    border-radius: 0.313rem;
    color: #ffffff;
    background-color: var(--text-third);
    font-size: 1rem;
    font-weight: 600;
    margin-right: 1rem;
  }

  .list-item-data .text {
    color: var(--text-secondary);
  }

  .list-item-data.compensation .marker {
    background-color: var(--green-color) !important;
  }

  .list-item-data.compensation .intro {
    color: var(--green-color);
    font-weight: 600;
  }

  .list-item.red {
    border-color: var(--red-color);
  }

  .list-item.red .marker {
    background-color: var(--red-color);
  }

  .list-item.gray {
    border-color: var(--text-third);
  }

  .list-item.gray .marker {
    background-color: var(--text-third);
  }
`;
