import React from 'react';

export type NotificationType = 'success';

export interface INotification {
  id: number;
  text: string;
  type: 'success';
}

const initialValue: {
  notifications: INotification[],
  add: (text: string, type?: NotificationType, delay?: number) => void
} = {
  notifications: [],
  add: () => {}
} 

const notificationContext = React.createContext(initialValue);

export default notificationContext;