import { AnyAction } from 'redux';
import { MAP_GET_SUCCESS } from 'src/store/actions/maps';

type IMapsState = string[]

export default function mapsReducer(state: IMapsState = [], action: AnyAction) {
    switch (action.type) {
        case MAP_GET_SUCCESS:
            const newState = [...state];
            newState[action.payload.formationId] = action.payload.map;
            return newState;
        default:
            return state
    }
}