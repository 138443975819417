import { AnyAction } from "redux";

const redirect = (store: any) => (next: Function) => (action: AnyAction) => {
    if (action.type === 'REDIRECT') {
        window.location.href = action.payload.url;
    }

    return next(action)
}

export default redirect;
