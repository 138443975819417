import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { connect } from 'react-redux';

import { CloseIcon } from 'src/assets/img/icons';

import PersonForm from 'src/components/PersonForm/';
import Confirm from 'src/components/ui/Confirm/';

import { edit, IPerson } from 'src/store/actions/persons';
import {setOnboardingStatus} from "../../store/actions/user";
import {getUser} from "../../helpers/localStorage";

import { IFormation } from 'src/components/PrognosisList/compact';
import { TGender } from '../ui/GenderSelector';
import { formatedDate, formatedTime } from 'src/helpers/utils';
import { fromDateTime, IPlace } from 'src/libs';

dayjs.extend(utcPlugin);

function PersonEdit({
    onClose = () => { },
    onEdit,
    user,
    onOnboarding,
}: {
    onClose(): void,
    onEdit(person: IPerson): void,
    user: IFormation,
    onOnboarding(): void,
}) {
    const [isShowConfirm, setIsShowConfirm] = React.useState(false);
    const [isEditedForm, setIsEditedForm] = React.useState(false);

    document.title = `${user.name} - Редактирование`;

    const showDawnOnboarding = getUser().showDawnOnboarding
    
    React.useEffect(() => {
        if(user.main && showDawnOnboarding) {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Start_onboarding_dawn')
        } else {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Start_edit_dawn')
        }
    }, [user.main])

    const closeHandle = () => {
        if (isEditedForm) {
            setIsShowConfirm(true);
        } else {
            closeWithYM();
        }
    }
    
    const closeWithYM = () => {
        if(user.main && showDawnOnboarding) {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Closed_onboarding_dawn')
            onOnboarding()
        } else {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Closed_edit_dawn')
        }
        onClose();
    }

    const onSave = (name: string, place: IPlace, date: string, time: string, gender: TGender) => {
        const newPersonData = {
            id: user.id,
            name,
            place,
            gender: gender,
            datetime: {
                date: formatedDate(date),
                time: formatedTime(time),
            }
        }
        if (newPersonData.datetime.time.length === 5) newPersonData.datetime.time += ':00';
        if(user.main && showDawnOnboarding) {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Success_onboarding_dawn');
            onOnboarding()
        } else {
            //@ts-ignore
            window.ym(49958389,'reachGoal','Success_edit_dawn');
        }
        onEdit(newPersonData);
    }

    let date;
    let time;
    let dateTime; // isoDateString
    if (user.datetime.isEmpty) {
        dateTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0);
    } else {
        dateTime = fromDateTime(user.datetime.date, user.datetime?.time || '12:00:00');
    }

    date = dateTime;
    time = dateTime;


    return <Container>
        <Header>
            <button onClick={closeHandle}>
                Отменить редактирование
                <span><CloseIcon /></span>
            </button>
        </Header>

        <Forms>
            <header>Редактирование</header>
            <PersonForm
                type="edit"
                onSave={onSave}
                initialName={user.name}
                initialGender={user.gender}
                initialPlace={user.place}
                initialDate={date.toString()}
                initialTime={time.toString()}
                isMain={user.main}
                onFormEdit={setIsEditedForm}
                showDawnOnboarding={showDawnOnboarding}
            ></PersonForm>

        </Forms>

        <div></div>


        {isShowConfirm && <Confirm
            title='Изменения не сохранятся'
            text='Если вы закроете создание карты, то все именения не будут сохранены, вы уверены что хотите выйти?'
            buttons={[
                { name: 'Вернуться', type: 'a', action: () => { setIsShowConfirm(false); } },
                { name: 'Выйти', type: 'button', action: () => { closeWithYM(); } }
            ]}
        ></Confirm>}
    </Container>
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onEdit: (data: IPerson) => {
            dispatch(edit(data));
        },
        onOnboarding: () => {
            dispatch(setOnboardingStatus({
                id: 'dawn_onboarding',
            }))
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonEdit);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 3rem 7.5rem;
    background-color: var(--color-bg);
    color: var(--color-gray);
    z-index: 10;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 1rem;
        padding-bottom: 3rem;
        height: 100%;
    }
`

const Header = styled.div`
    display: flex;

    @media (max-width: 768px) {
        position: absolute;
        right: 1rem;
    }

    & button {
        display: flex;
        align-items: center;
        margin-left: auto;
        border: none;
        outline: none;
        background: none;
        font-size: 0.875rem;
        cursor: pointer;
        color: inherit;

        @media (max-width: 768px) {
            font-size: 0rem;
            z-index: 10;
        }

        & span {
            margin-left: 1rem;
            padding: 0.75rem;
            background-color: var(--color-gray2);
            border-radius: 50%;

            @media (max-width: 768px) {
                margin-left: 0.5rem;
                padding: 0.5rem;
            }

            & svg {
                width: 1.5rem;
                height: 1.5rem;
                display: block;

                @media (max-width: 768px) {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }

        &:hover {
            color: var(--color-black2);
        }
    }
`

const Forms = styled.ul`
    display: block;
    width: 40%;
    min-width: 25rem;
    margin: 0 0 0 25%;
    list-style: none;
    padding: 0;


    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        min-width: initial;
    }

    & > header {
        position: relative;
        top: -3rem;

        @media (max-width: 768px) {
            top: 0;
            margin-bottom: 2rem;
        }
    }

    
`
