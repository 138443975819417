import React from 'react';
import styled from 'styled-components';

export default function Label({
    color = 'var(--color-sea)',
    children
}: {
    color?: string,
    children: React.ReactNode
}) {
    return <Container color={color}>
        {children}
    </Container>
}

const Container = styled.label`
    display: inline-block;
    color: var(--color-white);
    background: ${props => props.color};
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    border-radius: 0.25rem;
`