import dayjs from "dayjs";
import { cloneDeep } from 'lodash';
import React from 'react';
import { isBrowser, isMobile } from "react-device-detect";
import { connect, useDispatch } from 'react-redux';
import { CloseIcon } from 'src/assets/img/icons';
import Loading from 'src/components/Loading/';
import PersonHeader from 'src/components/PersonHeader/';
import PersonHeaderCompact from 'src/components/PersonHeader/compact';
import { IFormation } from 'src/components/PrognosisList/compact';
import Promocode from 'src/components/Promocode/';
import Confirm from 'src/components/ui/Confirm/';
import { s3urls } from 'src/helpers/api';
import cloudPayments, { getUTMCommentForCP } from 'src/helpers/cloudPayments';
import { isHideFormation } from 'src/helpers/formations';
import { get as getProducts } from 'src/store/actions/products';
import { IProductForConfig, getConfig, removeConfig } from 'src/store/actions/purchases';
import styled from 'styled-components';
import { List } from './List';
import { PaymentMethod, paymentMethods } from './payments';
import { Button, PaymentCardPicker, Region } from "src/libs";


export interface IProduct {
	createdAt: string;
	discount: number;
	id: number;
	isBuyed: boolean;
	key: string;
	name: string;
	price: number
	type: string;
	version: string;
	isNew?: boolean;
	hasUpdate: boolean;
	gender: 'male' | 'female' | null;
	partnerDiscount?: number;
	country: Region
}

export enum ProductTypes {
	IDP = 'idp',
	CIDP = 'cIdp',
	AP = 'ap,'
}

export interface IPackage {
	id: number;
	discount: number;
	price: number;
	elements: IProduct[];
	type: ProductTypes
}

function Buy({
	onClose = () => { },
	onGetConfig,
	products,
	user,
	paymentConfig,
	onGetProducts
}: {
	onClose(): void,
	onGetConfig(items: IProductForConfig[], country: string): void,
	products: IProduct[],
	user: IFormation,
	paymentConfig: any
	onGetProducts(id: number, country: string): void
}) {

	const [isShowConfirm, setIsShowConfirm] = React.useState(false);
	const [isPlaceConfirm, setIsPlaceConfirm] = React.useState(false);
	const [cartItems, setCartItems] = React.useState<IProduct[]>([]);

	const currentPaymentMethod = paymentMethods.find(item => item.country === products[0].country) || paymentMethods[0]
	const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(currentPaymentMethod)

	const dispatch = useDispatch()

	document.title = `${user.name} - Оформление заказа`;

	React.useEffect(() => {
		//@ts-ignore
		window.ym(49958389, 'reachGoal', 'Start_buy_dawn');
	}, [])

	React.useEffect(() => {
		if (user.place.isEmpty) {
			setIsPlaceConfirm(true);
		}
	}, [user.place.isEmpty])

	React.useEffect(() => {
		function goToPrognosis() {
			window.ym(49958389, 'reachGoal', 'Payment-success_buy_dawn');
			setTimeout(() => {
				window.location.href = `/prognosis/${user.id}`;
			}, 1000);
		}

		async function pay() {
			const cp = await cloudPayments();
			const chargeData = paymentConfig
			chargeData.data.comment = getUTMCommentForCP()

			new cp().charge(chargeData,
				() => { goToPrognosis(); },
				(error: string) => {
					dispatch(removeConfig())
					if (error === 'User has cancelled') return
					alert('Оплата не прошла, обратитесь в техническую поддержку');
				})
		}
		if (paymentConfig) {
			//@ts-ignore
			window.ym(49958389, 'reachGoal', 'Payment-start_buy_dawn')
			pay();
		}
	}, [paymentConfig, user])

	React.useEffect(() => {
		updateCarts()
	}, [products])

	const closeHandle = () => {
		if (cartItems.length > 0) {
			setIsShowConfirm(true);
		} else {
			onCloseWithYM();
		}
	}

	const onCloseWithYM = () => {
		//@ts-ignore
		window.ym(49958389, 'reachGoal', 'Exit_buy_dawn')
		onClose();
	}

	const removeFromCart = (id: number) => {
		const newCartItems = cartItems.filter(item => item.id !== id);
		setCartItems(newCartItems);
	}

	const addToCart = (item: IProduct) => {
		if (isInCart(item.id)) return false;

		let cartItemsCopy = [...cartItems]
		if (item.type === item.key) {
			cartItemsCopy = cartItemsCopy.filter(cartItem => cartItem.type !== item.type)
		}
		const newCartItems = [...cartItemsCopy, { ...item }];
		setCartItems(newCartItems);
	}

	const addToCartGroup = (items: IProduct[]) => {
		let cartItemsCopy = [...cartItems];
		items.forEach(item => {
			if (!isInCart(item.id)) cartItemsCopy.push(item);
		});
		setCartItems(cartItemsCopy);
	}

	const updateCarts = () => {
		if (cartItems.length === 0) return

		const updatedCarts = products.filter(item => cartItems.map(item => item.id).includes(item.id))
		setCartItems(updatedCarts)
	}

	const isInCart = (id: number) => {
		const findedItemInCart = cartItems.find(item => item.id === id);
		const findedItemInProducts = products.find(item => item.id === id);

		return !!findedItemInCart
			|| cartItems.findIndex(item => item.key === findedItemInProducts?.type) !== -1;
	}

	const isInCartGroup = (items: IProduct[]) => {
		let inCart = !!items.length;
		items.forEach(item => {
			inCart = isInCart(item.id) && inCart;
		});
		return inCart;
	}

	async function handleBuyClick() {
		//@ts-ignore
		window.ym(49958389, 'reachGoal', 'Buy-Button_buy_dawn')
		onGetConfig(cartItems.map(item => ({ formationId: user.id, formationProductId: item.id })), paymentMethod.country);
	}

	if (!user || products.length === 0) return <Loading />;

	const getStatusOver = (key: string) => {
		const serviceLife = dayjs(user.data?.ap?.[key]?.created).add(1, 'year');
		return dayjs().isAfter(serviceLife)
	}

	const getProductPrice = (product: IProduct) => {
		const partnerDiscount = product.partnerDiscount ?? 0
		return Math.round(product.price * (1 - partnerDiscount) * (1 - product.discount) + Number.EPSILON)
	}

	const getCommonPrice = (list: IProduct[]) => {
		return list.reduce((sum, next) => {
			const nextPrice = getProductPrice(next)
			return sum + nextPrice
		}, 0)
	}

	const userIdpList = user.data.idp || {};
	const userApList = user.data.ap || {};
	const userCIdpList = user.data.cidp || {};

	const cIdpList = products
		.filter(item => item.type === 'cidp')
		.map(item => { return { ...item, isBuyed: !!userCIdpList[item.key] } });

	const idpList = products
		.filter(item => item.type !== item.key && item.type === 'idp')
		.filter(item => !isHideFormation(item, user))
		.map(item => { return { ...item, isBuyed: !!userIdpList[item.key] } });
	const apList = products
		.filter(item => item.type !== item.key && item.type === 'ap')
		.map(item => { return { ...item, isBuyed: !!userApList[item.key] } });

	const cartItems_ = cloneDeep(cartItems);
	let idpPackage: IPackage | null = null;
	const idpDiscount = products.find(item => item.key === 'idp')!.discount;
	const idpDontBuyed = cloneDeep(idpList.filter(item => !item.isBuyed));

	if (idpDontBuyed.length > 1) {
		idpPackage = {
			id: -5,
			discount: idpDiscount,
			price: getCommonPrice(idpDontBuyed),
			elements: idpDontBuyed,
			type: ProductTypes.IDP,
		}

		const isBothRelations = idpDontBuyed.find(item => item.key === 'relationsMale') && idpDontBuyed.find(item => item.key == 'relationsFemale');

		if (idpDontBuyed.every(item => cartItems_.find(cartItem => cartItem.id === item.id))) {
			cartItems_.forEach(item => idpDontBuyed.find(item2 => item2.id === item.id) && (item.discount = idpDiscount));
			if (isBothRelations) {
				cartItems_.find(item => item.key === 'relationsMale')!.discount = 1;

			}
		}

		if (isBothRelations) {
			idpPackage.price -= (idpDontBuyed.find(item => item.key === 'relationsMale')?.price || 0)
		}
	}

	let apPackage: IPackage | null = null;
	const apDiscount = products.find(item => item.key === 'ap')!.discount;
	const apDontBuyed = cloneDeep(apList.filter(item => !item.isBuyed || getStatusOver(item.key)));

	if (apDontBuyed.length > 1) {
		apPackage = {
			id: -6,
			discount: apDiscount,
			price: getCommonPrice(apDontBuyed),
			elements: apDontBuyed,
			type: ProductTypes.AP,
		}
		if (apDontBuyed.every(item => cartItems_.find(cartItem => cartItem.id === item.id))) {
			cartItems_.forEach(item => apDontBuyed.find(item2 => item2.id === item.id) && (item.discount = apDiscount));
		}
	}

	const cartSum = Math.round(cartItems_.reduce((sum, current) => sum + getProductPrice(current), 0) + Number.EPSILON);
	const cartSumOld = Math.round(cartItems_.reduce((sum, current) => sum + +current.price, 0));
	const idpHeader = <>План развития {idpDontBuyed && idpDontBuyed.length === 0 ? <aside>Куплено</aside> : null}</>
	const apHeader = <>Автопрогноз {apDontBuyed && !apDontBuyed.length ? <aside>Куплено</aside> : null}</>

	const onChangeCardHandler = (value: PaymentMethod) => {
		setPaymentMethod(value)
		onGetProducts(user.id, value.country)
	}

	const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
					<>
					При покупке вы соглашаетесь с <a target="_blank" href={s3urls.termsOfUseKZ}>пользовательским соглашением</a>,
						а также с <a target="_blank" href={s3urls.privacyPolicyKZ}>политикой конфиденциальности</a>
          			</>
				)
			default:
				return (
					<>
					При покупке вы соглашаетесь с <a target="_blank" href={s3urls.termsOfUseRU}>пользовательским соглашением</a>,
						а также с <a target="_blank" href={s3urls.privacyPolicyRU}>политикой конфиденциальности</a>
          			</>
				)
		}
	}

	return <Container>
		<Header>
			<button onClick={closeHandle}>
				Отменить покупку
				<span><CloseIcon /></span>
			</button>
		</Header>

		<Block>
			<Left>
				<header><span>Покупки</span>
					{isBrowser && <PersonHeaderCompact user={user} />}
					{isMobile && <PersonHeader user={user} />}
				</header>

				{isMobile && <Promocode formationId={user.id} />}

				{/* CIdp */}
				{
					cIdpList.length > 0 && <List header='Для ребенка. Актуально до 14 лет'
						list={cIdpList}
						addToCart={(value) => addToCart(value)}
						isInCart={(id) => isInCart(id)}
						currency={paymentMethod.code}
					/>
				}

				{/* Idp */}
				<List header={idpHeader}
					list={idpList}
					package={idpPackage}
					addToCart={(value) => addToCart(value)}
					addToCartGroup={(value) => addToCartGroup(value)}
					isInCart={(id) => isInCart(id)}
					isInCartGroup={(products: IProduct[]) => isInCartGroup(products)}
					currency={paymentMethod.code}
				/>

				{/* Ap */}
				<List header={apHeader}
					list={apList}
					package={apPackage}
					addToCart={(value) => addToCart(value)}
					addToCartGroup={(value) => addToCartGroup(value)}
					isInCart={(id) => isInCart(id)}
					isInCartGroup={(products: IProduct[]) => isInCartGroup(products)}
					getStatusOver={(key) => getStatusOver(key)}
					currency={paymentMethod.code}
				/>
			</Left>

			<Right>
				{isBrowser && <Promocode formationId={user.id}></Promocode>}
				{cartItems_.length > 0 && <header><span>Итог</span> </header>}

				<ul>
					{cartItems_.map((item, index) =>
						<li key={index}>
							{item.name}
							<span className='price'>{getProductPrice(item)} {paymentMethod.symbol}</span>
							<CloseIcon onClick={() => removeFromCart(item.id)} />
						</li>
					)}
				</ul>

				{cartItems_.length > 0 && <footer>
					<StyledPaymentCardPicker
						items={paymentMethods}
						value={paymentMethod}
						showInfo={true}
						onChange={onChangeCardHandler}
					/>

					К оплате
					<span>
						{cartSumOld > cartSum && <del>{cartSumOld}{paymentMethod.symbol}</del>}
						{cartSum}{paymentMethod.symbol}
					</span>

					<Button style={{ padding: '1rem' }} size="small" color="#3DBDA6" onClick={handleBuyClick}><text>Перейти к оплате</text></Button>

					<p className='police'>
					{getLinks()}					
					</p>

				</footer>}
			</Right>
		</Block>

		{isShowConfirm && <Confirm
			title='Изменения не сохранятся'
			text='Если вы отмените покупку, то Ваша корзина не сохранится'
			buttons={[
				{ name: 'Вернуться', type: 'a', action: () => { setIsShowConfirm(false); } },
				{ name: 'Закрыть', type: 'button', action: () => { onCloseWithYM(); } }
			]}
		></Confirm>}

		{isPlaceConfirm && <Confirm
			title='Город не заполнен'
			text='Покупка невозможна - добавьте город в данную карту'
			buttons={[
				{ name: 'Закрыть', type: 'button', action: () => { onCloseWithYM(); } }
			]}
		></Confirm>}
	</Container>
}

const mapStateToProps = (state: any) => {
	return {
		products: state.products,
		paymentConfig: state.purchases.config
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onGetConfig: (cart: IProductForConfig[], country: string) => { dispatch(getConfig(cart, country))},
		onGetProducts: (id: number, country: Region) => { dispatch(getProducts(id, country))}
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Buy);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 3rem 7.5rem;
    background-color: var(--color-bg);
    color: var(--color-gray);
    z-index: 10;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 1rem;
        padding-bottom: 3rem;
        height: 100%;
    }
`

const Header = styled.div`
    display: flex;

    @media (max-width: 768px) {
        position: absolute;
        right: 1rem;
    }

    & button {
        display: flex;
        align-items: center;
        margin-left: auto;
        border: none;
        outline: none;
        background: none;
        font-size: 0.875rem;
        cursor: pointer;
        color: inherit;

        @media (max-width: 768px) {
            font-size: 0rem;
        }

        & span {
            margin-left: 1rem;
            padding: 0.75rem;
            background-color: var(--color-gray2);
            border-radius: 50%;

            @media (max-width: 768px) {
                margin-left: 0.5rem;
                padding: 0.5rem;
            }

            & svg {
                width: 1.5rem;
                height: 1.5rem;
                display: block;

                @media (max-width: 768px) {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }

        &:hover {
            color: var(--color-black2);
        }
    }
`

const Block = styled.div`
    display: flex;
    width: 80%;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`

const Left = styled.div`
    flex: 1;

    & > header {
        display: flex;
        margin-bottom: 2rem;

        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 0.5rem;
        }

        & > span:first-child{
            margin-right: 1.5rem;
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                margin-bottom: 1rem;
            }
        }
    }
`

const Right = styled.div`
    width: 20rem;
    margin-left: 10rem;

    @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        padding-bottom: 2rem;
    }

    & > header {
        margin-bottom: 2rem;
    }

    & > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        & > li {
            display: flex;
            font-size: 1rem;
            margin-bottom: 1rem;
            color: var(--color-black2);
            align-items: baseline;

            & span {
                margin-left: auto;
                color: var(--color-green);
            }

            & svg {
                width: 0.75rem;
                margin-left: 0.75rem;
                color: var(--color-gray);
                cursor: pointer;
            }
        }
    }

    & footer {
        margin-top: 4.5rem;

        @media (max-width: 768px) {
            margin-top: 2.5rem;
        }

        & span {
            display: block;
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 2rem;
            color: var(--color-green);

            & del {
                color: var(--color-gray);
                margin-right: 0.5rem;
            }
        }
        & button {
            color: rgba(255, 255, 255, 0.87)
        }
    }

    & .police {
        font-size: 0.75rem;

        & > a {
            color: var(--text-secondary);
        }
    }

		& .price {
			white-space: nowrap;
		}
`

const StyledPaymentCardPicker = styled(PaymentCardPicker)`
	.switcher_button {
		font-size: 0.875rem;
		text-align: center;
		height: 3rem;
	}
`
