import React from 'react';
import styled, {css} from 'styled-components';
import Energy from "./Energy";
import Health from "./Health";
import Proforientation from "./Proforientation";
import Finance from "./Finance";
import Relations from "./Relations";
import Birth from "./Birth";
import NewConsultationsBanner from '../banner/NewConsultationsBanner';
import ClosePeopleBanner from '../banner/ClosePeopleBanner';

const componentsMap = {
  birth: Birth,
  energy: Energy,
  proforientation: Proforientation,
  health: Health,
  finance: Finance,
  relationsFemale: Relations,
  relationsMale: Relations,
};

export type ComponentsMapType = keyof typeof componentsMap;

export default function DevPlan({
  type,
  data
}: {
  type: ComponentsMapType;
  data: any;
}) {
  if (!data || !componentsMap[type]) {
    return null;
  }

  const Comp = componentsMap[type];

  return (
    <Content>
      <Comp {...data} />
      <NewConsultationsBanner className='chronos-ext' mode={'idp'} link={`${process.env.REACT_APP_BUNNER_LINK}/services/18?block=idp-relations`} />
      {(type !== 'relationsMale' && type !== 'relationsFemale') && <ClosePeopleBanner className='chronos-ext' />}
    </Content>
  );
};

export const Content = styled.div<{mode?: string}>`
  position: relative;
  font-size: 1rem;
  color: var(--color-gray4);
  line-height: 1.5;

  ${props => props.mode === 'idp' && css`
    width: 50%;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  `}

  & h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    line-height: 1.125;
    color: var(--text-primary);

    @media (max-width: 768px) {
      margin: 0 0 0.3rem 0;
      font-size: 1rem;
      line-height: 1.2;
    }

    @media print {
      margin-bottom: 1rem;
    }
  }

  h3 {
    /* font-weight: 900; */
    margin: 1rem 0;
    line-height: 1.125;
  }

  h5 {
    margin-bottom: 1.5em;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--text-third);
  }

  ul {
    margin-top: 1em;
    margin-bottom: 1.5em !important;
    padding-left: 2em;
    color: var(--text-secondary);

    li:not(:last-child) {
      margin-bottom: 1em;
    }

    li {
      b {
        color: var(--text-primary);
      }

      .block {
        display: flex;
      }
    }

  }

  p {
    margin-bottom: 1.215em;
    color: var(--text-secondary);
  }

  .list-item {
    color: var(--color-gray);
  }

  .list-item_bold {
    font-weight: bold;
  }

  .list-item_black {
    color: var(--text-primary);
  }

  .red-cursive {
    font-style: italic;
    color: #D00000;
    margin-bottom: 1rem !important;
  }

  .signal {
    font-weight: bold;
    color: red;
  }

  .tense-sep {
    margin: 1.75em 0;
    border-top: 1px solid var(--element);
  }

  .aspects-block {
    position: relative;

    @media print {
      margin-bottom: 1.5rem;
    }
  }

  .aspect-item,
  .signal {
    display: block !important;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.375rem;
    border: 1px solid;

    @media print {
      border-width: 1pt !important;
    }
  }

  .aspect-item.minus {
    background-color: rgba(239, 83, 80, 0.1);
    border-color: rgba(239, 83, 80, 0.5);
  }
  .aspect-item.birth {
    background: rgba(242, 153, 74, 0.1);
    border: 1px solid rgba(242, 153, 74, 0.5);
    @media print {
      border-width: 1pt;
    }
  }
  .aspect-item.equal {
    background-color: rgba(64, 147, 244, 0.1);
    border-color: rgba(64, 147, 244, 0.5);
  }

  .aspect-item.compensation {
    background-color: rgba(115, 178, 48, 0.1);
    border-color: rgba(115, 178, 48, 0.5);
  }

  .signal {
    background-color: rgba(239, 83, 80, 0.1);
    border-color: rgba(239, 83, 80, 0.5);
  }

  .aspect-item h1,
  .signal h1 {
    font-size: 1.125rem;
    line-height: 1;
    margin: 0.625rem 0;
  }

  .aspect-item svg,
  .aspect-item img,
  .signal svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 1rem;
  }

  .aspect-item p,
  .signal p {
    margin: 1rem 0;
  }

  .warning {
    background-color: rgba(239, 83, 80, 0.1);
    border-color: rgba(239, 83, 80, 0.5);
  }
`;

export const Marker = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 1.75rem;
  height: 1.5rem;
  padding: 0.2rem;
  margin-right: 0.5rem;
  border-radius: 0.313rem;
  color: #ffffff;
  background-color: var(--text-third);
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
`

export const Block = styled.div<{mode?: string}>`
  display: block;
  position: relative;
  border: 1px solid;
  padding: 1rem 1.5rem;
  border-radius: 0.375rem;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 2rem;

  @media print {
    border-width: 1pt !important;
  }

  h3 {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 0.5rem;
  }

  ${(props) => {
    switch (props.mode) {
      case 'signal' || 'minus':
        return css`
          background-color: rgba(239, 83, 80, 0.1);
          border-color: rgba(239, 83, 80, 0.5);

          .iconContainer {
            color: red;
          }
        `;
      case 'equal':
      return css`
        background-color: rgba(64, 147, 244, 0.1);
        border-color: rgba(64, 147, 244, 0.5);
      `;
      case 'compensation':
      return css`
        background-color: rgba(115, 178, 48, 0.1);
        border-color: rgba(115, 178, 48, 0.5);
      `;
      default:
        return css`
          background: rgba(0, 0, 0, 0.02);
          border-color: rgba(0, 0, 0, 0.1);
        `;
    }
  }}
`
