export interface IRoute {
  description: string;
  method: string;
  url: string;
}
const routes = {
  // auth
  SIGN_IN: { 
    description: `Sign In`,
    method: 'post',
    url: '/auth/login',
  }, 
  
  SIGN_OUT: {
    description: `Sign Out`,
    method: 'post',
    url: '/auth/logout',
  },  
  
  REFRESH_TOKEN: {
    description: `Refresh token`,
    method: 'post',
    url: '/auth/refresh',
  }, 
  
  HOROSCOPE: {
    description: `Get weekly horoscope on specific date`,
    method: 'get',
    url: '/horoscope', //date in query
  },

  PROFILE: {
    description: `Profile`,
    method: 'get',
    url: '/user/profile'
  },

  ///cabinet of dawn
  FORMATION: {
    description: 'get formation by id',
    method: 'get',
    url: '/formations/:id',
  },

  FORMATION_PRODUCTS: { 
    description: 'get list of availible formations products',
    method: 'get',
    url: '/formations/products',
  },
  FORMATIONS: {
    description: `Get list of formations`,
    method: 'get',
    url: '/formations',
  },
  ADD_FORMATION: {
    description: 'add new formation',
    method: 'post',
    url: '/formations',
  },
  UPDATE_FORMATION: {
    description: 'update formation',
    method: 'put',
    url: '/formations/:id',
  },
  DELETE_FORMATION: {
    description: 'delete formation',
    method: 'delete',
    url: '/formations/:id',
  },
  
  IMPORT_IDP: {
    description: 'insert dummy formations products',
    method: 'post',
    url: '/formations/import',
  },
  
  CIRCLE: {
    description: 'get map svg',
    method: 'get',
    url: '/circle/:id',
  },

  IDP_HASH: {
    description: 'get idp by hash',
    method: 'get',
    url: '/misc/idp/:hash',
  },

  CIDP_HASH: {
    description: 'get idp by hash',
    method: 'get',
    url: '/misc/cidp/:hash',
  },

  IDP_HASH_PUT: {
    description: 'add data in data base',
    method: 'post',
    url: '/misc/idp/:hash',
  },

  CIDP_HASH_PUT: {
    description: 'add data in data base',
    method: 'post',
    url: '/misc/cidp/:hash',
  },

  AP_HASH: {
    description: 'get ap by hash',
    method: 'get',
    url: '/misc/ap/:hash',
  },

  AP_HASH_PUT: {
    description: 'add data in data base',
    method: 'post',
    url: '/misc/ap/:hash',
  },

  PAYMENT_CONFIG: {
    description: `Get payment config`,
    method: 'post',
    url: '/user/payments/config',
  },

  //promo
  USE_PROMOCODE: {
    description: `Use promocode`,
    method: 'post',
    url: '/promo',
  },
  CHECK_PROMOCODE: {
    description: `Check promocode`,
    method: 'post',
    url: '/promo/check',
  },

  // user
  ONBORDING: {
    description: `Set onboarding status`,
    method: 'put',
    url: '/user/notifications',
  },
  LOGOUT: {
    description: `Logout auth`,
    method: 'post',
    url: '/logout',
  },
}

export { routes }