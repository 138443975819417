import React from 'react';
import styled from 'styled-components';

import notificationContext from 'src/store/context/notifications';

import { connect } from 'react-redux';

import { get } from 'src/store/actions/persons';
import { check, reset, use } from 'src/store/actions/promocode';
import { IProduct } from 'src/components/Buy';
import { Button, IPromocodeAction, Input, TDawnPromoProduct } from 'src/libs';

function Promocode({
    products,
    promocode,
    formationId,
    onCheck,
    onUse,
    onReset,
    onGetPersons
}: {
    products: IProduct[],
    promocode: any,
    formationId: number,
    onCheck(formationId: number, code: string): void,
    onUse(formationId: number, code: string): void,
    onReset(): void,
    onGetPersons(): void
}) {
    const [code, setCode] = React.useState('');

    const context = React.useContext(notificationContext);

    React.useEffect(() => {
        onReset();
    }, [onReset])

    React.useEffect(() => {
        if (promocode?.use) {
            context.add('Промокод применен', 'success');
            onGetPersons();
            onReset();
            setCode('');
        }
    }, [promocode, context, onGetPersons, onReset])


    const errors = {
        'PROMO_NOT_FOUND': 'Этот промокод не найден',
        'PROMO_ALREADY_USED': 'Этот промокод уже использован',
        'PROMO_EXPIRED': 'Срок действия этого промокода истек',
        'PROMO_INCORRECT': 'Промокод поврежден. Обратитесь в тех. поддержку',
        'PROMO_USELESS': 'Все построения из промокода уже куплены'
    }

    const groupsNames = {
        'idp': 'план развития',
        'ap': 'автопрогноз'
    }

    let error = '';
    let groups: any[] = [];
    let items: any[] = [];

    let checkData = promocode?.check;
    let checkPromoActions: IPromocodeAction = checkData?.actions;

    if (checkData?.failed) {
        error = errors[checkData?.data[0] as keyof typeof errors] || 'Ошибка. Обратитесь в тех. поддержку';
    } else if (checkPromoActions?.app === 'dawn') {
        items = (checkPromoActions?.products as TDawnPromoProduct).map((item: any) => products.find(product => product.key === item));
        if(!items.filter(item => item).length) {
            items = [];
            error = 'Данный промокод невозможно использовать на данной карточке';
            checkPromoActions.products = []; 
        };

        //@ts-ignore
        groups = [...new Set(items.map(item => item.type))];
    }

    if (promocode?.use) {
    }

    const onInput = (value: string) => {
        onReset();
        setCode(value?.trim());
    }

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onCheck(formationId, code)
    }

    const resetCode = () => {
        onReset();
        setCode('');
    }

    const useCode = () => {
        onUse(formationId, code);
    }


    const formDisabled = !!checkData && !checkData.failed;


    return <Container inverse={true}>
        <header>Нашли промокод?</header>
        <CodeBlock onSubmit={onSubmit} disabled={formDisabled}>
            <Input size='small' value={code} onChange={onInput} />
            <Button size='small' onClick={() => onCheck(formationId, code)}>Добавить</Button>
        </CodeBlock>
        <Error>{error}</Error>
        {groups.length > 0 && <CodeResults>
            {groups.map(group => <li key={group}>
                Получить бесплатно {groupsNames[group as 'idp' | 'ap']}
                {items.filter(item => item.type === group).map(item => <b key={item.id}>{item.name}</b>)}
            </li>)}
        </CodeResults>}

        {checkPromoActions?.app === 'dawn' && <nav>
            <Button onClick={resetCode}>Отменить</Button>
            <Button disabled={Boolean(error.length)} onClick={useCode}>Применить промокод</Button>
        </nav>}
    </Container >
}

const mapStateToProps = (state: any) => {
    return {
        products: state.products,
        promocode: state.promocode
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onCheck: (formationId: number, promo: string) => {
            dispatch(check(formationId, promo));
        },
        onUse: (formationId: number, promo: string) => {
            dispatch(use(formationId, promo));
        },
        onReset: () => {
            dispatch(reset());
        },
        onGetPersons: () => {
            dispatch(get());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Promocode);

// <nav><Button color='var(--color-sea2)' colorHover='var(--color-sea)' fontSize='0.875rem' onClick={() => { }}>{promocode}</Button></nav>

const Container = styled.div<{inverse: boolean}>`
    padding: 1.5rem;
    color: var(--color-white);
    background: var(--color-sea);
    border-radius: 8px;
    margin-bottom: 1rem;


    @media (max-width: 768px) {
        padding: 1rem;
    }

    ${props => props.inverse && `
        background: none;
        border: 1px solid var(--color-gray3);
        color: var(--color-black);
    `}

    & header {
        display: block;
        font-size: 1.125rem;
        margin-bottom: 0.675rem;
        width: 90%;

        @media (max-width: 768px) {
            width: 98%;
        }
    }



    & nav {

        & button {
            font-size: 0.875rem;
            padding: 0.675rem;

            &:first-child{
                background: none;
                color: var(--color-black);
                border: 1px solid var(--color-gray3);
                margin-right: 0.5rem;
            }
        }
    }

`

const CodeBlock = styled.form<{disabled: boolean}>`
    display: flex;
    color: var(--color-white2);
    font-size: 0.875rem;
    line-height: 1.4;
    width: 90%;

    @media (max-width: 768px) {
        width: 98%;
    }

    ${props => props.disabled && `
        opacity: 0.5;
        pointer-events: none;
    `}

    & input{
        background: none;
        width: 7rem;
        padding: 0.5rem;
    }

    & button {
        color: var(--color-black);
        background: none;
        font-size: 0.875rem;

        &:hover {
            color: var(--color-sea);
            background: none;
        }
    }
`

const CodeResults = styled.ul`
    list-style: none;
    padding: 0;
    line-height: 1.65rem;

    & li {
        font-size: 0.875rem;
        color: var(--color-gray);
        margin-bottom: 1.125rem;

        & b {
            display:block;
            font-size: 1.125rem;
            color: var(--color-black);
            font-weight: normal;
        }
    }
`

const Error = styled.div`
    margin-top: 0.5rem;
    line-height: 1.5;
    font-size: 0.875rem;
    color: var(--color-red);
`
