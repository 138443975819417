import React from "react";
import {WarningTwoIcon} from "../../assets/img/icons";
import {Block} from "./index";

export type DisclaimerType = {
	email: string
	text: string
}

export function t(v: string) {
	const x = {
		"chronos.app.compensatoryLifeHack": 'Компенсаторный лайфхак:',
		"chronos.app.devPlan.noRiskMessage": 'Стандартный алгоритм считает, что при адекватном проживании 4 рождений ваш организм не подвержен медицинским рискам',
	}[v];
	return x ?? v;
}

export function listItems(items: string[]) {
	return items.map((item, i) => <li key={`li_${i}`}><span style={{color: 'var(--text-secondary)'}}>{item}</span>
	</li>);
}

export function tenseAspectsHeader(data: any, color: string, mode: string) {
	if(!data) return null;
	return (
		<div className={mode}>
			<img
				src={data.img}
				style={{
					position: 'relative',
					marginBottom: '1rem'
				}}
			/>
			<div style={{
				fontWeight: '500',
				fontSize: '1.2rem',
				// display: 'inline',
				color: 'var(--text-primary)',
				// color,
				// lineHeight: '16px',
				// position: 'relative',
				// left: '5px',
				// top: '-4px'
			}}>{data.title}</div>
			<p>{data.text}</p>
		</div>
	);
}

export function tenseAspects(items: any[]) {
	return items.map((item, i) =>
		<div key={`item_${i}`} className="aspects-block">
			{tenseAspectsHeader(item.negative, '#D72222', 'aspect-item minus')}
			{tenseAspectsHeader(item.prescription, '#F2994A', 'aspect-item birth')}
			{tenseAspectsHeader(item.compensation, '#3281CA', 'aspect-item equal')}
			{tenseAspectsHeader(item.positive, '#429F22', 'aspect-item compensation')}
		</div>
	);
}

export function psychotechnics(psychotechnics: any) {
	if(!psychotechnics) return null;
	return <div className='aspect-item minus' style={{background: 'rgba(0, 0, 0, 0.02)', border: '1px solid rgba(0, 0, 0, 0.1)'}}>
			<div style={{
				fontWeight: '500',
				fontSize: '1.2rem',
				color: 'var(--text-primary)',
			}}>{"Психотехники"}</div>
			<div style={{marginTop: '0.8rem'}}>{psychotechnics.description}</div>
			<ul style={{ color: 'var(--li-psychotechnics)' }}>{
				psychotechnics.psychotechnics.map((item: any, i: any) =>
					<li key={`li_${i}`}><span style={{color: 'var(--text-secondary)'}}><div><div style={{fontWeight: 600, display: 'inline-block'}}>{item.practic}</div>{item.text}</div></span></li>)
			}</ul>

	</div>
}

export function makeDisclaimer(v: any) {
	return <p>{v.text} <a href={`mailto:${v.email}`}>{v.email}</a>.</p>;
}

export function lifehack(text: string, t: Function) {
	return <Block className="lifehack-item">
		<WarningTwoIcon/>
		<h3>{t("chronos.app.compensatoryLifeHack")}</h3>
		<p>{text}</p>
	</Block>
}

export function signal(text: string) {
	return <p className="signal">{text}</p>;
}

export function group(group: string[]) {
	return <ul>{group.map((p, i) => <li key={`g_${i}`}>{p}</li>)}</ul>;
}

export function result(v: { text: string; marker?: string; }, id: number) {
	const toks = v.text.split('#!');
	return (
		<React.Fragment key={`result_${id}`}>
			<li>
				<div className="block">
					{v.marker && <div className="marker">{v.marker} </div>}
					<div className="text">{toks[0]}</div>
				</div>
			</li>
			{toks.length == 2 && <span className="red-cursive">{toks[1]}</span>}
		</React.Fragment>
	);
}