import { AnyAction } from 'redux';
import { FORMATION_GET_SUCCESS } from 'src/store/actions/formations';

export default function formationsReducer(state = {}, action: AnyAction) {
    switch (action.type) {
        case FORMATION_GET_SUCCESS:
            return {...state, [action.payload.id]: action.payload};
        default:
            return state
    }
}