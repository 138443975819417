export default class CircleQueue<T> {
    private items: any[] = [];
    private i = 0;
    constructor(items: T[]) {
        this.items = [...items];
    }

    get next() {
        const item = this.items[this.i];
        if (this.i + 1 < this.items.length) this.i++;
        else this.i = 0;
        return item;
    }


}