import { AnyAction } from 'redux';
import { PERSONS_GET_SUCCESS } from 'src/store/actions/persons';

export default function personsReducer(state = [], action: AnyAction) {
    switch (action.type) {
        case PERSONS_GET_SUCCESS:
            return [...action.payload];
        default:
            return state
    }
}