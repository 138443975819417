import React, { useEffect, useState } from "react";
import { BackIcon, ChronosLogo, CloseIcon } from "src/assets/img/icons";
import AstrologerIcon from 'src/assets/img/banner/astrologerIcon.png';
import styled from "styled-components";
import { getUser } from 'src/helpers/localStorage';

import { numWord } from "src/helpers/utils";


export default function IndiTopLine({
  link,
  onClose = () => { },
}: {
  link: string;
  onClose(): void;
}) {

  const trialDays = Number(link.match(/\?d=(\d*)/)?.[1]) || 3;

  const [width, setWidth] = useState(window.innerWidth);

  const user = getUser();
  const clickHandler = () => {
    //@ts-ignore
    window.dataLayer.push({ 'event': 'dawn_topline_plan_for_children_click', 'user_id': user?.id });
    window.open(link);
  }

  const closeHandler = (e: any) => {
    e.stopPropagation();
    onClose();
  }

  useEffect(() => {
    const handleResize = (event: any) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container onClick={clickHandler}>
      <ImgWrapper>
        {width > 660 ?
          <><div>Chronos</div><StyledPlus>Plus</StyledPlus></> :
          <ChronosLogo />
        }
      </ImgWrapper>
      <div>
        <div>
          <Text>
            Персональный ежедневный прогноз по 7 сферам жизни
          </Text>
        </div>
        <StyledButton><div>Попробовать {trialDays} {numWord(trialDays, ['день', 'дня', 'дней'])} {width <= 660 && <BackIcon />}</div></StyledButton>
      </div>

      <CloseButton onClick={closeHandler}><span><CloseIcon /></span></CloseButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;

  height: 30px;
  left: 0px;
  top: 0px;
  background: radial-gradient(220.97% 2360.96% at -102.33% -98.97%, #000A40 0%, rgba(73, 98, 231, 0.71) 74.23%, #BCC8F6 100%), linear-gradient(94.59deg, #4760E7 0%, #BCC8F6 92.57%);

  & img {
    width: 30px;
    height: 30px;
  }

  & div {
    display: flex;
    align-items: center;

    @media (max-width: 660px) {
      flex-direction: column;
      align-items: flex-start;
      text-wrap: balance;
    } 
  }

  @media (max-width: 660px) {
    align-items: center;
    justify-content: flex-start;
    height: 3.5rem;
    width: 100%;
    margin-left: -1rem;
  } 
`

const ImgWrapper = styled.div`
  display: flex;
  position: relative;
  color: var(--color-white);
  font-size: 1.15rem;
  font-weight: 350;
  opacity: 0.9;

  & > svg{
    width: 1.9rem;
    height: 1.9rem;
    margin-left: 0;
  }
`

const ContentWrapper = styled.div`

`
const StyledPlus = styled.span`
  padding: 0.1rem 0.7rem;
  font-size: 1.15rem;
  background: linear-gradient(113.08deg, #6EC7F9 5.98%, #2D5BFF 105.58%);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
`

const Text = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 19px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  margin-left: 0.5rem;
  margin-right: 1rem;
  color: var(--color-white);
  opacity: 0.9;


  @media (min-width: 768px) {
    font-size: 16px;
  } 

  @media (max-width: 660px) {
   margin-top: 0.5rem;
  } 

`

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6.5px 4px 6.5px;
  gap: 10px;
  background: #FFFFFF;
  border-radius: 5px;

  & div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 12rem;
    height: 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 115%;

    & svg {
    position: absolute;
    left: 12.5rem;
    transform: rotate(0.5turn);
    width: 1rem;
    height: 1rem;
    margin-left: 0.3rem;
  }
    

    @media (max-width: 768px) {
      font-size: 0.8rem;
      width: 10rem;
    } 
    @media (max-width: 660px) {
     color: var(--color-white);
     margin-left: 0.5rem;
     margin-top: 0.75rem;
    } 
  }

  

  @media (max-width: 660px) {
      border: none;
      background: none;
      padding: 0;
    } 
    
  @media (max-width: 768px) {
    padding: 4px 1px 4px;
    margin-right: 2rem;
  } 
`

const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 26px;
  height: 26px;
  right: 1rem;
  top: 11px;

  background: rgba(255, 255, 255, 0.25);
  border-radius: 40px;

  & svg {
    position: relative;
    top: 2px;
    width: 1rem;
    height: 1rem;
  }

  @media (max-width: 768px) {
    right: 1.5rem;
  } 
`