import React from 'react';
import styled from 'styled-components';
import { Marker } from '.';
import Characteristics from './Characteristics';
import Predisposition from './Predisposition';

function Blocks(data: any) {
  if (data.data[0] === 'characteristics') {
    return <Characteristics {...data.data[1]}/>
  } else if (data.data[0] === 'predisposition') {
    return <Predisposition {...data.data[1]}/>
  } else {
    return (
      <>
      <Title>{data.data[1].title}</Title>
      <p>{data.data[1].description}</p>
      <ul>
        {
          data.data[1].items.map((a: { k: number, v: string }, i: number) => <li key={`${data[0]}${i}`}>
            <div className="block">
              <Marker className='marker'>+{a.v}</Marker>
              <div className="text">{a.k}</div>
            </div>
          </li>
          )
        }
      </ul>
    </>
    )
  }
}

  export default Blocks;

  const Title = styled.div`
  font-size: large;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
`