import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
//import { isMobile, isBrowser } from "react-device-detect";

import { Center, ColumnRight } from 'src/pages/prognosis/modules/item';

import { sendUrl, clear } from 'src/store/actions/import'
import { Dispatch } from 'redux';
import { Button, Input, InputContainer } from 'src/libs';


function Import({
    onSendUrl,
    onClear,
    result
}: {
    onSendUrl(url: string): void;
    onClear(): void;
    result: any;
}) {
    const [url, setUrl] = React.useState('');
    const [urlError, setUrlError] = React.useState<string>();

    React.useEffect(() => {
        if (!result.failed && result.id) {
            window.location.href = `/prognosis/${result.id}`;
        } else {
            document.title = `Импорт - Кабинет рассвета`;
        }
        const urlError = result.failed && (result.data.includes('WRONG_URL')) ? 'Неккоректный url' : undefined;
        setUrlError(urlError);

    }, [result]);

    const submitHandle = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onSendUrl(url);
    }

    const onChangeUrl = (val: string) => {
        setUrl(val);
        setUrlError(undefined);
    }

    return <>
        <Center>
            <Header>
                <h1>Импорт ИПР</h1>
                <span>Вы можете вставить любую ссылку на ИПР, купленный с помощью виджета,
                    и он будет импортирован в Ваш личный кабинет.</span>
            </Header>

            <Form onSubmit={submitHandle}>
                {/* <span>Вставьте ссылку на ИПР из виджета</span> */}
                <InputContainer label="Вставьте ссылку на ИПР из виджета" message={urlError}>
                    <Input
                        size='small'
                        value={url}
                        onChange={onChangeUrl}
                        mode={urlError ? 'error' : 'normal'}
                        autoFocus
                        clearable
                    />
                </InputContainer>
                <Navigation>
                    <Button type="submit" size='small'>Добавить</Button> Или нажмите Enter
                </Navigation>
            </Form>

        </Center>
        <ColumnRight>

        </ColumnRight>
    </>
}


const mapStateToProps = (state: any) => {
    return {
        result: state.import
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSendUrl: (url: string) => { dispatch(sendUrl(url)); },
        onClear: () => { dispatch(clear()); },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Import);


const Header = styled.header`
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 30rem;
    max-width: 100%;
    font-size: 0.875rem;
    color: var(--color-gray4);

    & h1 {
        margin: 0;
        padding: 0;
        font-weight: normal;
        font-size: 2rem;
        color: var(--color-black);
        margin-bottom: 1.125rem;
    }

    & span {
        line-height: 1.4rem;
    }
`

const Form = styled.form`
    font-size: 0.875rem;
    color: var(--color-gray);

    & span {
        display: block;
        margin-bottom: 0.75rem;

    }

    & .message:empty {
        display: none;
    }
`

const Navigation = styled.nav`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-size: 0.875rem;
    color: var(--color-gray);

    & button {
        margin-right: 1.125rem;
        color: var(--color-white);
    }
`