import { AnyAction } from 'redux';
import { PROMOCODE_CHECK_SUCCESS, PROMOCODE_RESET, PROMOCODE_USE_SUCCESS } from 'src/store/actions/promocode';

export default function promocodeReducer(state = [], action: AnyAction) {
    switch (action.type) {
        case PROMOCODE_CHECK_SUCCESS:
            return {
                check: { ...action.payload }
            };
        case PROMOCODE_USE_SUCCESS:
            return {
                use: { ...action.payload }
            };
        case PROMOCODE_RESET:
            return {};
        default:
            return state
    }
}