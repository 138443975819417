import React, { ReactNode } from 'react';
import { CartIcon, ShoppingBagIcon } from 'src/assets/img/icons';
import styled, { css } from 'styled-components';
import { IPackage, IProduct, ProductTypes } from '.';
import Label from '../ui/Label';
import { Price } from '../ui/Price';
import { CurrencyCode } from 'src/libs';


interface ListProps {
	className?: string
	header: string | ReactNode
	list: IProduct[]
	package?: IPackage | null
	currency: CurrencyCode
	isInCart(id: number): boolean
	isInCartGroup?(products: IProduct[]): boolean
	addToCart(value: IProduct): void
	addToCartGroup?(value: IProduct[]): void
	getStatusOver?(key: string): void
}

export const List = (props: ListProps) => {
	const getPackageTitle = () => {
		switch (props.package?.type) {
			case ProductTypes.IDP:
				return 'Полный план развития'
			case ProductTypes.AP:
				return 'Все прогнозы'
			default:
				return ''
		}
	}

	const packageDiscountPrice = () => {
		return props.package?.elements.reduce((sum, current) => {
			const discountPrice = Math.round(current.price * (1 - (current.partnerDiscount || 0)) * (1 - (props.package?.discount || 0)) + Number.EPSILON)
			return sum + discountPrice
		}, 0)
	}

	return (
		<Container className={props.className}>
			<header>
				{props.header}
			</header>

			<ul>
				{
					props.package && props.addToCartGroup && props.isInCartGroup && <AllIdpItem isBuyed={false}>
						<LeftWrapper>
							<ShoppingBagIcon />
							<span>
								{getPackageTitle()}
								<DiscountBadge>-{props.package.discount * 100}%</DiscountBadge>
							</span>
						</LeftWrapper>
						<aside>
							<Price price={props.package.price} newPrice={packageDiscountPrice()} currency={props.currency}/>
							<CartCircle inCart={props.isInCartGroup(props.list)}
													onClick={() => props.addToCartGroup!(props.package?.elements || [])}>
								<CartIcon />
							</CartCircle>
						</aside>
					</AllIdpItem>
				}``

				{
					props.list.map((item, index) => {
						const isNew = item.isNew || item.type === 'cidp'
						const isOver = props.getStatusOver && props.getStatusOver(item.key)

						return <Item key={index} isBuyed={isOver ? false : item.isBuyed}>
							<span>
								{item.name}
								{isNew && <Label>Новое</Label>}
							</span>

							{
								item.isBuyed && !isOver ? (
									  <aside>Куплено</aside>
								)	: (<aside>
										<Price price={item.price} discount={item.partnerDiscount} currency={props.currency}/>
										<CartCircle inCart={props.isInCart(item.id)}
											onClick={() => props.addToCart(item)}>
											<CartIcon />
										</CartCircle>
									</aside>
                    )}
						</Item>
					})
				}
			</ul>
		</Container>
	)
}

const Container = styled.section`
  margin-bottom: 1.75rem;

  & header {
    color: var(--color-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1.75rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    & aside {
        display: flex;
        align-items: center;
        color: var(--color-green);

        & del {
            margin-right: 0.5rem;
            color: var(--color-gray);
        }
    }
  }

  & ul {
	    margin: 0;
	    padding: 0;
	    list-style: none;
  }
`

const Item = styled.li<{ isBuyed: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.875rem;
    padding: 1rem 1.75rem;
    background: var(--color-white);
    color: var(--color-black);
    font-size: 1.5rem;
    border-radius: 0.625rem;

    @media (max-width: 768px) {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        padding: 0.75rem 1rem;
    }

    ${props => props.isBuyed && css`
        color: var(--color-gray);
        & > span {
            text-decoration: line-through;
        }
    `}

    & > span {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        & label {
            margin-left: 0.65rem;
        }
    }

    & aside {
        display: flex;
        align-items: center;
        color: var(--color-green);

        @media (max-width: 768px) {
            font-size:1rem;
        }
    }
`

const AllIdpItem = styled(Item)`
    position: relative;
    justify-content: space-between;

    background: linear-gradient(0deg, rgba(121, 188, 69, 0.1), rgba(121, 188, 69, 0.1)), #FFFFFF;
    border: 1px solid rgba(121, 188, 69, 0.2);
    border-radius: 10px;

    & svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    & span {
        margin-left: 1rem;
        display: flex;
        align-items: center;
    }
`

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const CartCircle = styled.i<{ inCart?: boolean }>`
  margin-left: 0.5rem;
  display: block;
  padding: 0.5rem;
  background: var(--color-green);
  border-radius: 50%;
  color: var(--color-white);
  cursor: pointer;


  @media (max-width: 768px) {
      padding: 0.25rem;
  }

  & svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width: 768px) {
        width: 1rem;
        height: 1rem;
    }
  }

  ${props => props.inCart && css`
		background: var(--color-gray3);

    & svg path {
        stroke: var(--color-gray);
    }

    cursor: default;
  `}
`

const DiscountBadge = styled.div`
    margin: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 12px;

    width: 60px;
    height: 24px;

    background: #F59300;
    border-radius: 127px;

    @media (max-width: 470px) {
        margin-right: 16px;
        padding: 2px 8px;
        font-size: 16px;
        margin-left: 0;
    }

    @media (max-width: 410px) {
        margin-right: 1rem;
        padding: 0px 1px;
        font-size: 10px;
        margin-left: 0;
    }

`
