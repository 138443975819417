import { AnyAction } from 'redux';
import { AP_HASH_GET } from '../actions/ap';

export default function formationsReducer(state = {}, action: AnyAction) {
  switch (action.type) {
    case AP_HASH_GET:
      return {...state, [action.payload.hash]: action.payload};
    default:
      return state;
  }
}