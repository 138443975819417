export const BACKGROUND_COLOR = '#FAFAFA';
export const BACKGROUND_COLOR_OPACITY = '#F1F1F100';

export const CANVAS_HEIGHT = 380;

export const TIMELINE_LINE_COLOR = 'rgba(0, 0, 0, 0.38)';
export const TIMELINE_LINE_Y = 44;
export const TIMELINE_LINES_COUNT = 12;
export const TIMELINE_LABEL_LINE_HEIGHT = 6;

export const TIMELINE_CIRCLE_RADIUS = 6;
export const TIMELINE_CIRCLE_COLOR = '#3DBDA6';

export const HEADER_HEIGHT = 56;

export const TREND_FIRST_Y = 64;
export const TREND_LINE_COLOR = 'rgba(0, 0, 0, 0.10)';
export const TREND_RIGHT_INFO_PADDING = 16;
export const TREND_RIGHT_INFO_CIRCLE_RADIUS = 10;