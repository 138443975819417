import React from 'react';
import {InfoIcon} from "../../assets/img/icons";
import {Block} from "./index";
import {makeDisclaimer, DisclaimerType, lifehack, listItems, signal, t, tenseAspects, psychotechnics} from "./utils";

type TenseAspectsType = {
	compensation: {
		img: string
		text: string
		title: string
	}
	negative: {
		img: string
		text: string
		title: string
	}
	positive: {
		img: string
		text: string
		title: string
	}
	prescription: {
		img: string,
		text: string,
		title: string
	}
}

type BirthType = {
	aux: string[]
	auxLabel: string
	baggages: string[]
	baggagesLabel: string
	label: string
	next: string[]
	partners: string[]
	warnings: string[]
	signal: string
	comment1: string
	comment2: string
	description1: string
	description2: string
	description3: string
	lifehack: string
	moon: string[]
	sun: string[]
	habits: string[]
	saturnProblemsLabel: string
	saturnProblems: string[]
	jupiterLabel: string
	jupiter: string[]
	tasksLabel: string
	tasks: string[]
	retributionLabel: string
	retribution: string[]
	moonTenseAspects: TenseAspectsType[]
	sunTenseAspects: TenseAspectsType[]
	psychotechnics: any
	problems: {
		items: string[]
		label: string
	}
}
type ContentType = {
	label: string
	text: string
}
type InstructionType = {
	items: string[]
	label: string
}

function Birth({
	birth,
	contents,
	disclaimer,
	instruction,
	resource,
	title,
}: {
	birth: BirthType[]
	contents: ContentType[]
	disclaimer: DisclaimerType
	instruction: InstructionType
	resource: string[]
	title: string
}) {
	return (
		<>
			{instruction.label}
			<br />
			<br />
			{instruction.items.map((i: any, id: number) => <p key={`instr_${id}`}>{i}</p>)}

			<ul>
				{
					contents
					.map((i: any, id: number) => <li key={`cont_${id}`}><b>{i.label}</b> <span>{i.text}</span></li>)
				}
			</ul>

			{resource.map((i: any, id: number) => <p key={`res_${id}`}>{i}</p>)}

			<div className='print-inseparable'>
				<h2>{birth[0].label}</h2>
	
				<p>{birth[0].auxLabel}</p>
			</div>

			<ul style={{ color: 'var(--color-green)' }}>{listItems(birth[0].aux)}</ul>

			<p>{birth[0].baggagesLabel}</p>

			<ul style={{ color: 'var(--color-green)' }}>{listItems(birth[0].baggages)}</ul>

			{birth[0].next.map((p: string, i: number) => <p key={i.toString()}>{p}</p>)}

			<ul style={{ color: 'var(--color-green)' }}>{listItems(birth[0].partners)}</ul>

			{birth[0].warnings.map((w: string, i: number) => {
				return <Block className='signal-item' key={`w_${i}`} mode={'signal'}>
					<InfoIcon/>
					<h3>Внимание</h3>
					<p>{w}</p>
				</Block>
			})}

			<div className='print-inseparable'>
				<h2>{birth[1].label}</h2>
	
				<p>{birth[1].description1}</p>
			</div>

			{birth[1].moon && birth[1].moon.length > 0 &&
                <>
                    <p>{birth[1].description2}</p>

                    <ul style={{ color: 'var(--color-green)' }}>{listItems(birth[1].moon)}</ul>

                    <p>{birth[1].comment1}</p>
                </>
			}

			{birth[1].moonTenseAspects && birth[1].moonTenseAspects.length > 0 &&
                <>
					{tenseAspects(birth[1].moonTenseAspects)}
					{psychotechnics(birth[1].psychotechnics)}
                    <p>{birth[1].comment2}</p>
                </>
			}

			{lifehack(birth[1].lifehack, t)}

			<p><b>{birth[1].problems.label}</b></p>

			<ul style={{ color: 'var(--color-green)' }}>{birth[1].problems.items.map((i: any, id: number) => <li key={`prob1_${id}`}>
				<span style={{color: 'var(--text-secondary)'}}>{i}</span></li>)}</ul>

			{birth[1].signal && signal(birth[1].signal)}

			<div className='print-inseparable'>
				<h2>{birth[2].label}</h2>
	
				<p>{birth[2].description1}</p>
			</div>

			{birth[2].sun.length > 0 &&
                <>
                    <p>{birth[2].description2}</p>

                    <ul style={{ color: 'var(--color-green)' }}>{listItems(birth[2].sun)}</ul>

                    <p>{birth[2].comment1}</p>
                </>
			}

			{birth[2].sunTenseAspects.length > 0 &&
                <>
					{tenseAspects(birth[2].sunTenseAspects)}
					{psychotechnics(birth[2].psychotechnics)}
                    <p>{birth[2].comment2}</p>
                </>
			}

			{lifehack(birth[2].lifehack, t)}

			<p><b>{birth[2].problems.label}</b></p>

			<ul>{birth[2].problems.items.map((i: any, id: number) => <li key={`prob2_${id}`}><span>{i}</span></li>)}</ul>

			{birth[2].signal && signal(birth[2].signal)}

			<div className='print-inseparable'>
				<h2>{birth[3].label}</h2>
	
				<p>{birth[3].description1}</p>
			</div>

			<p>{birth[3].description2}</p>

			<p>{birth[3].description3}</p>

			<ul className="gray-list">{listItems(birth[3].habits)}</ul>

			<p>{birth[3].saturnProblemsLabel}</p>

			<ul className="gray-list">{listItems(birth[3].saturnProblems)}</ul>

			<p>{birth[3].jupiterLabel}</p>

			<ul className="gray-list">{listItems(birth[3].jupiter)}</ul>

			<p><b>{birth[3].problems.label}</b></p>

			<ul>{birth[3].problems.items.map((i: any, id: number) => <li key={`prob3_${id}`}><span>{i}</span></li>)}</ul>

			{birth[3].signal && signal(birth[3].signal)}

			<div className='print-inseparable'>
				<h2>{birth[4].label}</h2>
	
				<p>{birth[4].description1}</p>
			</div>

			<p>{birth[4].description2}</p>

			<p>{birth[4].tasksLabel}</p>

			<ul className="gray-list">{listItems(birth[4].tasks)}</ul>

			<p>{birth[4].retributionLabel}</p>

			<ul className="gray-list">{listItems(birth[4].retribution)}</ul>

			{makeDisclaimer(disclaimer)}
		</>
	);
}

export default Birth;