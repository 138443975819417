import React from 'react';
import styled, { css } from 'styled-components';

export type TGender = 'male' | 'female' | null;

const options: {
  title: string;
  value: TGender
}[] = [{
  title: 'Мужской',
  value: 'male'
}, {
  title: 'Женский',
  value: 'female'
}]

export default function GenderSelector({
  value,
  setValue,
  compact = false
}: {
  value: TGender,
  setValue: (value: TGender) => void,
  compact?: boolean
}){
  return <Container compact={!!compact}>
    {options.map(item => <Radio key={item.value} compact={compact}>
      <input type="radio" 
        key={item.value}
        onChange={() => setValue(item.value)}
        checked={item.value === value}
        autoFocus={true}
      />
      <span />
      {item.title}
    </Radio>
    )}
  </Container>
}

const Container = styled.div<{compact: boolean}>`
  display: flex;
  align-items: center;
  gap: 4.5rem;
  margin-top: ${p => p.compact ? '0' : '1.5rem'};


  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
  }
`
const Radio = styled.label<{compact: boolean}>`
  display: flex !important;
  align-items: center;
  gap: 1.25rem;
  font-size: ${p => p.compact ? '1.5rem' : '2.25rem'} !important;
  line-height: ${p => p.compact ? '1.875rem' : '2.875rem'} !important;
  cursor: pointer;

  & input {
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${p => p.compact ? '1.5rem' : '2.875rem'};
    height: ${p => p.compact ? '1.5rem' : '2.875rem'};
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.38);
  }

  & :checked + span::after {
      content: '';
      width: ${p => p.compact ? '0.75rem' : '1.625rem'};
      height: ${p => p.compact ? '0.75rem' : '1.625rem'};
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.38);
  }
`