import { AnyAction } from 'redux';
import {CONFIG_GET_SUCCESS, CONFIG_REMOVE} from 'src/store/actions/purchases';

export default function purchasesReducer(state = { config: null }, action: AnyAction) {
    switch (action.type) {
        case CONFIG_GET_SUCCESS:
            return {
                ...state,
                config: action.payload
            }
        case CONFIG_REMOVE:
            return {
                ...state,
                config: null,
            }

        default:
            return state
    }
}