import React from 'react';

const LocalTrends = ({
  trends,
  heading,
  comment,
  compensationIntro,
}: {
  trends: any;
  heading: string;
  comment: string;
  compensationIntro: string;
}) => {
  return (
    <div className="trends">
      {/* <h1>{heading}</h1> */}
      <p>{comment}</p>

      <div className="local-trends">
        {trends?.map((t: any, i: number) => (
          <div id={`trend-${i+1}`} key={`trent_${i}`} className="local-trend">
            {t.trendName &&
              <h2>{t.trendName}</h2>
            }
            <ins className="actual">
              {t.actualTo}
            </ins>
            {t.reason &&
              <p className="ap-reasons">
                {t.reason}
              </p>
            }
            <ul>
              {t.items?.map((item: any, j: number) => (
                <li key={`trent_${i}_item_${j}`} className="list-item gray">
                  <div className="list-item-data">
                    <div className="block">
                      {typeof item === "string"
                        ? item
                        : <>
                          <div className="marker">{item.marker}</div>
                          <div className="text">{item.text}</div>
                        </>
                      }
                    </div>
                  </div>
                </li>
              ))
              }
            </ul>

            {t.affect && <p className="ap-affects">{t.affect}</p>}

            {t.compensation &&
              <div className="list-item-data compensation">
                <div className="block">
                  <div className="marker">
                    !
                  </div>
                  <div className="text">
                    <span className="intro">{compensationIntro}: </span>
                    {t.compensation}
                  </div>
                </div>
              </div>
            }
          </div>
        ))
        }
      </div>
    </div>
  );
}

export default LocalTrends;