import {fetchAPI_REST} from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

import { signOut } from 'src/store/actions/user';
import { IPromocodeAction, IPromocode } from 'src/libs';

export const PROMOCODE_CHECK_SUCCESS = 'PROMOCODE_CHECK_SUCCESS';
export const PROMOCODE_RESET = 'PROMOCODE_RESET';
export const PROMOCODE_USE_SUCCESS = 'PROMOCODE_USE_SUCCESS';

export const check = (formationId: number, promo: string) => {
    return (dispatch: Dispatch<any>) => {
        fetchAPI_REST(routes.CHECK_PROMOCODE, {
            promo,
            formationId
        })
            .then((data) => { dispatch(checkSuccess(data as IPromocode)) })
            .catch(error => {
                if (error.failed) {
                    dispatch(checkError(error));
                } else {
                    dispatch(signOut(error));
                }
            });
    };
}

export const use = (formationId: number, promo: string) => {
    return (dispatch: Dispatch<any>)  => {
        fetchAPI_REST(routes.USE_PROMOCODE, {
            promo,
            formationId,
            confirmed: true
        })
            .then(data => {
                dispatch(useSuccess(data as IPromocodeAction))
                if ((data as IPromocodeAction).app === 'dawn') {
                    window.location.reload()
                }
            })
            .catch(error => {
                if (error.failed) {
                    dispatch(checkError(error));
                } else {
                    dispatch(signOut(error));
                }
            });
    };
}

const checkSuccess = (data: IPromocode) => {
    return {
        type: PROMOCODE_CHECK_SUCCESS,
        payload: data as IPromocode
    }
}

const useSuccess = (data: IPromocodeAction) => {
    return {
        type: PROMOCODE_USE_SUCCESS,
        payload: data
    }
}

const checkError = (data: any) => {
    return {
        type: PROMOCODE_CHECK_SUCCESS,
        payload: data
    }
}

export const reset = () => {
    return {
        type: PROMOCODE_RESET
    }
}