import { AnyAction } from 'redux';
import {SIGN_IN_SUCCESS, SIGN_IN_FAILED, SIGN_OUT_SUCCESS, ONBOARDING_SUCCESS} from 'src/store/actions/user';


export default function userReducer(state = {}, action: AnyAction) {
    switch (action.type) {
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                failedLogin: null
            }
        case SIGN_IN_FAILED:
            return {
                failedLogin: action.payload[0]
            }
        case SIGN_OUT_SUCCESS:
            return {}
        case ONBOARDING_SUCCESS: {
            return {
                ...state,
                showDawnOnboarding: false,
            }
        }
        default:
            return state
    }
}