import { AnyAction } from 'redux';
import { IProduct } from 'src/components/Buy';
import { PRODUCTS_GET_SUCCESS } from 'src/store/actions/products';

export default function personsReducer(state = [], action: AnyAction) {
    switch (action.type) {
        case PRODUCTS_GET_SUCCESS:
            return action.payload.map((item: IProduct) => item.key === 'relationsMale' ? {...item, isNew: true} : item);
        default:
            return state
    }
}