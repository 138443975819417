import dayjs, { Dayjs } from "dayjs";
import { IFormation } from "src/components/PrognosisList/compact";

export const formatedDate = (isoString: string) => {
    return dayjs(isoString).utc().format('DD.MM.YYYY');
}

export const formatedTime = (isoString: string) => {
    return dayjs(isoString).utc().format('HH:mm:ss');
}

export const checkIsOpenRoute = () =>
    window.location.href.indexOf('/share/') !== -1 ||
    window.location.href.indexOf('/idp/') !== -1 ||
    window.location.href.indexOf('/ap/') !== -1 ||
    window.location.href.indexOf('/finance/') !== -1

export const appealWindowText = (userIdpList: Object, userApList: Object, idpList: Array<Object>, apList: Array<Object>) => {
    const userIdpListCount = Object.keys(userIdpList).length;
    const userApListCount = Object.keys(userApList).length;
    const purchasesCount = userIdpListCount + userApListCount;
    let appealTitle;
    let appealText;

    if (purchasesCount === 0) {
        appealTitle = 'У вас нет построений';
        appealText = 'Получите полную картину событий, рисков и возможностей в вашей жизни на ближайший год в персональном прогнозе или в индивидуальном плане развития';
    } else if (userIdpListCount < idpList.length && userApListCount === apList.length) {
        appealTitle = 'У вас куплены не все построения';
        appealText = 'Получите полную картину событий, рисков и возможностей в вашей жизни в индивидуальном плане развития';
    } else if (userIdpListCount === idpList.length && userApListCount < apList.length) {
        appealTitle = 'У вас куплены не все построения';
        appealText = 'Получите полную картину событий, рисков и возможностей в вашей жизни на ближайший год в персональном прогнозе';
    } else {
        appealTitle = 'У вас куплены не все построения';
        appealText = 'Доплатите совсем немного и посмотрите свой прогноз';
    }

    return { title: appealTitle, text: appealText }
}

export const trendsOverInfo = (user: IFormation, mode: "ap" | "idp" | 'cidp', key: string) => {
    const buyDate: string = user.data?.[mode]?.[key]?.created;
    const serviceLife: Dayjs = dayjs(buyDate).add(1, 'year');
    const isOver = dayjs().isAfter(serviceLife) && mode === 'ap';
    return {
        isOver,
        serviceLife
    }
}

export function searchParams() {
    return new URLSearchParams(window.location.search);
}

export function getURL() {
    return new URL(document.location.href)
}

export function numWord(value: number, words: string[]){  
	value = Math.abs(value) % 100; 
	const numDiv = value % 10;
	
    if(value > 10 && value < 20) return words[2]; 
	if(numDiv > 1 && numDiv < 5) return words[1];
	if(numDiv == 1) return words[0];

	return words[2];
}