import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getIDP } from 'src/store/actions/idp';
import { getProducts, getPrognosisPerson } from 'src/store/actions/share';
import { Content } from 'src/components/DevPlan';
import Birth from "../../components/DevPlan/Birth";
import Energy from "../../components/DevPlan/Energy";
import Proforientation from "../../components/DevPlan/Proforientation";
import Health from "../../components/DevPlan/Health";
import Finance from "../../components/DevPlan/Finance";
import Relations from "../../components/DevPlan/Relations";
import styled from 'styled-components';
import Banner from './Banner';
import FormDataInput, { IData } from 'src/components/DataInput/FormDataInput';
// import ConsultationsBanner from 'src/components/banner/ConsultationsBanner';
import NewConsultationsBanner from 'src/components/banner/NewConsultationsBanner';
import ClosePeopleBanner from 'src/components/banner/ClosePeopleBanner';
import { Loader } from 'src/libs';
import { BannerContainer, StyledHeader, StyledPrintedFormData, StyledPrintedHeader, StyledToPrintButton } from '../common';

function IDP({
  idp,
  onGetIDP,
  match
}: {
  idp: any,
  onGetIDP(hash: string): void,
  match: any
}) {
  const { hash } = match.params;

  useEffect(() => {
    onGetIDP(hash);
  }, [onGetIDP, hash]);

  if (!idp?.[hash]) {
    return <Loader />;
  }

  if (idp[hash].error === 'NO_DATA') {
    return (
      <FormDataInput hash={hash} prognoseType={'IDP'} updateData={(newData: any) => {
        onGetIDP(hash);
      }} />
    )
  }


  const idpData = idp[hash].data;

  return (
    <Content mode={'idp'}>
      <Banner block={idpData.block} partner={idpData.partner} />

      <StyledHeader>
        <span>{idpData.name}</span>
        <StyledToPrintButton elements={['#ap-print-header', '#ap-print-content']}>Скачать в pdf</StyledToPrintButton>
      </StyledHeader>

      <div style={{ opacity: 0.5 }}>{idpData.date}, {idpData.time}, {idpData.place}</div>

      <div id="ap-print-header">
        <StyledPrintedHeader label="План развития" />
        <StyledPrintedFormData>
          <span>{idpData.name}</span>,&nbsp;
          <span>{idpData.date}, {idpData.time}</span>,&nbsp;
          <span>{idpData.place}</span>
        </StyledPrintedFormData>
      </div>

      <div id="ap-print-content">
        <Content>
          {idpData.birth && <><h2>{idpData.birth.title}</h2><br /><Birth {...idpData.birth} /></>}

          {idpData.energy && <><h2>{idpData.energy.title}</h2><Energy {...idpData.energy} /></>}

          {idpData.proforientation && <><h2>{idpData.proforientation.title}</h2><br /><Proforientation {...idpData.proforientation} /></>}

          {idpData.health && <><h2>{idpData.health.title}</h2><Health {...idpData.health} /></>}

          {idpData.finance && <><h2>{idpData.finance.title}</h2><Finance {...idpData.finance} /></>}

          {idpData.relationsFemale && <><h2>{idpData.relationsFemale.title}</h2><Relations {...idpData.relationsFemale}/></>}

          {idpData.relationsMale && <><h2>{idpData.relationsMale.title}</h2><Relations {...idpData.relationsMale}/></>}
        </Content>
      </div>

      <BannerContainer>
        <NewConsultationsBanner
          className='chronos-ext'
          mode={'idp'}
          link={`${process.env.REACT_APP_BUNNER_LINK}/services/18?block=idp-relations`}
        />
        <ClosePeopleBanner className='chronos-ext' />
      </BannerContainer>
    </Content>
  );
};

const mapStateToProps = (state: any) => ({
  idp: state.idp,
  products: state.products,
  shareProducts: state.share.products,
  person: state.share.person,
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetIDP: (id: string) => dispatch(getIDP(id)),
  onGetProducts: () => dispatch(getProducts()),
  onGetPrognosis: (id: number) => dispatch(getPrognosisPerson(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IDP);

