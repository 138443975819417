import {authURL, fetchRest, fetchRestWithoutToken, mainApi} from 'src/helpers/api';
import { setUser, unsetUser, getUser } from 'src/helpers/localStorage';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';
import { searchParams } from 'src/helpers/utils';
import { history } from 'src/pages/app';


export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS";

export const signInSuccess = (data: any) => ({
    type: SIGN_IN_SUCCESS,
    payload: {
        ...data,
    },
});

export const signInFailed = (data: any) => ({
    type: SIGN_IN_FAILED,
    payload: {
        ...data,
    },
});

export const signOut = (error?: string) => {
    const lsUser = getUser();
    const sessionId = parseInt(lsUser?.sessionId ?? '-1'); // FIXME: ???
    console.log(error);
    return (dispatch: Dispatch) => {
        //return;
        fetch(`${authURL}/logout`, {
            method: "POST",
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ sessionId }),
        })
        //fetchRestWithoutToken(routes.LOGOUT, { sessionId })
            .then((response) => response.text())
            .then((response) => {
                unsetUser();
                dispatch(signOutSuccess());
            });
    };
};

const signOutSuccess = () => {
    return {
        type: "REDIRECT",
        payload: {
            method: "push",
            url: `/`,
        },
    };
};

export const setOnboardingStatusSuccess = () => ({
    type: ONBOARDING_SUCCESS,
});

export const setOnboardingStatus = (data: any) => {
    return (dispatch: Dispatch) => {
        fetchRest(routes.ONBORDING, [data])
            .then(res => res.json())
            .then(data => {
                if (data.failed) {
                    console.log('Error', data.data[0])
                } else {
                    const stateLS = getUser()
                    stateLS.showDawnOnboarding = false
                    setUser(stateLS)

                    dispatch(setOnboardingStatusSuccess())
                }
            })
    }
}
