import { postAP } from "src/store/actions/ap";
import { postIDP } from "src/store/actions/idp";
import { TGender } from "../ui/GenderSelector";
import { PROGNOSETYPE, WIDGET_TYPE } from "./FormDataInput";

// FIXME: duplicate
export interface IData {
  place: {
    name: string;
    lat: number;
    lon: number;
  } | null;
  name: string;
  date: string | null;
  time: string | null;
  gender?: TGender;
}

export interface IPlan {
  id: string;
  title: string;
  price: number;
  priceOld: number;
  popular: boolean;
  type?: WIDGET_TYPE;
}

export default async function submit(
  hash: string,
  formData: IData,
  prognoseType: PROGNOSETYPE,
  updateData: (newData: any) => void
) {
  const dataForSend = { ...formData, place: { name: formData.place!.name, lat: formData.place!.lat, lon: formData.place!.lon } }
  if (prognoseType === 'IDP') {
    await postIDP(hash, dataForSend).then(res => updateData(res))
  } else if (prognoseType === 'AP') {
    await postAP(hash, dataForSend).then((res: any) => updateData(res))
  }
}
