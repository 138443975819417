import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { InfoIcon, MinusIcon, PlusIcon } from 'src/assets/img/icons';

import Loading from 'src/components/Loading';

import { get } from 'src/store/actions/horoscopeDaily';
import { getDayName } from 'src/helpers/dates'

import IndiPrognosisBanner from 'src/components/banner/IndiPrognosisBanner';
import IndiBunner from '../banner/IndiBunners/IndiBunner';

const locales = {
    'potential.day': 'Потенциал дня',
    'horoscope.empty': 'Нет гороскопа на сегодня.',
    'try.tomorrow': 'Попробуйте зайти завтра',
}

function HoroscopeDaily({
    horoscopeDaily,
    onGet
}: {
    horoscopeDaily: any;
    onGet(date: string): void;
}) {

    const nowDate = dayjs();

    React.useEffect(() => {
        onGet(dayjs().format('DD.MM.YYYY'));
    }, [onGet])
    
    if (horoscopeDaily && !horoscopeDaily.id) return <Loading />

    const ratio = horoscopeDaily?.ratio ?? null;

    let todo: string[] = [];
    let nottodo: string[] = [];
    let risks: string[] = [];

    if (horoscopeDaily?.todo) todo = horoscopeDaily?.todo.split('; ');
    if (horoscopeDaily?.nottodo) nottodo = horoscopeDaily?.nottodo.split('; ');
    if (horoscopeDaily?.risks) risks = horoscopeDaily?.risks.split('; ');

    const calculateSkew = (sectorIndex: number) => {
        if (ratio >= (sectorIndex + 1) * 25) return 0;
        else return (90 + ((ratio - sectorIndex * 25) / 25) * 90);

    }

    return <>
        <Header>
            <span>{nowDate.date()}</span> {getDayName(nowDate.day())}
        </Header>
        <Group>
            <header>{horoscopeDaily?.id ? <>{locales['potential.day']}</> : <>{locales['horoscope.empty']} <br /><br />{locales['try.tomorrow']}</>}</header>
            {ratio !== null && ratio >= 0 && <ProcentBlock>
                <span>
                    {ratio >= 0 && <Sector rotate={0} skew={calculateSkew(0)}></Sector>}
                    {ratio > 25 && <Sector rotate={90} skew={calculateSkew(1)}></Sector>}
                    {ratio > 50 && <Sector rotate={180} skew={calculateSkew(2)}></Sector>}
                    {ratio > 70 && <Sector rotate={270} skew={calculateSkew(3)}></Sector>}
                </span> {ratio}%
            </ProcentBlock>}
        </Group>
        <StyledIndiPrognosisBanner 
            link='https://feed.chronos.ru/indi_prognosis_free?utm_source=dawn'
            startShow={dayjs('2023-01-09 00:01')}
            endShow={dayjs('2023-01-09 23:59')}
        />
        <Group>
            {(todo.length > 0 || nottodo.length > 0 || risks.length > 0) &&
                <header>Ежедневный прогноз</header>
            }
            {todo.length > 0 && <PrognosisGroup color="var(--color-green)">
                <header><PlusIcon /> Что делать</header>
                <ul>
                    {todo.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
            </PrognosisGroup>}
            {true && <IndiBunner link='https://indi.chronos.mg/trial?d=14&utm_source=dawn_banner' />}
            {nottodo.length > 0 && <PrognosisGroup color="var(--color-red)">
                <header><MinusIcon /> Что не делать</header>
                <ul>
                    {nottodo.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
            </PrognosisGroup>}
            {risks.length > 0 && <PrognosisGroup color="var(--color-orange)">
                <header><InfoIcon /> Потенциал общих рисков</header>
                <ul>
                    {risks.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
            </PrognosisGroup>}
        </Group>
    </>;
}

const mapStateToProps = (state: any) => {
    return { horoscopeDaily: state.horoscopeDaily };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onGet: (date: string) => {
            dispatch(get(date));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HoroscopeDaily);

const Header = styled.header`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: var(--color-black);
    margin-bottom: 1.25rem;

    & span {
        display: flex;
        justify-content:center;
        align-items:center;
        width: 2rem;
        height: 2rem;
        background: var(--color-sea);
        color: var(--color-white);
        font-size: 0.875rem;
        margin-right: 0.875rem;
        border-radius: 50%;
    }
`

const Group = styled.section`
    margin-bottom: 1.5rem;
    font-size: 1rem;

    & > header {
        margin-bottom: 1rem;
        color: var(--color-gray);
    }
`

const ProcentBlock = styled.section`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: var(--color-green);

    & span {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        box-sizing: border-box;
        /*border: 0.2rem solid var(--color-green);*/
        background: var(--color-gray3);
        border-radius: 50%;
        margin-right: 0.5rem;
        overflow: hidden;

        &:after{
            content: '';
            position: absolute;
            width: 70%;
            height: 70%;
            background: var(--color-bg);
            border-radius: 50%;
            top: 15%;
            left: 15%;
        }
    }
`

const Sector = styled.i<{rotate: number, skew: number}>`
    width: 50%;
    height: 50%;
    position: absolute;
    left: 50%;
    top: 0;
    transform-origin: left bottom;
    background: var(--color-green);
    transform: rotate(${props => props.rotate}deg) skewY(${props => props.skew}deg);

`

const PrognosisGroup = styled.section`
    margin-bottom: 1rem;

    & header {
        color: var(--color-black2);
        display: flex;
        align-items: center;
        margin-bottom: 1.15rem;

        & svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.65rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0.875rem;
        color: var(--color-gray4);

        & li {
            margin-bottom: 1.15rem;
            padding-left: 2rem;
            position: relative;
            line-height: 1.5;

            &:before{
                content: '';
                display: block;
                position: absolute;
                left: 0.45rem;
                top: 0.45rem;
                width: 0.375rem;
                height: 0.375rem;
                background: ${props => props.color};
                border-radius: 50%;
            }
        }
    }
`
const StyledIndiPrognosisBanner = styled(IndiPrognosisBanner)`
    margin-bottom: 1.5rem;
`;