import { PrintedFormData, PrintedHeader, ToPrintButton } from "src/libs";
import styled from "styled-components";

export const BannerContainer = styled.div`
  margin-top: 3rem;
`;

export const StyledHeader = styled.h1`
  display: flex;
  justify-content: space-between;
`;

export const StyledPrintedHeader = styled(PrintedHeader)`
  margin-left: 0;
  margin-right: 0;
`;

export const StyledPrintedFormData = styled(PrintedFormData)`
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
`;

export const StyledToPrintButton = styled(ToPrintButton)`
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: var(--ui-accent-color);
  background: transparent;
  font-weight: 600;
`;