import { fetchAPI_REST } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

//import { signOut } from 'src/store/actions/user';

export const IMPORT_SEND_URL_SUCCESS = 'IMPORT_SEND_SUCCESS';
export const IMPORT_SEND_URL_FAILED = 'IMPORT_SEND_FAILED';
export const IMPORT_CLEAR = 'IMPORT_CLEAR';

export const sendUrl = (url: string) => {
    return (dispatch: Dispatch) => {
        fetchAPI_REST(routes.IMPORT_IDP, {
            url: url
        })
            .then(data => { dispatch(sendUrlSuccces(data)) })
            .catch(data => { dispatch(sendUrlFailed(data)) }); //dispatch(signOut()); 
    };
}

const sendUrlSuccces = (data: any) => {
    return {
        type: IMPORT_SEND_URL_SUCCESS,
        payload: data
    }
}

const sendUrlFailed = (data: any) => {
    return {
        type: IMPORT_SEND_URL_FAILED,
        payload: data
    }
}

export const clear = () => {
    return {
        type: IMPORT_CLEAR
    }
}