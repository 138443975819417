import React from 'react';
import styled from 'styled-components';

export default function PersonAvatar({
    color = 'var(--color-sea)',
    size = '1.5rem',
    name = '?',
    image
}:{
    color?: string,
    size?: string,
    name?: string,
    image?: string,
}) {
    if (image) {
        return <Icon color={color} size={size} image={image}></Icon>
    } else {
        const letter = name.substr(0, 1).toUpperCase();
        return <Icon color={color} size={size}>{letter}</Icon>
    }
}

const Icon = styled.span<{size: string, image?: string}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.size};
    height: ${props => props.size};
    background: ${props => props.image ? `url(${props.image})` : props.color};
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 0.75rem;

    
    @media (max-width: 768px) {
        width: ${props => parseInt(props.size) * 0.75}rem;
        height: ${props => parseInt(props.size) * 0.75}rem;
    }
`