import React from 'react';
import {makeDisclaimer, DisclaimerType, group, t} from "./utils";

function Health({
    warning,
    warning2,
    warning3,
    group1,
    group1Label,
    group1Aux,
    group2,
    group2Label,
    probabilities,
    title,
    disclaimer,
}: {
	warning: string
	warning2: string
	warning3: string
	group1: string[]
	group1Label: string[]
	group1Aux: string[]
	group2: string[]
	group2Label: string[]
	probabilities: string[]
	title: string
	disclaimer: DisclaimerType
}) {
	return (
		<>
			<p>{warning}</p>

			<p>{warning2}</p>

			{group1.length > 0 && <>
                <h2>{group1Label}</h2>

                <div style={{
					color: 'red',
					display: 'flex',
					flexDirection: 'column'
				}}>
					{group1Aux.map((a: string, i: number) => <div key={`aux_${i}`}>{a}</div>)}
                </div>

				{group(group1)}
            </>}

			{group2.length > 0 && <>
                <h2>{group2Label}</h2>
				{group(group2)}
            </>}

			{group1.length > 0 && group2.length > 0 && <p>{warning3}</p>}
			{group1.length === 0 && group2.length === 0 ?
				<p>{t("chronos.app.devPlan.noRiskMessage")}</p> :
				<div>
					{probabilities.map((p: string, i: number) => <p
						key={`prob_${i}`}
						style={{
							fontWeight: 'bold',
							color: '#D00000'
						}}
					>{p}</p>)}
				</div>
			}
			{makeDisclaimer(disclaimer)}
		</>
	)
}

export default Health;