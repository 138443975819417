import React from "react";
import { BackIcon, ExternalLinkIcon } from "src/assets/img/icons";
import Phone1 from 'src/assets/img/banner/Phone.png';
import Phone2 from 'src/assets/img/banner/Phone-1.png';
import styled, { css } from "styled-components";
import { getUser } from 'src/helpers/localStorage';
import { numWord } from "src/helpers/utils";

export default function IndiBunner({
  link, 
  fromModule,
  isMobile,
}: {
  link: string;
  fromModule?: boolean;
  isMobile?: boolean;
}) {

  // const trialDays = 14;

  const trialDays = Number(link.match(/\?d=(\d*)/)?.[1]) || 3;

  const user = getUser(); 
  const clickHandler = () => {
    // @ts-ignore
    window.dataLayer.push({'event': 'dawn_banner_plan_for_children_click', 'user_id': user?.id});
    window.open(link);
  }
  // 
  return (
    <Container fromModule={fromModule} isMobile={isMobile} onClick={clickHandler}>
      <ContentWrapper>
        <TextWrapper>
          <Header>
            <div>Chronos</div><StyledPlus>Plus</StyledPlus>
          </Header>
          {fromModule && !isMobile ? 
          <>
            <h2>Персональные ежедневные прогнозы</h2>
            <Text fromModule={fromModule}>
              Узнайте в приложении, как небесные события влияют<br />
              именно на вас! Спланируйте дела на месяцы вперёд<br />
              и избавьтесь от неопределенности
            </Text>
          </> :
          <Text>
            Персональный ежедневный <br />прогноз по 7 сферам жизни
          </Text>
          }
        </TextWrapper>
        <Imgs>
          <Img1  fromModule={fromModule} isMobile={isMobile} ><img src={Phone1} /></Img1>
          <Img2  fromModule={fromModule} isMobile={isMobile} ><img src={Phone2} /></Img2>
        </Imgs>
      </ContentWrapper>

      <StyledButton fromModule={fromModule} isMobile={isMobile}><div>Попробовать {trialDays} {numWord(trialDays, ['день', 'дня', 'дней'])}</div>{fromModule && !isMobile ? <ExternalLinkIcon /> : <BackIcon />}</StyledButton>
    </Container>
  )
}

const Container = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.9rem 1rem;
  gap: 0.75rem;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: var(--color-white);

  width: 90%;
  height: 6rem;
  ${props => props.fromModule && !props.isMobile && css`
  margin-top: 2rem;
    padding: 1.5rem;
    justify-content: space-between;
    height: 14rem;
  `}

  h2 {
    position: relative;
    font-size: 1.5rem;
    font-weight: 500;
    z-index: 2;
  }

  background: radial-gradient(220.97% 2360.96% at -102.33% -98.97%, #000A40 0%, rgba(73, 98, 231, 0.71) 74.23%, #BCC8F6 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(158.54% 1374.04% at -23.29% -28.8%, #4760E7 0%, rgba(73, 98, 231, 0.71) 63.5%, #BCC8F6 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  z-index: 1;

    @media (min-width: 1900px) {
      width: 80%;
    } 

    @media (max-width: 1800px) {
      width: 90%;
    } 

    @media (max-width: 768px) {
      width: 90%;
    } 

  img {
    width: 4rem;
    height: 4rem;
  }
`

const Img1 = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  position: relative;

  & > img {
      position: relative;
      width: 5rem;
      height: 8.5rem;
      top: 1.5rem;
    }

    ${props => props.fromModule  && !props.isMobile && css`
      & > img {
        width: 5.625rem;
        height: 14.8125rem;
        top: 4rem;      
      }
    `}
`
const Img2 = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  position: relative;
  right: 0.8rem;
  & > img {
      width: 5rem;
      height: 8.5rem;
      bottom: 0rem;      
    }

  ${props => props.fromModule  && !props.isMobile && css`
  & > img {
    position: relative;
    width: 6.5625rem;
    height: 16.5rem;
    bottom: 0rem;      
  }
`}
`

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 350;
  margin-bottom: 0.4rem;
  z-index: 2;
`

const StyledPlus = styled.span`
  padding: 0.1rem 0.7rem;
  font-size: 1rem;
  background: linear-gradient(113.08deg, #6EC7F9 5.98%, #2D5BFF 105.58%);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
`

const Text = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  position: relative;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  z-index: 2;

  ${props => props.fromModule  && !props.isMobile && css`
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.87);
  `}
`

const StyledButton = styled.div<{fromModule?: boolean, isMobile?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem 0.4rem;
  gap: 0.75rem;
  width: fit-content;
  height: 1rem;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 2.5rem;
  z-index: 2;

  div {
    height: 1rem;
    font-weight: 500;
    font-size: 0.875;
    line-height: 115%;
  }



  svg {
    transform: rotate(0.5turn);
    width: 1.5rem;
    height: 1.5rem;
  }

  ${props => props.fromModule  && !props.isMobile && css`
    background: var(--color-white);
    & > div {
      color: var(--color-black);
    }
    svg {
      transform: rotate(90deg) scale(-1, 1);
    }
  `}
`

const Imgs = styled.div`
  position: absolute;
  width: fit-content;
  display: flex;
  justify-content: center;
  z-index: 1;
  right: -3rem;
`
