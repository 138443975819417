import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Data } from './types'
import BannerImg from './assets/img/banner.jpg'
import { DownloadIcon, StarIcon } from './assets/icons/system'
import { getFinance } from './api'
import { Button } from 'src/libs'


export default function Finance() {
  const [data, setData] = useState<Data | null>(null)

  const place = data && data.place.split(',')[0]

  useEffect(() => {
    const hash = window.location.pathname.split('/')[2]

    getFinance(hash)
    .then(res => setData(res))
  }, [])

  if (!data) return <></>

  const { howMoney, whereMoney } = data.earnings

  const aboutCourse = {
    title: 'Хотите узнать о себе больше?',
    description: 'На курсе «Делать деньги бл*ть вот так» вы получите расширенную версию диагностики личности, которая покажет:',
    list: [
      'Персональную стратегию реализации в социуме, которая принесет вам финансовую удачу',
      'Ваши деньгогенераторы – действия, которые позволят вам зарабатывать больше всегда и везде',
      'Ваши финансовые риски — чего нужно избегать в жизни, чтобы не потерять то, что вы уже заработали',
      'В чем ваша уникальность и особые таланты, а также способы их активации',
      'Рекомендуемые направления профессиональной реализации',
      'Качества вашей личности, которые принесут вам максимум реализации в материальном мире',
      'Ваше главное препятствие на пути реализации + рекомендации, как его обойти',
      'Как вам принимать надежные решения на своём пути развития'
    ]
  }

  const goToSite = () => {
    const url = 'https://terenteva8.com/money '
    window.open(url);
  }

  const print = () => {
    window.print()
  }

  return (
    <Container>
      <Banner src={BannerImg} alt="banner" />
      <Content>
        <Title>Как вам необходимо зарабатывать 💰</Title>
        <Subtitle>
          <div>{data.date} {data.time} {place}</div>
          <div className='download no-print' onClick={print}><DownloadIcon/>Скачать PDF</div>
        </Subtitle>

        <HowMoney>
          <div className='titleWords'>{howMoney.titleWords} — <span>«{howMoney.mainWords}»</span></div>
          <div className='description'>
            {
              howMoney.description.map((item, index) => {
                return (<div key={`howMoney_description_${index}`}>{item}</div>)
              })
            }
          </div>

          <div className='titleNiches'>{howMoney.titleNiches}</div>
          <ul className='niches'>
            {
              howMoney.niches.map((item, index) => {
                return (<li key={`howMoney_niches_${index}`}><StarIcon/>{item}</li>)
              })
            }
          </ul>
          <div className='postWords'>{howMoney.postWords}</div>
        </HowMoney>

        {
          whereMoney.map((item, index) => {
            return (
              <WhereMoney key={`whereMoney_${index}`}>
                <div className='title'><span>{whereMoney.length > 1 ? `${index + 1}.` : ''}</span> Откуда берутся деньги</div>
                {
                  item.description.map((item, index) => {
                    return(<div key={`whereMoney_description_${index}`} className='item-description'>{item}</div>)
                  })
                }
                <div className='titleAreas'>{item.titleAreas}</div>
                <ul className='areas'>
                  {
                    item.areas.map((item, index) => {
                      return (<li key={`whereMoney_areas_${index}`}><StarIcon/>{item}</li>)
                    })
                  }
                </ul>
              </WhereMoney>
            )
          })
        }

        {/* <Delimiter><span></span>💰<span></span></Delimiter>

        <About>
          <div className='title'>{aboutCourse.title}</div>
          <div className='description'>{aboutCourse.description}</div>
          <ul>
            {
              aboutCourse.list.map((item, index) => {
                return (<li key={`aboutCourse_${index}`}><StarIcon/>{item}</li>)
              })
            }
          </ul>
        </About> */}
      </Content>

      {/* <Footer className='no-print'>
        <StyledButton onClick={goToSite}>Перейти на сайт курса</StyledButton>
      </Footer> */}
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.25rem;
  color: var(--text-secondary);

  img {
    width: 100%;
  }

  ul {
    padding: 0;
    margin-bottom: 1rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.25rem;
    }

    li {
      display: flex;

      list-style-type: none;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 959px) {
    width: 90%;
  }
`

const Banner = styled.img`
  margin-bottom: 2rem;

  @media (max-width: 959px) {
    margin-bottom: 1rem;
  }
`

const Content = styled.div`
  text-align: left;
  margin-bottom: 3rem;

  @media print {
    .no-print {
      display: none !important;
    }
  }
`

const Title = styled.div`
  color: var(--text-primary);
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1rem;
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;

  .download {
    display: flex;
    align-items: center;

    margin-left: 1rem;
    cursor: pointer;
    white-space: nowrap;

    svg {
      margin-right: 0.25rem;
    }
  }

  @media (max-width: 959px) {
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`

const HowMoney = styled.div`
  margin-bottom: 2rem;

  .titleWords {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;

    span {
      font-weight: 700;
    }
  }

  .description {
    div {
      margin-bottom: 1rem;
    }
  }

  .titleNiches {
    font-weight: 500;
  }
`

const WhereMoney = styled.div`
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .item-description {
    margin-bottom: 1rem;
  }

  .titleAreas {
    font-weight: 500;
  }
`

const Delimiter = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin: 2rem 0;

  span {
    width: 100%;
    height: 1px;
    background: #4093F4;
  }
`

const About = styled.div`
  .title {
    color: var(--text-primary);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .description {
    font-size: 1.5rem;
    font-weight: 700;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-bottom: 4rem;

  @media (max-width: 959px) {
    padding-bottom: 2rem;
  }
`

const StyledButton = styled(Button)`
  color: white !important;
  background: #4093F4 !important;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 959px) {
    width: 100%;
  }
`
