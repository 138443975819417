import React from 'react';
import { BackIcon } from 'src/assets/img/icons';
import styled from 'styled-components';
import { getUser } from 'src/helpers/localStorage';

export default function SynastrySmall(){
  const onClick = () => {
    const user = getUser();
    const id = user?.id;
    if(id) {
      //@ts-ignore
      window.dataLayer.push({'event': 'dawn_click-synastry', 'user_id': id})
      setInterval(() => {
        window.location.href = `${process.env.REACT_APP_INDIVIDUALS_APP_URL}/products/synastry`;
      }, 750);
      
    }
  }
  return <Container onClick={onClick}>
    <Circle>🧡</Circle>
    <Text>Получите детальный разбор вашей совместимости</Text>
    <Button>Попробовать</Button>
    <CustomForwardIcon />
  </Container>
}

const Button = styled.div`
  padding: 0.375rem 0.45rem;

  font-size: 0.675rem;
  text-transform: uppercase;
  color: var(--color-white);
  border-radius: 4px;
  background: var(--color-sea);

  @media (max-width: 768px){
    display: none;
  }
`

const Container = styled.div`
  display: block;
  margin-bottom: 0.75rem;
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  color: inherit;
  text-decoration: none;
  background: var(--color-white);
  border-radius: 10px;
  cursor: pointer;

  :hover ${Button} {
    opacity: 0.75;
  }
`

const Circle = styled.div`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 1.125rem;
  background: rgba(212, 18, 4, 0.13);
  border-radius: 50%; 
`

const Text = styled.div`
  font-size: 1rem;
  color: var(--text-primary);
`

const CustomForwardIcon = styled(BackIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  transform: scaleX(-1);
`