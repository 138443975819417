const apiUrl = `${process.env.REACT_APP_STATIC_URL}/api`;

export async function getFinance(hash: string, data?: any) {
  try {
    const url = `/misc/idp/${hash}`

    const result = await fetch(`${apiUrl}${url}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then((response) => response.json());

    return result.data
  } catch(err) {
      console.error(`Error in getFinance: ${err}`)
  }
}
