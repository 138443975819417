import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoaderIcon } from 'src/assets/img/animated/loader.svg';

export default function Loading() {

    return <Container>
        <LoaderIcon />
    </Container>

}


const Container = styled.div`
    & svg {
        width: 5rem;
    }
`