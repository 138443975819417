import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOR, CANVAS_HEIGHT } from './constants';
import { getPixelRatio, render } from './helpers';

// import mockData from './mock.json';
import { initEvents } from './events';
import { TTrend } from './trends';


export default function TrendsChart({
  data
}: {
  data: TTrend[]
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // data = mockData.data;

  useEffect(() => {
    if(canvasRef.current) {
      const canvas = canvasRef.current;

      const context = canvas.getContext('2d');
      if(context) {
        const CANVAS_WIDTH = canvas.getBoundingClientRect().width;
        const PIXEL_RATIO = getPixelRatio(context);

        canvas.width = CANVAS_WIDTH * PIXEL_RATIO;
        canvas.height = CANVAS_HEIGHT * PIXEL_RATIO;
        canvas.style.width = `${CANVAS_WIDTH}px`;
        canvas.style.height = `${CANVAS_HEIGHT}px`;

        context.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);

        render(context, { data, activeIndex: -1 });

        initEvents(context.canvas, data);

      }
    }
  }, [canvasRef])
  return <>
    <Canvas ref={canvasRef}></Canvas>
  </>
}

const Canvas = styled.canvas`
  width: 100%;
  height: ${CANVAS_HEIGHT}px;
  margin-bottom: 1rem;

  background-color: ${BACKGROUND_COLOR};
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 8px;
`