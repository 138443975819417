import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { LogoIcon, MenuIcon } from 'src/assets/img/icons';

// import AppSwitcher from 'src/components/AppSwitcher';

import { signOut } from 'src/store/actions/user';

import { NavLink } from "react-router-dom";

import { getUser } from 'src/helpers/localStorage';
import { checkIsOpenRoute } from 'src/helpers/utils';

import { s3urls } from 'src/helpers/api';

import { get as getFormation } from 'src/store/actions/products';
import IndiTopLine from 'src/components/banner/IndiBunners/IndiTopLine';
import { AppSwitcher, Button, Region } from 'src/libs';

const levelName = {
    '0': 'Начинающий',
    '1': 'Любитель',
    '2': 'Профессионал'
}

function Header({
    onSignOut,
    onGetFormation,
    match
}: {
    onSignOut(): void;
    onGetFormation(id: number, country: Region): void;
    match: any
}) {
    const [appSwitcherShow, setAppSwitcherShow] = React.useState(false);
    const [showCidpBanner, setShowCidpBanner] = React.useState(false)

    const user = getUser();

    const currentUrl = window.location.href;
    const dashboardClass = currentUrl.indexOf('import') === -1 ? 'active' : '';
    const importClass = currentUrl.indexOf('import') === -1 ? '' : 'active';

    const isOpenRoute = checkIsOpenRoute();

    const userId = +match.params.userId | user?.id

    const prognosisPath = `/prognosis/${userId}/`

    const goToMain = () => {
        window.location.href = `${process.env.REACT_APP_PROGNOSIS_APP_URL}`
    }

    useEffect(() => {
        if (+match.params.userId) onGetFormation(+match.params.userId, Region.ru);
    }, [onGetFormation, +match.params.userId]);

    useEffect(() => {
        setShowCidpBanner(!match.path.includes('share'))
    }, [])

    return <>
        {showCidpBanner && <IndiTopLine link='https://indi.chronos.mg/trial?d=14&utm_source=dawn_topline' onClose={() => { setShowCidpBanner(false) }} />}
        <HeaderContainer>

            <ColumnLeft>
                {!isOpenRoute && <LogoMenu onClick={() => setAppSwitcherShow(!appSwitcherShow)}>
                    <MenuIcon />
                </LogoMenu>}

                <LogoContainer onClick={goToMain}>
                    <Logo>
                        <LogoIcon />
                    </Logo>
                    <span>Рассвет</span>
                </LogoContainer>

                {appSwitcherShow && <AppSwitcher
                    user={{
                        firstName: user.name,
                        avatarUrl: user.avatarUrl,
                        levelOfAstrology: levelName[user.levelOfAstrology as ('0' | '1' | '2')]
                    }}
                    current={process.env.REACT_APP_PROGNOSIS_APP_URL || ''}
                    style={{ top: '49px', zIndex: 70 }}
                    onLogOut={() => { onSignOut(); }}
                    onClose={() => { setAppSwitcherShow(false) }}
                />}

            </ColumnLeft>

            <Center>
                <Links>
                    {!isOpenRoute && <li className={dashboardClass}><NavLink to={prognosisPath} >План развития</NavLink></li>}
                    <li onClick={() => { }}>
                        <a href={s3urls.support} target="_blank" rel="noopener noreferrer">Поддержка</a>
                    </li>
                    {user?.id && <li className={importClass}><NavLink to="/prognosis/import" >Импорт ИПР</NavLink></li>}
                </Links>
            </Center>

            <ColumnRight>
                {user?.id && <Button size='small' color="transparentWithBorder" onClick={onSignOut}>Выйти</Button>}
                {/* {!user?.id && <ButtonContainer simple><Button onClick={() => { window.location.href = '/'; }} fontSize="0.875rem">Войти</Button></ButtonContainer>} */}
            </ColumnRight>

        </HeaderContainer>
    </>
}

const mapStateToProps = (state: any) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSignOut: () => {
            dispatch(signOut());
        },
        onGetFormation: (id: number, country: Region) => { dispatch(getFormation(id, country)); },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2.8rem;
    padding-top: 1rem;

    @media (max-width: 768px) {
        padding-top: 0rem;
        margin-bottom: 0rem;
        justify-content: space-between;
    }
`

const Center = styled.div`
    width: var(--main-layout-center-width);

    @media (max-width: 768px) {
        flex: 1;
    }
`

const Links = styled.ul`
    list-style: none;
    padding: 0;
    text-align: center;
    color: var(--color-gray);
    font-size: 0.875rem;

    & li {
        display: inline-block;
        margin: 0 1.5rem;
        cursor: pointer;

        & a {
            color: inherit;
            text-decoration: inherit;
        }

        &.active{
            color: var(--color-black2);
        }

        @media (max-width: 480px) {
            margin: 1rem 0rem;

            &.active {
                display: none;
            }
        }
    }

    @media (max-width: 480px) {
        display: flex;
        justify-content: space-around;
        font-size: 0.75rem;
        margin-left: 0.25rem;
    }
`

const ColumnLeft = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: var(--main-layout-sidebar-left-width);

    @media (max-width: 768px) {
        width: min-content;

        & span {
            display: none
        }
    }
`

const ColumnRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: var(--main-layout-sidebar-right-width);

    @media (max-width: 768px) {
        width: min-content;
    }

    & > button {
        font-size: 0.75rem;
    }
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const Logo = styled.i`
    display: inline-block;
    margin-right: 0.75rem;
    padding: 0.2rem;
    background: var(--color-sea);
    border-radius: 5px;
    color: var(--color-white);

    & svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
    }
`

const LogoMenu = styled.i`
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0.25rem;
    color: var(--color-black2);
    cursor: pointer;

    & svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover {
        color: var(--color-gray4);
    }
`
