import { fetchRestWithoutToken, fillRoute } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

export const SHARE_PROGNOSIS_PERSON_GET = 'SHARE_PROGNOSIS_PERSON_GET';
export const SHARE_PROGNOSIS_PERSON_GET_SUCCESS = 'SHARE_PERSON_GET_SUCCESS';
export const SHARE_PRODUCTS_GET_SUCCESS = 'SHARE_PRODUCTS_GET_SUCCESS';

export const getPrognosisPerson = (id: number) => {
    return (dispatch: Dispatch<any>) => {
        dispatch({type: SHARE_PROGNOSIS_PERSON_GET});
        const route = fillRoute(routes.FORMATION, {id}, {
            id,
            textsForDawn: true
        })
        fetchRestWithoutToken(route)
            .then(res => res.json())
            .then(data => { dispatch(getSuccess(data)) })
            .catch(error => {  console.log('PERSON 2', error); });
    };
}

const getSuccess = (data: any) => {
    return {
        type: SHARE_PROGNOSIS_PERSON_GET_SUCCESS,
        payload: data.failed ? null : data.data,
    };
}


export const getProducts = () => {
    return (dispatch: Dispatch<any>) => {
        fetchRestWithoutToken(routes.FORMATION_PRODUCTS)
            .then(res => res.json())
            .then(data => { dispatch(getProductsSuccess(data)) })
            .catch(error => {  console.log('PERSON', error); });
    };
}

const getProductsSuccess = (data: any) => {
    return {
        type: SHARE_PRODUCTS_GET_SUCCESS,
        payload: data.data
    }
}