import React from 'react';
import styled from 'styled-components';

import { DoneIcon } from 'src/assets/img/icons';

export default function Notification({
    type = 'success',
    children
}: {
    type?: string;
    children: React.ReactNode
}) {
    return <Container>
        {type === 'success' && <DoneIcon />}
        {children}
    </Container>
}

const Container = styled.div`
    display: flex;
    align-items: center;
    width: min-content; 
    box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13), 0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
    border-radius: 0.375rem;
    padding: 0.875rem 1rem;
    font-size: 1.125rem;
    white-space: nowrap;

    color: var(--color-white);
    background: #73B330;

    & svg {
        width: 1.5rem;
        margin-right: 0.5rem;
    }

    &.notification-enter {
        opacity: 0.01;
    }

    &.notification-enter.notification-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &.notification-exit {
        opacity: 1;
    }

    &.notification-exit.notification-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    
`