import React from 'react';
import {makeDisclaimer, DisclaimerType, listItems, tenseAspects} from "./utils";

function Energy({
    question,
    potential,
    firstSource,
    energySigns,
    secondSource,
    warning,
    marsHouses,
    marsTenseAspects,
    marsTenseAspectsLabel,
    disclaimer,
}: {
	question: string
	potential: string
	firstSource: string
	energySigns: string[]
	secondSource: string
	warning: string
	marsHouses: string[]
	marsTenseAspects: string[]
	marsTenseAspectsLabel: string
	disclaimer: DisclaimerType
}) {
	return (
		<>
			<div className='print-inseparable'>
				<h2>{question}</h2>
	
				<p>{potential}</p>
			</div>

			<p>{firstSource}</p>

			<ul className="gray-list">
				{listItems(energySigns)}
			</ul>

			<p>{secondSource}</p>

			<p>{warning}</p>

			<ul className="gray-list">
				{listItems(marsHouses)}
			</ul>

			{marsTenseAspects.length > 0 &&
                <>
                    <p>{marsTenseAspectsLabel}</p>
					{tenseAspects(marsTenseAspects)}
                </>
			}

			{makeDisclaimer(disclaimer)}
		</>
	)
}

export default Energy;