import { AnyAction } from 'redux';
import { IMPORT_SEND_URL_SUCCESS, IMPORT_SEND_URL_FAILED, IMPORT_CLEAR } from 'src/store/actions/import';

export default function importReducer(state = {}, action: AnyAction) {
    switch (action.type) {
        case IMPORT_SEND_URL_SUCCESS:
            return { ...action.payload };
        case IMPORT_SEND_URL_FAILED:
            return { ...action.payload };
        case IMPORT_CLEAR:
            return {};
        default:
            return state
    }
}