import { combineReducers } from "redux"

import userReducer from 'src/store/reducers/user'
import horoscopeDailyReducer from 'src/store/reducers/horoscopeDaily'
import personsReducer from 'src/store/reducers/persons'
import productsReducer from 'src/store/reducers/products'
import purchasesReducer from 'src/store/reducers/purchases'
import shareReducer from 'src/store/reducers/share'
import importReducer from 'src/store/reducers/import'
import mapsReducer from 'src/store/reducers/maps'
import promocodeReducer from 'src/store/reducers/promocode'
import formationsReducer from 'src/store/reducers/formations'
import idpReducer from 'src/store/reducers/idp'
import apReducer from 'src/store/reducers/ap'

const rootReducer = combineReducers({
    user: userReducer,
    horoscopeDaily: horoscopeDailyReducer,
    persons: personsReducer,
    purchases: purchasesReducer,
    products: productsReducer,
    share: shareReducer,
    import: importReducer,
    maps: mapsReducer,
    promocode: promocodeReducer,
    formations: formationsReducer,
    idp: idpReducer,
    ap: apReducer,
})
export default rootReducer;