import React from 'react';
import styled from 'styled-components';

function Characteristics({
  title,
  description,
  coreItems,
  items
}: {
  title: string;
  description: string;
  coreItems: {v: string, k: string};
  items: { v: string, k: string }[],
  }) {
  return (
    <>
      <Title>{title}</Title>
      <p>{description}</p>
      {coreItems.v && <>
        <Core>{coreItems.k}</Core>
      </>}
      <ul>
				{
					items.map((a, i: number) =>
						<li key={`char${i}`}>
							<div className="block">
								<Marker className='marker'></Marker>
								<div className="text">{a.k}</div>
							</div>
						</li>
					)
				}
			</ul>
    </>
  )
}

export default Characteristics;

const Title = styled.div`
  font-size: large;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
`

const Marker = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  margin-right: 0rem;
  border-radius: 0.313rem;
`

const Core = styled.div`
  display: 'flex';
  flex-direction: 'column';
`