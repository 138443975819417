import dayjs from 'dayjs';
import { isMobile } from "react-device-detect";
import { CANVAS_HEIGHT, TIMELINE_CIRCLE_COLOR, TIMELINE_CIRCLE_RADIUS, TIMELINE_LABEL_LINE_HEIGHT, TIMELINE_LINES_COUNT, TIMELINE_LINE_COLOR, TIMELINE_LINE_Y } from './constants';
import { getLines, hp, getSynchronWithGlobalTrengsText } from './helpers';
import { TDrawTrendsOptions, TTrend } from './trends';
import { IMousePosition } from './events';

export function drawUI(ctx: CanvasRenderingContext2D, options: TDrawTrendsOptions, mouse?: IMousePosition){
  drawTimeline(ctx);
  
  const trend = options.data[options.activeIndex];
  drawHeaderInfoIdle(ctx, trend);
}

function drawTimeline(ctx: CanvasRenderingContext2D){
  const LINE_Y = hp(CANVAS_HEIGHT - TIMELINE_LINE_Y);
  const TEXT_Y = hp(CANVAS_HEIGHT - TIMELINE_LINE_Y/2);
  const CANVAS_WIDTH = parseInt(ctx.canvas.style.width);
  const MONTH_STEP = CANVAS_WIDTH / (TIMELINE_LINES_COUNT + 1);
  const TODAY_X = MONTH_STEP * (dayjs().date() / 30);

  ctx.lineWidth = 1;
  ctx.strokeStyle = TIMELINE_LINE_COLOR;
  ctx.fillStyle = TIMELINE_LINE_COLOR;
  ctx.font = `14px Inter`;
  ctx.textBaseline = 'middle';
  ctx.textAlign = "center";

  ctx.beginPath();
  ctx.moveTo(0, LINE_Y);
  ctx.lineTo(CANVAS_WIDTH, LINE_Y);

  for(let i = 1; i <= TIMELINE_LINES_COUNT; i++) {
    const x = hp(i * MONTH_STEP);
    ctx.moveTo(x, LINE_Y);
    ctx.lineTo(x, LINE_Y - TIMELINE_LABEL_LINE_HEIGHT);
    ctx.stroke();

    ctx.fillText(dayjs().add(i, 'month').format('MMM'), x, TEXT_Y);
  }
  ctx.stroke();


  ctx.fillStyle = TIMELINE_CIRCLE_COLOR

  ctx.beginPath();
  ctx.arc(TODAY_X, LINE_Y, TIMELINE_CIRCLE_RADIUS, 0, Math.PI * 2, true);
  ctx.fill();

  // ctx.fillText(dayjs().format('DD MMM'), TODAY_X, TEXT_Y);

}

function drawHeaderInfoIdle(ctx: CanvasRenderingContext2D, trend: TTrend) {
  const PADDING = 16;
  const canvasWidth = parseInt(ctx.canvas.style.width);

  ctx.translate(PADDING, PADDING);

  let trendText = 'Нажмите или наведите курсор на тренд, чтобы узнать о нем больше.';
  let dirPeriodtext = '';

  if(trend) {
    trendText = trend.color === 'red'
      ? 'Напряженный тренд - космос движет вас к экстраординарным результатам, которые при нереализации 4 рождений могут восприниматься как проблемы.'
      : 'Гармоничный тренд - космос плавно движет вас в сторону ваших ожиданий и принятых социумом реалий.'

    ctx.fillStyle = trend.color;
    ctx.beginPath();
    ctx.arc(8, 8, 6, 0, Math.PI * 2, true);
    ctx.fill();

    if (trend.directionPeriods?.length) {

      const periodsStr = trend.directionPeriods.map(({ start, end }) => {
        return `${dayjs(start).format('DD.MM.YY')} - ${dayjs(end).format('DD.MM.YY')}`
      }).join(', ');

      dirPeriodtext = `На текущий момент продолжение декораций / раскрытие деталей ситуации периодов: ${periodsStr}`;
    }
  } else {
    ctx.fillStyle = TIMELINE_LINE_COLOR;
    const path = new Path2D('M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.11111 4.44444V6.22222H8.88889V4.44444H7.11111ZM7.11111 7.11111V11.5556H8.88889V7.11111H7.11111Z');
    ctx.fill(path, 'evenodd');
  }


  ctx.fillStyle = TIMELINE_LINE_COLOR;
  ctx.font = `13px Inter`;
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';
  
  const trendTextLines = getLines(ctx, trendText, (canvasWidth - (PADDING * 4)));
  trendTextLines.forEach((line, idx) => {
    ctx.fillText(line, PADDING * 1.5, (2 + (idx * 16)));
  });

  const periodTextLines = getLines(ctx, dirPeriodtext, (canvasWidth - (PADDING * 4)));
  const startY = 4 + trendTextLines.length * 16;
  periodTextLines.forEach((line, idx) => {
    ctx.fillText(line, PADDING * 1.5, (startY + (idx * 16)) );
  });


  ctx.translate(-PADDING, -PADDING);

}

export function drawTooltip(ctx: CanvasRenderingContext2D, trend: TTrend, mouse: number[]) {

  const CANVAS_WIDTH: number = parseInt(ctx.canvas.style.width, 10);

  const [mouseX, mouseY] = mouse;
  
  const WIDTH = 320;
  let HEIGHT = 100;
  
  let tooltipX = mouseX + 16;
  let tooltipY = mouseY + 16;

  const PADDING = 8;
  let textX = tooltipX + PADDING;
  let textY = tooltipY + PADDING;

  // получаем текст тултипа
  const text = getSynchronWithGlobalTrengsText(trend);
  // если текста нет, то не показываем тултип
  if (!text) return;

  const textLines = getLines(ctx, text, WIDTH - (PADDING * 2));
  HEIGHT = (textLines.length * (15)) + (PADDING * 2);

  // проверяем расстояние до правого края. Если тултип не вмещается, то смещаем его левее
  const rightSpace = CANVAS_WIDTH - tooltipX;
  if (rightSpace < WIDTH) {
    tooltipX = mouseX - (WIDTH - rightSpace);
    textX = tooltipX + PADDING;
  }

  // проверяем расстояние до нижнего края. Если тултип не вмещается, то смещаем его выше
  const bottomSpace = CANVAS_HEIGHT - tooltipY;
  if (bottomSpace < HEIGHT) {
    tooltipY = mouseY - (HEIGHT - bottomSpace);
    textY = tooltipY + PADDING;
  }

  
  ctx.strokeStyle = "#e6e6e6";
  ctx.fillStyle = '#ffffff';
  
  ctx.beginPath();
  // @ts-ignore
  ctx.roundRect(tooltipX, tooltipY, WIDTH, HEIGHT, [8]);
  ctx.stroke();
  ctx.fill();

  ctx.font = `12px Inter`;
  // ctx.textBaseline = 'middle';
  ctx.textAlign = "left";
  ctx.fillStyle = "#212121";


  textLines.forEach((line, idx) => {
    ctx.fillText(line, textX, (textY + (idx * 15)));
  });

}