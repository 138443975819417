import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getAP } from 'src/store/actions/ap';
import { getProducts, getPrognosisPerson } from 'src/store/actions/share';

import { Content } from 'src/components/AutoPrediction';
import LocalTrends from 'src/components/AutoPrediction/LocalTrends';
import GlobalTrends from 'src/components/AutoPrediction/GlobalTrends';
import FormDataInput from 'src/components/DataInput/FormDataInput';
// import ConsultationsBanner from 'src/components/banner/ConsultationsBanner';
import NewConsultationsBanner from 'src/components/banner/NewConsultationsBanner';
import ClosePeopleBanner from 'src/components/banner/ClosePeopleBanner';
import { Loader } from 'src/libs';
import { BannerContainer, StyledHeader, StyledPrintedFormData, StyledPrintedHeader, StyledToPrintButton } from '../common';

function AP({
  ap,
  onGetAP,
  match
}: {
  ap: any,
  onGetAP(hash: string): void,
  match: any
}) {
  const { hash } = match.params;
  useEffect(() => {
    onGetAP(hash);
  }, [onGetAP, hash]);

  if (!ap?.[hash]) {
    return <Loader />;
  }

  if (ap[hash].error === 'NO_DATA') {
    return (
      <FormDataInput hash={hash} prognoseType={'AP'} updateData={(newData: any) => {
        onGetAP(hash);
      }} />
    )
  }

  const apData = ap[hash];

  return (
    <Content style={{ width: '50%', margin: '0 auto' }}>
      <StyledHeader>
        <span>{apData.name}</span>
        <StyledToPrintButton elements={['#ap-print-header', '#ap-print-content']}>Скачать в pdf</StyledToPrintButton>
      </StyledHeader>

      <div style={{ opacity: 0.5 }}>{apData.date}, {apData.time}, {apData.place}</div>

      <div id="ap-print-header">
        <StyledPrintedHeader label="Автопрогноз"/>
        <StyledPrintedFormData>
          <span>{apData.name}</span>,&nbsp;
          <span>{apData.date}, {apData.time}</span>,&nbsp;
          <span>{apData.place}</span>
        </StyledPrintedFormData>
      </div>

      <div id="ap-print-content">
        <Content>
          <LocalTrends {...apData.localTrends} />

          <GlobalTrends {...apData.globalTrends}/>
        </Content>
      </div>

      <BannerContainer>
        <NewConsultationsBanner
          className='chronos-ext'
          mode={'ap'}
          link={`${process.env.REACT_APP_BUNNER_LINK}/services/17?block=ap-global`}
        />
        <ClosePeopleBanner className='chronos-ext' />
      </BannerContainer>
    </Content>
  );
};

const mapStateToProps = (state: any) => ({
  ap: state.ap,
  products: state.products,
  shareProducts: state.share.products,
  person: state.share.person,
});

const mapDispatchToProps = (dispatch: any) => ({
  onGetAP: (id: string) => dispatch(getAP(id)),
  onGetProducts: () => dispatch(getProducts()),
  onGetPrognosis: (id: number) => dispatch(getPrognosisPerson(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AP);