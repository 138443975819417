import CircleQueue from 'src/helpers/types/CircleQueue';

import { fetchAPI_REST, fillRoute, refreshToken } from 'src/helpers/api';
import { routes } from 'src/routes';

import { signOut } from 'src/store/actions/user';
import { Dispatch } from 'redux';
import { IFormation } from 'src/components/PrognosisList/compact';
import { TGender } from 'src/components/ui/GenderSelector';
import { searchParams } from 'src/helpers/utils';
import { IPlace } from 'src/libs';

export const PERSONS_GET_SUCCESS = 'PERSONS_GET_SUCCESS';
export const PERSONS_ADD_SUCCESS = 'PERSONS_ADD_SUCCESS';

export interface IPerson {
    id?: number;
    name: string;
    place: IPlace;
    gender?: TGender;
    datetime: {
        date: string;
        time: string;
    },
    hash?: string;
}

const colors = new CircleQueue([
    'var(--color-red)',
    'var(--color-orange)',
    'var(--color-green)',
    'var(--color-purple)',
    'var(--color-blue)',
]);

const sp = searchParams()

export const get = () => {
    return (dispatch: Dispatch<any>) => {
        const route = fillRoute(routes.FORMATIONS, {}, { textsForDawn: true });
        fetchAPI_REST(route)
            .then(data => { dispatch(getSuccess(data as IFormation[])) })
            .catch(error => { dispatch(signOut(error)); });
    };
}

const getSuccess = (data: IFormation[]) => {
    data.forEach((item, index) => { index > 0 && (item.color = colors.next) });
    return {
        type: PERSONS_GET_SUCCESS,
        payload: data
    }
}

interface IAddFormationResult {
    id: number;
    user_id: number;
}
export const add = (
    { name, place, datetime, gender, hash }: IPerson
) => {
    return (dispatch: Dispatch<any>) => {
        const data = {
            name,
            place,
            datetime,
            gender,
            hash
        };

        const resultHandler = (data: any) => {
            dispatch(addSuccess(data));
        };

        fetchAPI_REST<IAddFormationResult>(routes.ADD_FORMATION, data)
            .then(async data => {
                resultHandler(data);
            })
            .catch(error => { dispatch(signOut(error)); });
    };
}

const addSuccess = (data: any) => {
    const id = data.id || 0;

		const sp = searchParams()
    const hash = sp.get('id')
		hash && sp.delete('id')

    return {
        type: 'REDIRECT',
        payload: {
            method: 'push',
            url: `/prognosis/${id}?${sp.toString()}`
        }
    }
}

export const del = (id: number) => {
    return (dispatch: Dispatch<any>) => {
        const route = fillRoute(routes.DELETE_FORMATION, {id});
        fetchAPI_REST(route)
            .then(data => {
                dispatch(delSuccess(data))
            })
            .catch(error => { dispatch(signOut(error)); });
    };
}

const delSuccess = (data: any) => {
    return {
        type: 'REDIRECT',
        payload: {
            method: 'push',
            url: `/prognosis/0?${sp.toString()}`
        }
    }
}

export const edit = (
    { id, name, place, datetime, gender }: IPerson
) => {
    return (dispatch: Dispatch) => {
        const route = fillRoute(routes.UPDATE_FORMATION, {id});
        fetchAPI_REST(route, {
            id,
            name,
            place,
            datetime,
            gender,
            confirmed: true
        })
            .then(data => {
                dispatch(editSuccess(data))
            })
            .catch(error => { console.log(error); alert(error) });
    };
}

const editSuccess = (data: any) => {
    return {
        type: 'REDIRECT',
        payload: {
            method: 'push',
            url: `/prognosis/${data.id}?${sp.toString()}`
        }
    }
}
