import React from 'react';
import {EqualIcon, MinusIcon} from "../../assets/img/icons";

type TType = {
	neg: string
	pos: string
	wrn: string
}
type MTType = {
	m: boolean
	t: TType | string
}
type WhynotItemType = {
	icon: string
	text: string
	title: string
}

interface IRelationsProps {
	description: any;
	// description: string[] | {
	// 	know: {
	// 		items: string[];
	// 		title: string;
	// 	},
	// 	lines: string[];
	// 	lastLine: string;
	// };
	block1: {
		title: string,
		description?: string,
		data: string[]
	},
	block2: {
		title: string,
		description?: string,
		data: MTType[],
		accentMsg?: string
	},
	block3: {
		title: string,
		data: string[],
		description?: string
	},
	block4: {
		title: string,
		title2: string,
		data1: MTType[],
		data2: string[],
		description?: string[],
		description2?: string
	},
	block5: {
		title: string,
		data: string[],
		description?: string
	},
	block6: {
		title: string,
		text?: string,
		description?: string,
		data: WhynotItemType[][]
	}
}

function Relations({
	description,
	block1: {title: who1Title, data: who1, description: who1Description},
	block2: {title: who2Title, data: who2, description: who2Description, accentMsg: who2AccentMsg},
	block3: {title: how1Title, data: how1, description: how1Description},
	block4: {title: how2Title, data1: how2, title2: how3Title, data2: how3, description: how2Description, description2: how3Description},
	block5: {title: whereTitle, data: where, description: whereDescription},
	block6: {title: whynotTitle, text: noWhynotText, data: whynot, description: noWhynotDescription}
}: IRelationsProps) {
	return (
		<>
			{Array.isArray(description) && description?.length && 
				description.map((d: string, i: number) => <p key={`desc_${i}`}>{d}</p>)
			}

			{description.know && 
				<div className='print-inseparable'>
					<h2>{description.know.title}</h2>
					<ul>
						{description.know.items.map((item: string, i: number) => (
							<li
								key={`desc_know_${i}`}
								className="list-item"
							>
								{item}
							</li>
						))}
					</ul>
				</div>
			}

			{description.lines && 
				<div className='print-inseparable'>
						{description.lines.map((item: string, i: number) => (
							<div
								key={`desc_line_${i}`}
								className="list-item"
							>
								{item} <br /><br />
							</div>
						))}
				</div>
			}

			{description.lastLine && 
				<div style={{ color: 'red' }}>{description.lastLine}<br /><br /></div>
			}
			
			<div className='print-inseparable'>
				
				<h2>{who1Title}</h2>
				{who1Description && <p>{who1Description}</p>}
				<ul>
					{who1.map((item: string, i: number) => (
						<li
							key={`who1_${i}`}
							className="list-item"
						>
							{item}
						</li>
					))}
				</ul>
			</div>


			<div className='print-inseparable'>
				<h2>{who2Title}</h2>
				{who2Description && <p >{who2Description}</p>}
				<ul>
					{who2.map((item: any, i: number) =>
						<li key={`who2_${i}`} style={{ color: item.m ? '#282' : 'inherit' }}>{item.t}</li>
					)}
				</ul>
			</div>
			
			{who2AccentMsg && <p style={{ color: '#609b60' }}>{who2AccentMsg}</p>}

			<div className='print-inseparable'>
				<h2>{how1Title}</h2>
				{how1Description && <p >{how1Description}</p>}
				<ul>
					{how1.map((item: string, i: number) => <li key={`how1_${i}`}>{item}</li>)}
				</ul>
			</div>

			

			<div className='print-inseparable'>
				{how2Title && <h2>{how2Title}</h2>}
				{how2Description && Array.isArray(how2Description) && <p>{how2Description.join(' ')}</p>}
				{how2Description && !Array.isArray(how2Description) && <p>{how2Description}</p>}
				<ul>
					{how2.map((item: any, i: number) =>
						<li key={`how2_${i}`}>
							<div style={{ color: '#609b60' }}>{item.t.pos}</div>
							<div style={{ color: '#C5372F' }}>{item.t.neg}</div>
							<div style={{ color: '#286ABE' }}>{item.t.wrn}</div>
							{item.m && <div>{item.m}</div>}
						</li>
					)}
				</ul>
			</div>

			{how3.length > 0
				? <div className='print-inseparable'>
					<h4>{how3Title}</h4>
					{how3Description && Array.isArray(how3Description) && <p>{how3Description.join(' ')}</p>}
					{how3Description && !Array.isArray(how3Description) && <p>{how3Description}</p>}
					<ul>
						{how3.map((item: string, i: number) => <li key={`how3_${i}`}>{item}</li>)}
					</ul>
				</div>
				: null
			}


			<div className='print-inseparable'>
				<h2>{whereTitle}</h2>
				{whereDescription && <p>{whereDescription}</p>}
				<ul>
					{where.map((item: string, i: number) => <li key={`where_${i}`}>{item}</li>)}
				</ul>
			</div>


			<h2>{whynotTitle}</h2>
			{noWhynotDescription && <p >{noWhynotDescription}</p>}
			{whynot?.length > 0
				? whynot.map((wn: any[], i: number) =>
					<div className="whynot-block" key={`whynot_item_${i}`}>
						{wn.map(({ text, icon, title }, j) => {
							const Img = icon === 'minus' ? MinusIcon : EqualIcon;

							return (
								<div className="block-item" key={`whynot_item_${i}_${j}`}>
									<div className={`aspect-item ${icon}`}>
										<Img style={{ position: 'relative' }} />
										<h1>{title}</h1>
										<p className="block-item_content">{text}</p>
									</div>
								</div>
							);
						})
						}
					</div>
				)
				: <p>{noWhynotText}</p>
			}
		</>
	);
}

export default Relations;