import React from 'react'
import { CurrencyCode } from 'src/libs'
import styled from 'styled-components'


interface PriceProps {
	className?: string
	price: number
	discount?: number
	currency?: CurrencyCode
	newPrice?: number
}

export const Price = (props: PriceProps) => {
	const currency = () => {
		switch (props.currency) {
			case 'USD':
				return '$'
			case 'KZT':
				return '₸'
			default:
				return '₽'
		}
	}

	if (props.newPrice || props.discount) {
		const newPrice = () => {
			if (props.newPrice) {
				return props.newPrice
			}

			if (props.discount) {
				return Math.round(props.price * (1 - props.discount) + Number.EPSILON)
			}
		}

		return (
			<Container className={props.className}>
				<OldPrice>{props.price}</OldPrice>
				<>{newPrice()}{currency()}</>
			</Container>
		)
	}

	return <div>{props.price}{currency()}</div>
}

const OldPrice = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.37);
	margin-right: 0.5rem;

  @media (max-width: 470px) {
      display: none;
  }
`

const Container = styled.div`
	display: flex;
	align-items: center;
`
