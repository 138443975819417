import { AnyAction } from 'redux';
import { IDP_HASH_GET } from 'src/store/actions/idp';

export default function formationsReducer(state = {}, action: AnyAction) {
  switch (action.type) {
    case IDP_HASH_GET:
      return {
        ...state,
        [action.payload.hash]: action.payload
      };
    default:
      return state;
  }
}