import { apiURL, fetchRestWithoutToken, fillRoute } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';
import { IData } from 'src/components/DataInput/FormDataInput';

export const IDP_HASH_GET = 'IDP_HASH_GET';

export const getIDP = (hash: string) => {
  return (dispatch: Dispatch) => {
    const route = fillRoute(routes.IDP_HASH, { hash });
    fetchRestWithoutToken(route)
      .then(resp => resp.json())
      .then(data => dispatch({
        type: IDP_HASH_GET,
        payload: {
          hash,
          ...data,
        },
      }))
      .catch(error => {
        alert('Ошибка получения данных 2');
        console.log('PERSON 2', error);
      });
  };
}

export const postIDP = async (hash: string, dataPost: IData ) => {
  await fetch(`${apiURL}${fillRoute(routes.IDP_HASH_PUT, { hash }).url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost)
  });
};