import React from 'react';
import styled from 'styled-components';

import { WarningIcon } from 'src/assets/img/icons';

interface IButton {
    name: string;
    type: 'a' | 'button';
    action(): void;
}

export default function Confirm({
    title = 'Изменения не сохранятся',
    text = 'Изменения не сохранятся',
    buttons = []
}: {
    title: string;
    text: string;
    buttons: IButton[];
}) {

    return <Container>
        <Block>
            <WarningIcon />
            <h2>{title}</h2>
            <span>{text}</span>
            <nav>
                {buttons.map((item, index) => {
                    if (item.type === 'button') return <button key={index} onClick={item.action}>{item.name}</button>
                    else return <button key={index} data-link onClick={item.action} >{item.name}</button>
                })}
            </nav>
        </Block>
    </Container>
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-white-modal);
    z-index: 500;
`

const Block = styled.div`
    padding: 2.2rem;
    width: 28rem;
    background: var(--color-white);
    box-shadow: 0px 1.8px 5.4px rgba(0, 0, 0, 0.09), 0px 9.6px 21.6px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: var(--color-black2);
        
    @media (max-width: 768px) {
        padding: 1rem;
        width: calc(100% - 2rem);
        box-sizing: border-box;
    }

    & svg {
        width: 2.3rem;
        height: 2.3rem;
        fill: #EF5350;
        margin-bottom: 0.8rem;
    }


    & h2 {
        font-weight: normal;
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 0.675rem;
        max-width: 85%;

        @media (max-width: 768px) {
            max-width: 98%;
        }
    }

    & span {
        display: block;
        line-height: 1.55;
        color: var(--color-gray4);
        max-width: 85%;
        
        @media (max-width: 768px) {
            max-width: 98%;
        }
    }

    & nav {
        margin-top: 1.125rem;
    
        & button {
            margin-right: 1.125rem;
            padding: 0.6rem 1rem;
            border: none;
            outline: none;
            font-size: inherit;
            color: var(--color-white);
            background: var(--color-red);
            cursor: pointer;
            border-radius: 0.325rem;
        }

        & button[data-link] {
            color: inherit;
            background: none;
            padding: 0;
            border-radius: 0;
        }
    }
`

