import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile, isBrowser } from 'react-device-detect';
import { BackIcon, ShareIcon, DawnIcon, ChronosMonoIcon } from 'src/assets/img/icons';

import Loading from 'src/components/Loading/';
import PersonHeaderCompact from 'src/components/PersonHeader/compact';
import PersonHeader from 'src/components/PersonHeader';
import PrognosisListCompact, { IFormation } from 'src/components/PrognosisList/compact';
import Buy, { IProduct } from 'src/components/Buy/';
import Appeal from 'src/components/ui/Appeal/';
import Map from 'src/components/Map/'
import { getFormation } from 'src/store/actions/formations';

import { getProducts, getPrognosisPerson } from 'src/store/actions/share';
import DevPlan from 'src/components/DevPlan';
import AutoPrediction from 'src/components/AutoPrediction';
import Updater from "../../../components/Updater";

import { InfoIcon2 } from 'src/assets/img/icons';

import { mainApi } from 'src/helpers/api';

import './print.css';
import { appealWindowText } from 'src/helpers/utils';
import SynastryBig from 'src/components/Synastry/SynastryBig';
import DevPlanForKids from 'src/components/DevPlanForKids';
import IndiBunner from 'src/components/banner/IndiBunners/IndiBunner';
import { MoreMenu, ToPrintButton } from 'src/libs';


export function printPage(divIds: string[]) {
  let printContents = '';
  divIds.forEach((divId) => {
    const element = document.getElementById(divId);
    if (element) {
      printContents += element.innerHTML;
    } 
  });
  if (printContents) {
    const printContainer = document.createElement('div');
    printContainer.innerHTML = printContents;

    const printStyle = document.createElement('style');
    printStyle.innerHTML = `
      @media print {
        body * {
          visibility: hidden;
        }
        #print-container, #print-container * {
          visibility: visible;
        }
        #print-container {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
      }
    `;
    document.head.appendChild(printStyle);
    printContainer.id = 'print-container';
    document.body.appendChild(printContainer);

    window.onafterprint = () => {
      document.body.removeChild(printContainer);
      document.head.removeChild(printStyle);
    };

    window.print();
  }
}


function Item({
  match,
  onGetFormation,
  formations,
  onGetPrognosis,
  person,
  personLoaded,
  onGetProducts,
  shareProducts,
  products,
}: {
  match: any,
  onGetFormation(id: number): void,
  formations: IFormation[],
  onGetPrognosis(id: number): void,
  person: IFormation,
  personLoaded: boolean,
  onGetProducts(): void,
  shareProducts: IProduct[],
  products: IProduct[],
}) {
  const userId = +match.params.userId;

  const isShare = !formations[userId] || match?.path?.indexOf('/share/') === 0;

  const user = isShare ? person : formations && formations[userId];
  const productsList = products; // isShare ? shareProducts : products;  // FIXME: ???

  const [buyIsOpened, setBuyIsOpened] = React.useState(false);

  const [openModal, setopenModal] = useState(false);

  useEffect(() => {
    if(isShare) {
      onGetProducts();
      onGetPrognosis(userId);
    } else {
      onGetFormation(userId);
    }
  }, [onGetFormation, userId]);

  if(isShare && personLoaded && !user) {
    const currentURL = `${window.location.protocol}//${window.location.host}`;
    const encodeURL = encodeURIComponent(currentURL)
    window.location.href = `${process.env.REACT_APP_AUTH_URL}#/?ref=${encodeURL}`;
    return null;
  }

  if(!user || !productsList?.length) return <>
    <Center>
    </Center>
    <ColumnRight>
      <Loading />
    </ColumnRight>
  </>;

  const prognosisKey = match.params.key;
  const prognosis = productsList.find(item => item.key === prognosisKey)!;
  const isRelations = prognosis.key.indexOf('relations') >= 0;

  document.title = `${user.name} - ${prognosis.name}`;

  //FIXME
  //TODO: Delete isSeventeenVersionBirth and ternary when the version of birth (IDP) will become 3.0
  const isSeventeenVersionBirth = prognosisKey === 'birth' && user.data.idp?.birth.version === '1.7' && Math.floor(+prognosis?.version) === 2
  const hasUpdate = user.data.idp ? isSeventeenVersionBirth ? false : Math.floor(user.data.idp[prognosisKey]?.version) < Math.floor(+prognosis?.version) : false

  const userIdpList = user.data.idp || {};
  const userApList = user.data.ap || {};
	const userCidpList = user.data.cidp || {};

  const idpList = productsList
    .filter(item => item.type !== item.key && item.type === 'idp' && (!user.gender || !item?.gender || item?.gender === user.gender))
    .map(item => { return { ...item, isBuyed: !!userIdpList[item.key] } });
  const apList = productsList
    .filter(item => item.type !== item.key && item.type === 'ap')
    .map(item => { return { ...item, isBuyed: !!userApList[item.key] } });

  const cidpList = products
    .filter(item => item.type === 'cidp' && (!user.gender || !item?.gender || item?.gender === user.gender))
    .map(item => { return { ...item, isBuyed: !!userIdpList[item.key]} });

	const extraGender = new RegExp(user.gender ? user.gender === 'male' ? 'Female' : 'Male' : '')
	const FilteredUserIdpList = Object.keys(userIdpList).filter(item => !item.match(extraGender) )

  const purchasesCount = Object.keys(FilteredUserIdpList).length + Object.keys(userApList).length + Object.keys(userCidpList).length;
  const maxPurchasesCount = idpList.length + apList.length + cidpList.length;
  const appealTextData = appealWindowText(userIdpList, userApList, idpList, apList);

  const onShare = async () => {
    const { success } = user.is_public
      ? { success: true}
      : await mainApi.shareFormation(userId);
    if(success) {
      navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/share/prognosis/${userId}/${prognosisKey}`)
        .then(() => alert('Ссылка скопирована в Ваш буфер обмена'))
        .catch(err => alert('Ошибка копирования в буфер обмена ' + err));
    } else {
      alert('Что-то пошло не так');
    }
  }

  let el = null;
  if(prognosis.type === 'ap' || prognosis.type === 'idp' || prognosis.type === 'cidp') {
    if(user?.data?.[prognosis.type]?.[prognosisKey]?.data && prognosis.type !== 'cidp') {
      el = prognosis.type === 'ap'
        ? <AutoPrediction type={prognosisKey} data={user.data[prognosis.type][prognosisKey].data} />
        : <DevPlan type={prognosisKey} data={user.data[prognosis.type][prognosisKey].data} />
    } else if (prognosis.type === 'cidp') {
        el = <DevPlanForKids type={'cidp'} data={user.data[prognosis.type][prognosisKey].data}/>

    } else{
      let html
      html = user.data[prognosis.type as 'ap'| 'idp'][prognosisKey].html;
      html = html.replace(/Включая[\s\S]*повлияет на будущее/gi, '');
      el = (
        <Content>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      );
    }
  } else {
    let html
    html = user.data[prognosis.type as 'ap'| 'idp'][prognosisKey].html;
    html = html.replace(/Включая[\s\S]*повлияет на будущее/gi, '');
    el = (
      <Content>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    );
  }

const clickHandler = () => {
  if(!openModal) {
    const modal = document.querySelector("[class*='ui-dropdown']") as HTMLElement;
    const closeButton = document.querySelector("[data-test-id*='Share']") as HTMLElement;
const bodyClickHandler = (e: MouseEvent) => {
    if (e.target === closeButton) {
      document.body.removeEventListener('click', bodyClickHandler);
      setopenModal(false)
    } else {
      if (e.target !== modal) {
        const eventClick = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      document.body.removeEventListener('click', bodyClickHandler);
      closeButton.dispatchEvent(eventClick);
    }
  }
  };
  setopenModal(true)
  document.body.addEventListener('click', bodyClickHandler);
} else {
  setopenModal(false)
}
};

  return <>
    <Center>
      <div id="print-header">
        <ItemHeader>
          <div>
            <ChronosMonoIcon color="#C3CCCA"/>
            <DawnIcon/>
          </div>
          <div className="label">{prognosis.type === 'idp' ? 'План развития' : 'Автопрогноз'}</div>
        </ItemHeader>
        <ItemTitle>{prognosis.name}</ItemTitle>
      </div>
      <div id="print-profile-data">
        <ProfileData>
          <span>{user.name}, {user.datetime.date}, {user.datetime.time}, {user.place.name}</span>
        </ProfileData>
      </div>
      <Map user={user} />
      {isBrowser && <PersonHeaderCompact user={user} onShare={onShare} />}
      <IndiBunner link='https://indi.chronos.mg/trial?d=14&utm_source=widget_banner' fromModule isMobile={isMobile}/>
      {isMobile && <PersonHeader user={user} />}
      {isRelations && <SynastryBig />}
      <Header>
        <div>
          {!isShare && <Link to={`/prognosis/${userId}`}><BackIcon /></Link>}
        </div>
        <span>{prognosis.name}</span>
        <span>
          {isMobile &&
            <StyledMoreMenu
            button={<Share onClick={clickHandler} />}
            position='bottom-right'
            options={[
              { label: 'Скопировать ссылку', action: () => onShare() },
              {
                label: 'Скачать в pdf', action: () => {
                  // const toPdfButton = document.querySelector('#print-button button') as HTMLButtonElement;
                  // toPdfButton.click();
                  printPage(['print-header', 'print-profile-data', 'print-map', 'print-content'])
                }
                }
              ]}
              />
            }
        </span>
        {/* здесь мы просто размещаем кнопку и скрываем ее, что бы потом в любом месте найти ее по id и кликнуть программно */}
        <div id="print-button" style={{visibility: 'hidden', width: 0}}>
        <StyledToPDFButton className='to-print' onClick={() => printPage(['print-header', 'print-profile-data', 'print-map', 'print-content'])}><span>Скачать в pdf</span></StyledToPDFButton>
          
          {/* <StyledToPDFButton
            elements={['#print-header','#print-profile-data','#print-map','#print-content']}
            excludes={['button', '.chronos-ext']}
            inseparable={['.print-inseparable']}
            size='small'
            color="transparent"
          >Скачать в pdf</StyledToPDFButton> */}
        </div>
      </Header>

      {hasUpdate && <Updater mode={'in-item'} price={prognosis.price} discount={0.69} userId={user.id} productId={prognosis.id} prognosisKey={prognosis.key}/>}

      <Content id="print-content">
        <Prolog>
          <InfoIcon2 />
          <div className="text">
            Так выглядит натальная карта этого человека на момент рождения,
            ее может использовать астролог для более глубокого анализа.
          </div>
        </Prolog>
        { el }
      </Content>

      {buyIsOpened && <Buy onClose={() => setBuyIsOpened(false)} user={user} />}
    </Center>
    {!isShare && <ColumnRight>
      <PrognosisListCompact title='Для ребенка. Актуально до 14 лет' list={cidpList} user={user} mode="cidp" />
      <PrognosisListCompact title='План развития' list={idpList} user={user} mode="idp" />
      <PrognosisListCompact title='Автопрогноз' list={apList} user={user} mode="ap" />
      {purchasesCount < maxPurchasesCount &&
        <Appeal {...appealTextData} onClick={() => { setBuyIsOpened(true) }} inverse />
      }
    </ColumnRight>}
  </>
}

const mapStateToProps = (state: any) => {
  return {
    formations: state.formations,
    products: state.products,
    shareProducts: state.share.products,
    person: state.share.person,
    personLoaded: state.share.personLoaded,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetFormation: (id: number) => { dispatch(getFormation(id)) },
    onGetProducts: () => { dispatch(getProducts()) },
    onGetPrognosis: (id: number) => { dispatch(getPrognosisPerson(id)); },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);

const ItemHeader = styled.div`
  display: none;
  justify-content: space-between;
  margin-bottom: 1.75rem;

  svg {
    width: 2.25rem;
    height: 2.25rem;
  }

  svg + svg {
    margin-left: 0.225rem;
  }

  .label {
    display: flex;
    align-items: center;
    height: 2.25rem;
    padding: 0 1rem;
    border: 1pt solid var(--color-sea2);
    border-radius: 2.438rem;

    font-size: 1.125rem;
    color: var(--color-sea2);
  }

  @media print {
    display: flex;
  }
`;

const ItemTitle = styled.h1`
  display: none;
  position: relative;
  margin: 0 0 0.875rem 0 !important;
  font-size: 2.25rem;
  color: var(--color-black2);
  font-weight: 500;

  @media print {
    display: block;
  }
`;

export const Center = styled.div`
  width: var(--main-layout-center-width);
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;

export const ColumnRight = styled.div`
  width: var(--main-layout-sidebar-right-width);
  padding-bottom: 3rem;
`;

export const Header = styled.header`
  margin-top: 1.25rem;
  margin-bottom: 1.125rem;
  font-size: 2rem;
  line-height: 2.75rem;
  color: var(--color-black2);
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  & > span {
    margin-right: 0.75rem;

    /* @media (max-width: 768px) {
      & {
        margin-right: 0;
      }
    } */
  }

  & > div {
    display: inline-block;

    @media (max-width: 768px) {
      & > * {
        margin-right: 0.75rem;
      }
      & > *:first-child {
        margin-left: 0;
      }
    }

    a {
      display: block;
      color: inherit;
      position: absolute;
      left: -2.5rem;
      top: 0.7rem;
      line-height: 1rem;

      & svg {
        width: 1.5rem;
        height: 1.5rem;

        @media (max-width: 768px) {
          width: 1rem;
        }
      }

      @media (max-width: 768px) {
        position: static;
        display: inline-block;

        & svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`;

export const Prolog = styled.div`
  display: none;
  position: relative;
  align-items: flex-start;
  box-sizing: border-box;
  /* margin: 0 1rem; */
  margin-bottom: 1.313rem;
  font-size: 1.125rem;

  & svg {
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    left: -2.5rem;
    color: var(--color-gray)
  }

  & .text {
    font-size: 1.125rem;
    color: var(--text-secondary);
    line-height: 1.5;
  }

  @media print {
    display: flex;

    & .text {
      text-indent: 1.7rem;
    }

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      left: -0.2rem;
      top: 0rem;
      vertical-align: baseline;
    }
  }
`;

export const Content = styled.article`
  position: relative;
  font-size: 1.125rem;
  line-height: 1.68rem;
  color: var(--color-gray4);

  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  & h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: normal;
    /* line-height: 1.6; */
    color: var(--color-black2);

    @media (max-width: 768px) {
      margin: 0 0 0.3rem 0;
      font-size: 1rem;
      line-height: 1.2;
    }

    @media print {
      margin-bottom: 1rem;
    }
  }

  & h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.6;
    color: var(--color-black2);

    @media (max-width: 768px) {
      margin: 0 0 0.3rem 0;
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  & a {
    color: var(--color-sea);
  }

  & p {
    margin-top: 0;
    margin-bottom: 1.5em;
  }

  & .block {
    display: flex;
  }
`;

const Share = styled(ShareIcon)`
  width: 1.5rem;
  height: 1.5rem;
  /* margin-right: 0.75rem; */
  fill: var(--color-gray);
  vertical-align: middle;

  @media (max-width: 768px) {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledToPDFButton = styled.div`
  margin-left: 1rem;
  color: var(--color-black2);

  &:hover {
    border-color: var(--color-black2) !important;
  }
`;

const StyledMoreMenu = styled(MoreMenu)`
  [class*=ui-dropdown] {
    &.mobile {
      height: 7rem;
    }
  }
  & .ch-option {
    /* background-color: var(--color-gray2); */
  }

`;

const ProfileData = styled.div`
    display: none;
    align-items: center;

    & span:first-of-type {
        margin-right: 0.75rem;
    }

    & span:last-of-type {
        color: var(--color-gray);
    }

    @media print {
      display: flex;
    }
`;
