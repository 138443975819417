import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isMobile } from "react-device-detect";

import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { mainApi } from 'src/helpers/api';
import { CloseIcon } from 'src/assets/img/icons';

import Confirm from 'src/components/ui/Confirm/'
import PersonForm from 'src/components/PersonForm/'

import { add, IPerson } from 'src/store/actions/persons'
import { formatedDate, formatedTime } from "../../helpers/utils";
import GenderSelector, { TGender } from '../ui/GenderSelector';
import { Button, DateTimeInput, Input, InputContainer, IPlace, PlaceInput } from 'src/libs';

dayjs.extend(utcPlugin);

function PersonAdd({
    hash,
    onClose = () => { },
    onAdd
}: {
    hash?: string,
    onClose(): void,
    onAdd(person: IPerson): void
}) {
    const [inputSize, setInputSize] = React.useState<null | number>(null);
    const ref = React.useRef();

    const resizeHandler = () => {
        const { clientWidth }: any = ref.current || {};
        setInputSize(clientWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();

            window.removeEventListener("resize", resizeHandler);

    }, []);

    useEffect(() => {
        window.addEventListener('popstate', onClose, false);
        return () => window.removeEventListener('popstate', onClose, false);
    })
    const [step, setStep] = React.useState(1);
    const [isShowConfirm, setIsShowConfirm] = React.useState(false);

    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState<string>();
    const handleName = (value: string) => {
        setName(value);
        if (value.trim() === '') setNameError('Имя не может быть пустым');
        else if (!/^[a-z,а-я,ё,\s]*$/i.test(value)) setNameError('В имени могут быть только буквы');
        else setNameError(undefined)
    }

    const [lon, setLon] = React.useState<number>(0);
    const [lat, setLat] = React.useState<number>(0);
    const [place, setPlace] = React.useState('');
    const [gender, setGender] = React.useState<TGender>('female');
    const [placeError, setPlaceError] = React.useState<string>();
    const handlePlace = (place: IPlace) => {
        setPlace(place.name);
        setLon(place.lon);
        setLat(place.lat);
        setPlaceError(undefined);
    }
    const onChangePlace = (val: string) => {
        if (val?.length < 3) {
            setPlaceError('Введите от трех символов')
        } else {
            setPlaceError('Выберите город из списка')
        }
    }

    const [date, setDate] = React.useState('');
    const [dateError, setDateError] = React.useState<string>();
    const handleDate = (isoDate: string) => {
        if (Date.parse(isoDate) >= Date.now()) {
            setDateError('Введите дату в прошлом');
        } else if (Date.parse(isoDate) < Date.parse('1900')) {
            setDateError('Введите корректную дату')
        } else {
            setDate(isoDate);
            setDateError(undefined)
        }
    }
    const defaultTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0); // 12:00:00
    const [time, setTime] = React.useState<string>(defaultTime.toString());
    const [timeError, setTimeError] = React.useState<string>();
    const handleTime = (isoTime: string) => {
        setTime(isoTime);
        setTimeError(undefined);
    }

    const inputContainerStyle = { marginBottom: '1.8rem' }
    const buttonStyle = { padding: '0.9rem 1rem', opacity: '1', borderRadius: '0.375rem' }

    const nextStep = () => {
        if (step < 6) {
            if (step === 1) {
                window.ym(49958389, 'reachGoal', 'Name_add_dawn');
            } else if (step === 2) {
                window.ym(49958389, 'reachGoal', 'Gender_add_dawn')
            } else if (step === 3) {
                window.ym(49958389, 'reachGoal', 'City_add_dawn')
            } else if (step === 4) {
                window.ym(49958389, 'reachGoal', 'Date_add_dawn')
            } else if (step === 5) {
                window.ym(49958389, 'reachGoal', 'TimeBirth_add_dawn')
            }
            setStep(step + 1);
        }
    }

    const closeHandle = () => {
        if (name === '') onClose();
        setIsShowConfirm(true);
    }

    const onSave = (name: string, place: IPlace, date: string, time: string, gender: TGender, hash?: string) => {
        const newPersonData: IPerson = {
            name,
            place: {
                name: place.name,
                lat: place.lat,
                lon: place.lon
            },
            gender: gender,
            datetime: {
                date: formatedDate(date),
                time: formatedTime(time),
            },
            hash
        }

        if (newPersonData.datetime.time.length === 5) newPersonData.datetime.time += ':00';
        window.ym(49958389, 'reachGoal', 'Create_add_dawn');
        onAdd(newPersonData);
    }

    const checkInputMode = (errorMessage?: string) => {
        return errorMessage ? 'error' : 'normal';
    }

    return <Container>
        <Header>
            <button onClick={closeHandle}>
                Отменить создание
                <span><CloseIcon /></span>
            </button>
        </Header>

        {isMobile && <header>Добавление человека</header>}

        <Forms>
            {!isMobile && <header>
                <span>Введите данные рождения человека, для которого оформляете план развития</span>
                Заполнение обязательно для получения ИПР
            </header>}


            {step === 1 && <form onSubmit={(e) => { e.preventDefault(); !nameError && nextStep() }}>
                <Label>
                    <i>1</i>
                    <InputContainer
                        label="Имя"
                        message={(nameError ?
                            nameError : '')} style={inputContainerStyle}>
                        <Input ref={ref} size="big" mode={checkInputMode(nameError)} placeholder="Как зовут человека?" value={name} onChange={handleName} autoFocus clearable />
                    </InputContainer>
                    <nav>
                        {!nameError && name !== '' && <>
                            <Button type="submit" color="var(--color-sea)" size="small" style={buttonStyle}>Подтвердить</Button> Или нажмите Enter
                        </>}
                    </nav>
                </Label>
            </form>}

            {step === 2 && <form onSubmit={(e) => { e.preventDefault(); !nameError && nextStep() }}>
                <Label>
                    <i>2</i>
                    <InputContainer label="Ваш пол" message={nameError} style={inputContainerStyle}>
                        <GenderSelector value={gender} setValue={setGender} />
                    </InputContainer>
                    <nav>
                        {!nameError && name !== '' && <>
                            <Button type="submit" color="var(--color-sea)" size="small" style={buttonStyle}>Подтвердить</Button> Или нажмите Enter
                        </>}
                    </nav>
                </Label>
            </form>}

            {step === 3 && <form onSubmit={(e) => { e.preventDefault(); !dateError && nextStep() }}>
                <Label>
                    <i>3</i>
                    <InputContainer label='Дата рождения' message={dateError} style={inputContainerStyle}>
                        <DateTimeInput
                            type='date'
                            size='big'
                            value={date}
                            onChange={handleDate}
                            mode={checkInputMode(dateError)}
                            hideIcon={true}
                            onInvalid={() => setDateError('Нужно выбрать дату')}
                        />
                    </InputContainer>
                    <nav>
                        {!dateError && date !== '' && <>
                            <Button type="submit" color="var(--color-sea)" size="small" style={buttonStyle}>Подтвердить</Button>
                        </>}
                    </nav>
                </Label>
            </form>}

            {step === 4 && <form onSubmit={(e) => { e.preventDefault(); !timeError && nextStep() }}>
                <Label>
                    <i>4</i>
                    <InputContainer label='Время рождения' message={timeError} style={inputContainerStyle}>
                        <DateTimeInput
                            type='time'
                            size='big'
                            value={time}
                            onChange={handleTime}
                            mode={checkInputMode(timeError)}
                            hideIcon={true}
                            onInvalid={() => setTimeError('Нужно ввести время')}
                            utcMode
                        />
                    </InputContainer>
                    <nav>
                        {!timeError && time !== '' && <>
                            <Button type="submit" color="var(--color-sea)" size="small" style={buttonStyle}>Подтвердить</Button>
                        </>}
                    </nav>
                </Label>
            </form>}

            {step === 5 && <form onSubmit={(e) => { e.preventDefault(); !placeError && nextStep() }}>
                <Label>
                    <i>5</i>
                    <InputContainer label="Место рождения" message={placeError} style={inputContainerStyle}>
                        <PlaceInput
                            size="big"
                            value={place}
                            mode={checkInputMode(placeError)}
                            asyncDataFn={mainApi.places.bind(mainApi)}
                            asyncDetailFn={mainApi.place.bind(mainApi)}
                            onChange={onChangePlace}
                            onSelect={handlePlace}
                            placeholder="Где родился?"
                            autoFocus
                            lang="ru"
                        />
                    </InputContainer>
                    <nav>
                        {!placeError && place !== '' && <>
                            <Button type="submit" color="var(--color-sea)" size="small" style={buttonStyle}>Подтвердить</Button> Или нажмите Enter
                        </>}
                    </nav>
                </Label>
            </form>}

            {step === 6 && <PersonForm
                type="add"
                onSave={(name: string, place: IPlace, date: string, time: string, gender: TGender) => onSave(name, place, date, time, gender, hash)}
                initialGender={gender}
                initialName={name}
                initialPlace={{
                    name: place,
                    lon: lon || 0,
                    lat: lat || 0
                }}
                initialDate={date}
                initialTime={time}
            ></PersonForm>}
        </Forms>

        <Navigation>
            <NavigationItem current={step === 1} past={step > 1}>Имя</NavigationItem>
            <NavigationItem current={step === 2} past={step > 2}>Ваш пол</NavigationItem>
            <NavigationItem current={step === 3} past={step > 3}>Дата рождения</NavigationItem>
            <NavigationItem current={step === 4} past={step > 4}>Время рождения</NavigationItem>
            <NavigationItem current={step === 5} past={step > 5}>Место рождения</NavigationItem>
            <NavigationItem current={step === 6} past={step > 6}>Итог</NavigationItem>
            {isMobile && <StepCounter>{step} из 6</StepCounter>}
        </Navigation>


        {isShowConfirm && <Confirm
            title='Изменения не сохранятся'
            text='Если вы закроете создание карты, то все именения не будут сохранены, вы уверены что хотите выйти?'
            buttons={[
                { name: 'Вернуться', type: 'a', action: () => { setIsShowConfirm(false); } },
                { name: 'Выйти', type: 'button', action: () => { onClose(); } }
            ]}
        ></Confirm>}
    </Container>
}

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onAdd: (data: IPerson) => {
            dispatch(add(data));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonAdd);

const Container = styled.div`
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 3rem 7.5rem;
    background-color: var(--color-bg);
    color: var(--color-gray);
    z-index: 10;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 1rem;
        padding-bottom: 4rem;
        overflow: auto;
        height: 100%;

        & header {
            margin-bottom: 1rem;
        }
    }
`

const Header = styled.div`
    display: flex;

    @media (max-width: 768px) {
        position: absolute;
        right: 1rem;
    }

    & button {
        display: flex;
        align-items: center;
        margin-left: auto;
        border: none;
        outline: none;
        background: none;
        font-size: 0.875rem;
        cursor: pointer;
        color: inherit;

        @media (max-width: 768px) {
            font-size: 0rem;
            z-index: 10;
        }

        & span {
            margin-left: 1rem;
            padding: 0.75rem;
            background-color: var(--color-gray2);
            border-radius: 50%;

            @media (max-width: 768px) {
                margin-left: 0.5rem;
                padding: 0.5rem;
            }

            & svg {
                width: 1.5rem;
                height: 1.5rem;
                display: block;

                @media (max-width: 768px) {
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }
        }

        &:hover {
            color: var(--color-black2);
        }
    }
`

const Forms = styled.ul`
    display: block;
    width: 55%;
    min-width: 25rem;
    margin: 0 0 0 25%;
    list-style: none;
    padding: 0;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        min-width: initial;
        font-size: 0.75rem;

        & input {
            font-size: 1.5rem;
        }
    }

    & > header {
        position: relative;
        top: -25vh;
        font-size: 1.125rem;
        max-width: 32rem;

        & > span {
            display: block;
            font-size: 1.5rem;
            margin-bottom: 6px;
        }

        @media (max-width: 768px) {
            top: 0;
            margin-bottom: 2rem;
        }
    }
`

const Navigation = styled.ul`
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    grid-gap: 0.75rem;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    margin-top: 2rem;

    @media (max-width: 768px) {
        display: flex;
    }
`

const NavigationItem = styled.li<{ current: boolean, past: boolean }>`
    font-size: 0.875rem;

    @media (max-width: 768px) {
        font-size: 0rem;
        width: ${props => props.current ? `1` : `0.4`}rem;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 0.2rem;
        border-radius: 1rem;
        background: red;
        margin-top: 0.75rem;
        background-color: var(--color-gray2);
        ${props => (props.current || props.past) && `background-color: var(--color-sea);`}
        ${props => props.past && `opacity: 0.3;`}

        @media (max-width: 768px) {
            height: 0.375rem;
        }

    }
`


const Label = styled.label`
    display: block;
    position: relative;
    font-size: 1.125rem;

    & i {
        display: inline-block;
        font-style: normal;
        position: absolute;
        left: -1.25rem;

        @media (max-width: 768px) {
            display: none;
        }
    }

    & nav {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        min-height: 3rem;

        & button {
            margin-right: 1.125rem;
            color: var(--color-white);
        }
    }
`

const StepCounter = styled.nav`
    @media (max-width: 768px) {
        position: absolute;
        bottom: -2rem;
        left: 0;
    }
`

const InputMessage = styled.div<{ size?: number }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    margin-left: -7px;
    width: calc(${props => props.size}px - 28px);
    height: fit-content;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`
