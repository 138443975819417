import React from 'react';
import styled from 'styled-components';

import PersonAvatar from 'src/components/PersonAvatar/';
import { Input, Button, IDropdownOption } from 'src/libs';
import { printPage } from 'src/pages/prognosis/modules/item';

export default function PersonHeaderCompact({
    user,
    onShare
}:{
    user: any,
    onShare?(): void
}) {
    return <Container>
        <ProfileData>
            <PersonAvatar name={user.name} color={user.color} image={user.image} />
            <span>{user.name}, {user.datetime.date}, {user.datetime.time}, {user.place.name}</span>
        </ProfileData>
        {onShare &&
            // <Button size='small' color='transparentWithBorder' onClick={onShare}>Поделиться</Button>
            <StyledInput
                size='small'
                value={1}
                options={[
                    { label: 'Поделиться', subtitle: 'Скопировать ссылку', value: 1, action: () => { onShare() } },
                    { label: 'Скачать в pdf', value: 1, action: () => {
                            // const toPdfButton = document.querySelector('#print-button button') as HTMLButtonElement;
                            // toPdfButton.click();
                            printPage(['print-header', 'print-profile-data', 'print-map', 'print-content'])
                        }
                    }
                ]}
            />
        }
    </Container>;
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
        margin-left: auto;
        color: var(--color-black2);

        &:hover {
            border-color: var(--color-black2) !important;
        }
    }
`
const ProfileData = styled.div`
    display: flex;
    align-items: center;

    & span:first-of-type {
        margin-right: 0.75rem;
    }

    & span:last-of-type {
        color: var(--color-gray);
    }
`;
const StyledInput = styled(Input)`
    max-width: 12rem;
    margin-left: auto;
    background: #f5f5f5 !important;
    
    input {
        background: #f5f5f5 !important;
    }

    @media print {
        display: none;
    }
`;