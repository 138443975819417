import { fetchAPI_REST, fillRoute } from 'src/helpers/api';
import { Dispatch } from 'redux';
import { routes } from 'src/routes';

import { signOut } from 'src/store/actions/user'

export const HOROSCOPE_GET_SUCCESS = 'HOROSCOPE_GET_SUCCESS';

export const get = (date: string) => {
    return (dispatch: Dispatch<any>) => {
        const route = fillRoute(routes.HOROSCOPE, {}, { date });
        fetchAPI_REST(route)
            .then(data => { dispatch(getSuccess(data)) })
            .catch(error => { dispatch(signOut(error)); });
    };
}

const getSuccess = (data: any) => {
    let d = null;
    if (data) d = { ...data };
    return {
        type: HOROSCOPE_GET_SUCCESS,
        payload: d
    }
}