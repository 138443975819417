import React from 'react';
import styled from 'styled-components';

interface IButton {
    name: string;
    action(): void;
    red?: boolean;
}

export default function DropDown({
    buttons = []
}: {
    buttons: IButton[]
}) {
    return <Container>
        {buttons.map((item, index) =>
            <Item key={index} onClick={item.action} red={item.red}>{item.name}</Item>
        )}
    </Container>
}

const Container = styled.ul`
    display: block;
    width: 12.5rem;
    margin: 0;
    padding: 0.312rem;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.06), 0px 3.2px 7.2px rgba(0, 0, 0, 0.06);
    border-radius: 0.375rem;
    background: var(--color-white);
    list-style: none;
    font-size: 0.875rem;
`

const Item = styled.li<{red?: boolean}>`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.6rem 0.9rem;
    background: var(--color-gray2);
    margin-bottom: 0.312rem;
    border-radius: 0.375rem;

    ${props => props.red && `
        background: none;
        color: var(--color-red);
    `}

    &:hover {
        opacity: 0.6;
    }

    &:last-child {
        margin-bottom: 0;
    }
`