import { searchParams } from "./utils"

export default async function cloudPayments() {
    //@ts-ignore
    if (!window.cp?.CloudPayments) await new Promise((resolve, reject) => {
        const script = document.createElement('script')
        document.head.append(script)
        script.onload = resolve
        script.onerror = reject
        script.async = true
        script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments'
    })

    //@ts-ignore
    return window.cp?.CloudPayments
}

export const getUTMCommentForCP = () => {
    const sp = searchParams()
    const url = window.location.href;

    let isFind: boolean = false;
    const regex = /utm/
    for (let key of sp.keys()) {
      if (regex.test(key)) {
        isFind = true
        break
      }
    }

    return isFind ? url : undefined;
  }
