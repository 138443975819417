import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { connect } from 'react-redux';
import Header from 'src/components/layout/Header/';
import MainContainer from 'src/components/layout/MainContainer/';

import AutoPrediction from 'src/pages/ap';
import DevPlan from 'src/pages/idp';
import Import from 'src/pages/prognosis/modules/import';
import Item from 'src/pages/prognosis/modules/item';
import List from 'src/pages/prognosis/modules/list';

import PersonList from 'src/components/PersonList/';
import PopupBottomBanner from 'src/components/PopupBottomBanner/index';

import { checkIsOpenRoute } from 'src/helpers/utils';
import { get as getProducts } from 'src/store/actions/products';
import { mainApi, s3urls } from "../../helpers/api";
import { getUser, setUser, unsetUser } from "../../helpers/localStorage";
import { Button, NotificationPopup, Region } from 'src/libs';


function Prognosis({
  match,
  onGetProducts
}: {
  match: any,
  onGetProducts(id: number, country: Region): void
}) {
    const [showPrivacyAndTerms, setShowPrivacyAndTerms] = useState(false)
    const lsUser = getUser()
    const acceptNewPrivacyPolicy = () => {
        mainApi.closeNotifications([{id: 'confirm_privacy_and_terms'}])
        setShowPrivacyAndTerms(false)

        const newLsUser = {...lsUser}
        newLsUser.confirmations.privacyAndTerms = true
        setUser(newLsUser)
    }
    useEffect(() => {
      if(lsUser) {
        if (lsUser?.confirmations) {
            setShowPrivacyAndTerms(!lsUser.confirmations.privacyAndTerms)
        } else {
            unsetUser()
            window.location.reload()
        }
      }
    }, [])

  const isOpenRoute = checkIsOpenRoute();

  const userId = +match.params.userId;
  React.useEffect(() => {
    !isOpenRoute && onGetProducts(userId, Region.ru);
  }, [onGetProducts]);

  return <>
      <MainContainer>
          <Header match={match} />
          <Container>
            {!isOpenRoute && <ColumnLeft id="person-list"><PersonList currentUserId={userId} /></ColumnLeft>}
            <Switch>
              <Route path={`/idp/:hash`} exact component={DevPlan} />
              <Route path={`/ap/:hash`} exact component={AutoPrediction} />
              <Route path={`/prognosis/import`} component={Import} />
              <Route path={`/prognosis/:userId/`} exact component={List} />
              <Route path={`/prognosis/:userId/:key/`} exact component={Item} />
              <Route exact path='/share/prognosis/:userId/:key' component={Item} />
            </Switch>
          </Container>

          <BannerContainer>
              <PopupBottomBanner />
          </BannerContainer>
      </MainContainer >

      {showPrivacyAndTerms && <NotificationPopup
          type='warning'
          title="Ознакомьтесь с новой политикой и соглашением"
          content={
              <>
                  Chronos изменил <a target="_blank" href={s3urls.privacyPolicyRU}>политику
                  конфиденциальностии</a> и <a target="_blank" href={s3urls.termsOfUseRU}>условия
                  использования</a>. Пожалуйста ознакомьтесь перед дальнейшим использованием сайта. Нажимая «Продолжить» вы соглашаетесь с новыми правилами.
              </>
          }
          buttons={<Button color="accent" size='small'
                          onClick={acceptNewPrivacyPolicy}
          >Продолжить</Button>}
      />}
  </>
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetProducts: (id: number, country: Region) => { dispatch(getProducts(id, country)); }
  }
};

export default connect(
  null,
  mapDispatchToProps
)(Prognosis);

export const Container = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  width: var(--main-layout-sidebar-left-width);

  @media (max-width: 768px) {
    max-height: 0rem;
    overflow: hidden;
    position: absolute;
    width: calc(100% - 2rem);
    box-sizing: border-box;
    top: 3.75rem;
    transition: max-height 0.5s;
    z-index: 50;

    &.opened {
      max-height: 100vh;
    }
  }
`;

export const BannerContainer = styled.div`
  position: fixed;
  bottom: 2.5rem;
  left: 5vw;

  @media (max-width: 768px) {
    bottom: 5vw;
  }
`;
