import React from 'react';
import {Marker} from "./index";
import {result} from "./utils";

type CommonMoneyMakerType = {
	text: string
}
type RealizationType = {
	color: string
	label: string
	text: string
}
type SpecificMoneyMakerType = {
	marker: string
	text: string
}
type TopType = {
	marker: string
	text: string
}
type YinYangType = {
	lines: string[]
	who: string
	warning: string
}

function Finance({
	commonMoneyMakers,
	commonMoneyMakersLabel,
	instruction,
	label1,
	label2,
	label3,
	realization,
	recommendation,
	specificMoneyMakers,
	specificMoneyMakersLabel,
	title,
	top7,
	yinYang,
 }: {
	commonMoneyMakers: CommonMoneyMakerType[]
	commonMoneyMakersLabel: string
	instruction: string[]
	label1: string
	label2: string
	label3: string
	realization: RealizationType[]
	recommendation: string
	specificMoneyMakers: SpecificMoneyMakerType[]
	specificMoneyMakersLabel: string
	title: string
	top7: TopType[]
	yinYang: YinYangType
}) {
	return (
		<>
			{recommendation}
			<ul>
				{instruction.map((t: string, i: number) => <li key={`instr_${i}`}>{t}</li>)}
			</ul>

			<h3>{label1}</h3>
			<h3>{commonMoneyMakersLabel}</h3>
			<ul>
				<li>
					<span style={{fontWeight: 'bold', color: '#373'}}>{yinYang.who}&nbsp;</span>
					{yinYang.lines.map((i: string, id: number) => <React.Fragment
						key={`yy_${id}`}>{i}<br/></React.Fragment>)}
					{yinYang.warning && <span className="red-cursive">{yinYang.warning}</span>}
				</li>
				{commonMoneyMakers.map(result)}
			</ul>

			<h3>{specificMoneyMakersLabel}</h3>
			<ul>
				{specificMoneyMakers.map((result: any, i: number) =>
					<li key={`top7_${i}`}>
						<div className="block">
							{result.marker && <Marker className='marker'>{result.marker}</Marker>}
							<div className="text">{result.text}</div>
						</div>
					</li>
				)}
			</ul>

			<h3>{label2}</h3>
			{realization.map((r: any, id: number) =>
				<div key={`real_${id}`} style={{marginBottom: '2rem'}}>
					<div style={{marginTop: '2em'}}>{r.label}</div>
					<span style={{color: r.color}}>{r.text}</span>
				</div>
			)}

			<h3>{label3}</h3>
			<ul>
				{(top7 as any[]).map((t, i) =>
					<li key={`top7_${i}`}>
						<div className="block">
							{t.marker && <Marker className='marker'>{t.marker}</Marker>}
							<div className="text">{t.text}</div>
						</div>
					</li>
				)}
			</ul>
		</>
	)
}

export default Finance;